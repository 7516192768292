<template>
  <div>
    <v-container class="pt-3 Kanit pb-10">
      <ValidationObserver ref="observer">
        <h3 class="mt-4 bold text-decoration-underline">
          <u>แจ้งข้อมูลสำหรับการติดตั้ง</u>
        </h3>
        <h4 class="mt-3">กรุณาอัพโหลดรูปภาพหน้างานตามหัวข้อ</h4>
        <div>
          <div class="topic mt-5 mb-5">ภาพโดยรวม<span class="red--text"> *</span></div>
          <div>
            <v-zoomer style="width: 100%; height: 300px">
              <v-img contain width="100%" height="300px" :src="srcImg[0]">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-zoomer>
          </div>
          <div class="mt-5">
            <ValidationProvider rules="required" name="ภาพโดยรวม ด้านซ้าย" v-slot="{ errors }">
              <UploadDocument
                :errors="errors"
                :key="keyupload"
                :multiple="true"
                v-model="image.left"
                :max="55"
              >
              </UploadDocument>
            </ValidationProvider>
          </div>
          <!-- <ImageGallery
            v-if="data.jobsiteimages && data.jobsiteimages.left"
            :image="data.jobsiteimages.left"
          ></ImageGallery> -->
        </div>
        <!-- <div>
          <div class="topic mt-5 mb-5">ด้านหน้า ระยะห่างระหว่างท่อกับเสา</div>
          <div>
            <v-zoomer style="width: 100%; height: 300px">
              <v-img contain width="100%" height="300px" :src="srcImg[1]">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-zoomer>
          </div>
          <div class="mt-5">
            <ValidationProvider name="ด้านหน้า ระยะห่างระหว่างท่อกับเสา" v-slot="{ errors }">
              <UploadDocument
                :errors="errors"
                :key="keyupload"
                :multiple="true"
                v-model="image.front"
                :max="55"
              >
              </UploadDocument>
            </ValidationProvider>
          </div>
        </div> -->
        <!-- <div>
          <div class="topic mt-5 mb-5">ด้านขวา</div>
          <div>
            <v-zoomer style="width: 100%; height: 300px">
              <v-img contain width="100%" height="300px" :src="srcImg[2]">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-zoomer>
          </div>
          <div class="mt-5">
            <ValidationProvider name="ด้านขวา" v-slot="{ errors }">
              <UploadDocument
                :errors="errors"
                :key="keyupload"
                :multiple="true"
                v-model="image.right"
                :max="55"
              >
              </UploadDocument>
            </ValidationProvider>
          </div>
        </div> -->
        <div>
          <div class="topic mt-5 mb-5">
            ตำแหน่งท่อน้ำทิ้งและน้ำดี ความสูงของพื้น และระยะห่างจากผนัง
          </div>
          <div>
            <v-zoomer style="width: 100%; height: 300px">
              <v-img contain width="100%" height="300px" :src="srcImg[3]">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </v-row> </template
              ></v-img>
            </v-zoomer>
          </div>
          <div class="mt-5">
            <ValidationProvider
              :rules="topicRules.sewer"
              name="ตำแหน่งท่อน้ำทิ้งและน้ำดี ความสูงของพื้น และระยะห่างจากผนัง"
              v-slot="{ errors }"
            >
              <UploadDocument
                :errors="errors"
                :key="keyupload"
                :multiple="true"
                v-model="image.sewer"
                :max="55"
              >
              </UploadDocument>
            </ValidationProvider>
          </div>
        </div>
        <!-- <div>
          <div class="topic mt-5 mb-5">ขนาดของเสา ด้านซ้าย และขวา</div>
          <div>
            <v-zoomer style="width: 100%; height: 300px">
              <v-img contain width="100%" height="300px" :src="srcImg[4]">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-zoomer>
          </div>
          <div class="mt-5">
            <ValidationProvider name="ขนาดของเสา ด้านซ้าย และขวา" v-slot="{ errors }">
              <UploadDocument
                :errors="errors"
                :key="keyupload"
                :multiple="true"
                v-model="image.pile"
                :max="55"
              >
              </UploadDocument>
            </ValidationProvider>
          </div>
        </div> -->
        <!-- <div>
          <div class="topic mt-5 mb-5">ขนาดและตำแหน่งของหน้าต่าง</div>
          <div>
            <v-zoomer style="width: 100%; height: 300px">
              <v-img contain width="100%" height="300px" :src="srcImg[5]">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </v-row> </template
              ></v-img>
            </v-zoomer>
          </div>
          <div class="mt-5">
            <ValidationProvider name="ขนาดและตำแหน่งของหน้าต่าง" v-slot="{ errors }">
              <UploadDocument
                :errors="errors"
                :key="keyupload"
                :multiple="true"
                v-model="image.window"
                :max="55"
              >
              </UploadDocument>
            </ValidationProvider>
          </div>
        </div> -->
        <!-- <div>
          <div class="topic mt-5 mb-5">ตำแหน่งของปลั๊กไฟและประตู</div>
          <div>
            <v-zoomer style="width: 100%; height: 300px">
              <v-img contain width="100%" height="300px" :src="srcImg[6]">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </v-row> </template
              ></v-img>
            </v-zoomer>
          </div>
          <div class="mt-5">
            <ValidationProvider name="ตำแหน่งของปลั๊กไฟและประตู" v-slot="{ errors }">
              <UploadDocument
                :errors="errors"
                :key="keyupload"
                :multiple="true"
                v-model="image.plug"
                :max="55"
              >
              </UploadDocument>
            </ValidationProvider>
          </div>
        </div> -->
        <!-- <div>
          <div class="topic mt-5 mb-5">ความสูงของบัวจากพื้น</div>
          <div>
            <v-zoomer style="width: 100%; height: 300px">
              <v-img contain width="100%" height="300px" :src="srcImg[7]">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </v-row> </template
              ></v-img>
            </v-zoomer>
          </div>
          <div class="mt-5">
            <ValidationProvider name="ความสูงของบัวจากพื้น" v-slot="{ errors }">
              <UploadDocument
                :errors="errors"
                :key="keyupload"
                :multiple="true"
                v-model="image.lotus"
                :max="55"
              >
              </UploadDocument>
            </ValidationProvider>
          </div>
        </div> -->
        <!-- <div>
          <div class="topic mt-5 mb-5">ความสูงของพื้นถึงด้านบนฝ้า</div>
          <div>
            <v-zoomer style="width: 100%; height: 300px">
              <v-img contain width="100%" height="300px" :src="srcImg[8]">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </v-row> </template
              ></v-img>
            </v-zoomer>
          </div>
          <div class="mt-5">
            <ValidationProvider name="ความสูงของพื้นถึงด้านบนฝ้า" v-slot="{ errors }">
              <UploadDocument
                :errors="errors"
                :key="keyupload"
                :multiple="true"
                v-model="image.pellicle"
                :max="55"
              >
              </UploadDocument>
            </ValidationProvider>
          </div>
        </div> -->
      </ValidationObserver>

      <br />
      <div class="d-flex">
        <div class="flex-grow-1 mr-5">
          <v-btn @click="$emit('back')" block color="#C4C4C4"> ย้อนกลับ </v-btn>
        </div>
        <div class="flex-grow-1">
          <v-btn block @click="onSave" :loading="IsLoading" color="#59BEA3" dark>ยืนยัน</v-btn>
        </div>
      </div>
      <v-dialog v-model="showImage" width="550px" persistent>
        <v-card v-if="showImage">
          <div class="text-right">
            <v-btn @click="showImage = false" icon><v-icon>close</v-icon></v-btn>
          </div>
          <v-zoomer>
            <v-img :src="imageShow" width="500px">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-zoomer>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showAlert" fullscreen transition="dialog-bottom-transition"
        ><v-card><NotificationAlert :message="messageAlert" @close="closeAlert" /></v-card>
        ></v-dialog
      >
    </v-container>
  </div>
</template>

<script>
import ImageGallery from '@/components/ShowImageGallery.vue'
import UploadDocument from '@/components/UploadMultipleFile.vue'
import axios from 'axios'
import NotificationAlert from '../Manager/NotifcationAlert.vue'
export default {
  props: {
    data: Object
  },
  components: {
    NotificationAlert,
    UploadDocument,
    ImageGallery
  },
  computed: {
    query() {
      return this.$store.state.query
    }
  },
  data() {
    return {
      keyupload: 0,
      showAlert: false,
      messageAlert: null,
      IsLoading: false,
      image: {
        left: [],
        front: [],
        right: [],
        sewer: [],
        pile: [],
        window: [],
        plug: [],
        lotus: [],
        pellicle: []
      },
      showImage: false,
      imageshow: '',
      srcImg: [
        require('@/assets/installation/one.jpg'),
        require('@/assets/installation/two.jpg'),
        require('@/assets/installation/three.png'),
        require('@/assets/installation/four.jpg'),
        require('@/assets/installation/five.png'),
        require('@/assets/installation/six.jpg'),
        require('@/assets/installation/seven.png'),
        require('@/assets/installation/eight.png'),
        require('@/assets/installation/nine.png')
      ],
      topicRules: {
        sewer: ''
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    let found = this.data.productdetails.filter((f) => f.sink.trim() == 'เรียบ')
    if (!found || found.length != this.data.productdetails.length) {
      this.topicRules.sewer = 'required'
    }

    if (this.data.jobsiteimages) {
      // Get previous image data
      this.image.left.push(...this.data.jobsiteimages.left)
      this.image.front.push(...this.data.jobsiteimages.front)
      this.image.right.push(...this.data.jobsiteimages.right)
      this.image.sewer.push(...this.data.jobsiteimages.sewer)
      this.image.pile.push(...this.data.jobsiteimages.pile)
      this.image.window.push(...this.data.jobsiteimages.window)
      this.image.plug.push(...this.data.jobsiteimages.plug)
      this.image.lotus.push(...this.data.jobsiteimages.lotus)
      this.image.pellicle.push(...this.data.jobsiteimages.pellicle)
    }
  },
  methods: {
    async onSave() {
      let isValid = await this.$refs.observer.validate()
      if (!isValid) {
        this.$nextTick(() => {
          var el = this.$el.querySelector('.v-messages.error--text:first-of-type')
          if (!el) {
            el = this.$el.querySelector('.error--text')
          }
          this.$vuetify.goTo(el)
          return
        })
        return
      } else {
        // Old requirement
        // if(this.data.customerstatus){
        //   let beforeImage = this.data.jobsiteimages;
        //   this.image.left = [...beforeImage.left,...this.image.left]
        //   this.image.front = [...beforeImage.front,...this.image.front]
        //   this.image.right = [...beforeImage.right,...this.image.right]
        //   this.image.sewer = [...beforeImage.sewer,...this.image.sewer]
        //   this.image.pile = [...beforeImage.pile,...this.image.pile]
        //   this.image.window = [...beforeImage.window,...this.image.window]
        //   this.image.plug = [...beforeImage.plug,...this.image.plug]
        //   this.image.lotus = [...beforeImage.lotus,...this.image.lotus]
        //   this.image.pellicle = [...beforeImage.pellicle,...this.image.pellicle]
        // }

        var payload = {
          orderid: this.data.orderid,
          jobsiteimages: this.image, // array url รูปภาพ
          googlemaps: this.data.googlemap, // url
          address: this.data.address,
          zipcode: this.data.zipcode,
          subdistrict: this.data.subdistrict,
          district: this.data.district,
          province: this.data.province,
          customerid: this.data.customerid
        }
        this.IsLoading = true
        axios
          .put(
            process.env.VUE_APP_API_URL + 'kingkitchen/fillInstallationDetailsByCustomer',
            payload,
            {
              headers: {
                AUTHORIZATION: 'Bearer xxx xxx'
              }
            }
          )
          .then(async (response) => {
            if (response.data.responsecode == 200) {
              await this.$store.dispatch(
                'sendMessage',
                'แจ้งรายละเอียดการติดตั้งสำเร็จ: ' + this.query.orderid
              )
              let IscloseLiff = false
              if (!this.data.customerstatus && this.query.usertype == 'customer') {
                this.$swal.fire({
                  icon: 'success',
                  text: 'สำเร็จ'
                })
                IscloseLiff = true
              } else {
                this.messageAlert = 'ระบบได้ทำการแจ้งเตือนไปยัง Sales เรียบร้อยแล้ว'
                this.showAlert = true
              }

              if (IscloseLiff) {
                this.$liff.closeWindow()
              }
            }
            this.Isloading = false
          })
          .catch(async (error) => {
            await this.$swal.fire({
              text: error.response.data.reason
            })
            if (error.response.data.responsecode == 400) {
              this.$liff.closeWindow()
            }
          })
      }
    },
    closeAlert() {
      this.$liff.closeWindow()
    },
    onShowImage(index) {
      this.imageShow = this.srcImg[index]
      this.showImage = true
    }
  }
}
</script>

<style scoped>
.topic {
  padding: 10px;
  background: #dadada;
  color: black;
  width: 100%;
  border-radius: 5px;
}
</style>
