<template>
  <div>
    <div class="text-right">
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div class="px-4 Kanit pb-5">
      <ValidationObserver ref="observer">
        <ValidationProvider name="สินค้า" :rules="'required'" v-slot="{ errors }">
          <div>สินค้า</div>
          <v-combobox
            :error-messages="errors"
            v-if="showOtherProduct"
            placeholder="อื่นๆ (โปรดระบุ)"
            dense
            ref="productother"
            outlined
            solo
            @change="onSelectProduct"
            :items="productOptions"
            v-model="input.product"
          ></v-combobox>
          <v-select
            v-else
            :error-messages="errors"
            dense
            outlined
            solo
            @change="onSelectProduct"
            :items="productOptions"
            v-model="input.product"
          ></v-select>
        </ValidationProvider>
        <template v-if="['DIY', 'Built-in'].includes(input.product)">
          <ValidationProvider name="รูปแบบการติดตั้ง" :rules="'required'" v-slot="{ errors }">
            <div>รูปแบบการติดตั้ง</div>
            <v-text-field v-model="input.format" outlined solo dense :error-messages="errors">
            </v-text-field>
          </ValidationProvider>
          <ValidationProvider name="แนบภาพตัวอย่าง" :rules="'required'" v-slot="{ errors }">
            <UploadMultipleFile
              :errors="errors"
              :key="keyupload"
              :multiple="true"
              :label="'แนบภาพตัวอย่าง'"
              v-model="input.photos"
              :max="55"
            >
            </UploadMultipleFile>
          </ValidationProvider>
        </template>
        <template v-if="['ตัวโชว์ (display)'].includes(input.product)">
          <ValidationProvider name="แนบภาพตัวอย่าง" v-slot="{ errors }">
            <UploadMultipleFile
              :errors="errors"
              :key="keyupload"
              :multiple="true"
              :label="'แนบภาพตัวอย่าง'"
              v-model="input.photos"
              :max="55"
            >
            </UploadMultipleFile>
          </ValidationProvider>
        </template>
        <div>
          <ValidationProvider name="ชนิดท็อป/ตำแหน่งซิงค์" :rules="'required'" v-slot="{ errors }">
            <div class="mt-2">ชนิดท็อป/ตำแหน่งซิงค์</div>

            <v-combobox
              ref="sinkother"
              :key="sinkkey"
              :error-messages="errors"
              v-if="showOtherSink"
              placeholder="อื่นๆ (โปรดระบุ)"
              dense
              outlined
              solo
              @change="onSelectSink"
              :items="sinkOptions"
              v-model="input.sink"
            ></v-combobox>
            <v-select
              v-else
              :key="sinkkey"
              :error-messages="errors"
              dense
              outlined
              solo
              @change="onSelectSink"
              :items="sinkOptions"
              v-model="input.sink"
            ></v-select>
          </ValidationProvider>

          <div v-if="input.sink == 'เรียบ'" class="pb-5">
            <ValidationProvider
              v-if="input.simpleSmoothSink"
              name="แนบภาพตัวอย่าง"
              :rules="'required'"
              v-slot="{ errors }"
            >
              <UploadMultipleFile
                v-if="input.simpleSmoothSink"
                :errors="errors"
                :key="keyupload"
                :multiple="true"
                :label="'แนบภาพตัวอย่าง'"
                v-model="input.simpleSmoothSink"
                :max="55"
              >
              </UploadMultipleFile>
            </ValidationProvider>
          </div>

          <div v-if="input.sink == 'เจาะ'">
            <v-checkbox v-model="input.HasDrillSink" label="เจาะซิงค์" color="red"></v-checkbox>
            <ValidationProvider
              v-if="input.HasDrillSink"
              name="บริเวณเจาะซิงค์"
              :rules="'required'"
              v-slot="{ errors }"
            >
              <v-radio-group v-model="input.drilSinkOption" row :error-messages="errors">
                <v-radio label="ซ้าย" value="ซ้าย"></v-radio>
                <v-radio label="ขวา" value="ขวา"></v-radio>
                <v-radio label="กลาง" value="กลาง"></v-radio>
              </v-radio-group>
            </ValidationProvider>
            <ValidationProvider
              v-if="input.HasDrillSink"
              name="แนบภาพตัวอย่างเจาะซิงค์"
              :rules="'required'"
              v-slot="{ errors }"
            >
              <UploadMultipleFile
                v-if="input.HasDrillSink"
                :errors="errors"
                :key="keyupload"
                :multiple="true"
                :label="'แนบภาพตัวอย่าง'"
                v-model="input.simplePhotoDrillSink"
                :max="55"
              >
              </UploadMultipleFile>
            </ValidationProvider>

            <v-checkbox v-model="input.HasDrillStove" label="เจาะเตา" color="red"></v-checkbox>

            <ValidationProvider
              v-if="input.HasDrillStove"
              name="บริเวณเจาะเตา"
              :rules="'required'"
              v-slot="{ errors }"
            >
              <v-radio-group v-model="input.drilStoveOption" row :error-messages="errors">
                <v-radio label="ซ้าย" value="ซ้าย"></v-radio>
                <v-radio label="ขวา" value="ขวา"></v-radio>
                <v-radio label="กลาง" value="กลาง"></v-radio>
              </v-radio-group>
            </ValidationProvider>

            <ValidationProvider
              v-if="input.HasDrillStove"
              name="แนบภาพตัวอย่างเจาะเตา"
              :rules="'required'"
              v-slot="{ errors }"
            >
              <UploadMultipleFile
                v-if="input.HasDrillStove"
                :errors="errors"
                :key="keyupload"
                :multiple="true"
                :label="'แนบภาพตัวอย่าง'"
                v-model="input.simplePhotoDrillStove"
                :max="55"
              >
              </UploadMultipleFile>
            </ValidationProvider>
          </div>
          <div class="red--text" v-if="requireDrillDetail">กรุณาเลือกเจาะซิงค์หรือเจาะเตา</div>
        </div>
        <div>
          <ValidationProvider name="หมายเหตุ" v-slot="{ errors }">
            <div class="mt-2">หมายเหตุ</div>
            <v-textarea
              rows="3"
              :error-messages="errors"
              dense
              outlined
              solo
              :items="otherOptions"
              v-model="input.remark"
            ></v-textarea>
          </ValidationProvider>
        </div>
      </ValidationObserver>
      <div class="text-center px-3">
        <v-btn @click="submitData" dark color="#59BEA3">{{
          edittype == 'create' ? 'เพิ่มสินค้า' : 'แก้ไขสินค้า'
        }}</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import UploadMultipleFile from '@/components/UploadMultipleFile.vue'
export default {
  props: ['edittype', 'editdata'],
  components: {
    UploadMultipleFile
  },
  data() {
    return {
      keyupload: 0,
      sinkkey: 0,
      sinkOptions: ['เรียบ', 'เจาะ', 'อื่นๆ (โปรดระบุ)'],
      otherOptions: ['เตา', 'ฮู้ด', 'ไม่มี'],
      showOtherProduct: false,
      showOtherSink: false,
      productOptions: [
        'c180',
        'c135',
        'c225',
        'DIY',
        'ตัวโชว์ (display)',
        'Built-in',
        'อื่นๆ (โปรดระบุ)'
      ],
      otherproduct: '',
      input: {
        product: '',
        format: '', // รูปแบบการติดตั้ง
        photos: [], // แบบภาพตัวอย่าง array url
        sink: '', // ซิงค์
        other: '', // อุปกรณ์อื่นๆ
        remark: '', // หมายเหตุ
        HasDrillSink: false,
        drilSinkOption: '',
        simplePhotoDrillSink: [],
        HasDrillStove: false,
        drillStoveOption: '',
        simplePhotoDrillStove: [],
        simpleSmoothSink: []
      },
      requireDrillDetail: false
    }
  },
  mounted() {
    if (this.edittype == 'edit') {
      Object.assign(this.input, this.editdata)
    }
    if (!this.input.product || this.productOptions.includes(this.input.product)) {
      this.showOtherProduct = false
    } else this.showOtherProduct = true

    if (!this.input.sink || this.sinkOptions.includes(this.input.sink)) {
      this.showOtherSink = false
    } else this.showOtherSink = true
  },
  methods: {
    onSelectProduct(item) {
      if (item === 'อื่นๆ (โปรดระบุ)' || !this.productOptions.includes(item)) {
        if (item === 'อื่นๆ (โปรดระบุ)') {
          this.input.product = ''
        }
        this.showOtherProduct = true
        this.$nextTick(() => {
          this.$refs.productother.focus()
          this.$refs.observer.reset()
        })
      } else {
        this.showOtherProduct = false
      }
    },
    onSelectSink(item) {
      if (item === 'อื่นๆ (โปรดระบุ)' || !this.sinkOptions.includes(item)) {
        if (item === 'อื่นๆ (โปรดระบุ)') {
          this.input.sink = ''
        }
        this.showOtherSink = true

        this.$nextTick(() => {
          this.$refs.sinkother.focus()
          this.$refs.observer.reset()
          this.$forceUpdate()
        })
      } else {
        this.showOtherSink = false
      }
    },
    async submitData() {
      let IsFailProcess = false
      var isValid = await this.$refs.observer.validate()
      if (!isValid) {
        IsFailProcess = true
      }

      // Check sink as 'drill' ?
      if (this.input.sink == 'เจาะ' && !this.input.HasDrillStove && !this.input.HasDrillSink) {
        this.requireDrillDetail = true
        IsFailProcess = true
      } else {
        this.requireDrillDetail = false
      }

      if (IsFailProcess) {
        return
      }

      this.requireDrillDetail = false
      if (this.edittype == 'create') {
        this.$emit('onCreate', this.input)
      } else {
        this.$emit('onEdit', this.input)
      }
    }
  }
}
</script>
