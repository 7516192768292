var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Kanit"},[_c('div',{staticClass:"mx-auto pa-3",staticStyle:{"max-width":"500px"}},[_c('div',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('prev')}}},[_c('v-icon',[_vm._v("mdi-chevron-left")]),_vm._v(" กลับ ")],1)],1),_vm._m(0),_c('div',{staticClass:"px-2 pb-4"},[_c('ValidationObserver',{ref:"observer"},[_c('ValidationProvider',{attrs:{"rules":{
            required: true
          },"name":"Customer ID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('div',[_vm._v("Customer ID")]),_c('custom-input',{attrs:{"dense":"","outlined":"","solo":"","error-messages":errors},model:{value:(_vm.input.customerid),callback:function ($$v) {_vm.$set(_vm.input, "customerid", $$v)},expression:"input.customerid"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":{
            required: true
          },"name":"ชื่อ - สกุล"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('div',[_vm._v("ชื่อ - สกุล")]),_c('custom-input',{attrs:{"dense":"","outlined":"","solo":"","error-messages":errors},model:{value:(_vm.input.name),callback:function ($$v) {_vm.$set(_vm.input, "name", $$v)},expression:"input.name"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":{
            required: true,
            thai_phonenumber: true
          },"name":"เบอร์โทร"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('div',[_vm._v("เบอร์โทร")]),_c('custom-input',{attrs:{"numberOnly":"","maxlength":"10","dense":"","outlined":"","solo":"","error-messages":errors},model:{value:(_vm.input.phonenumber),callback:function ($$v) {_vm.$set(_vm.input, "phonenumber", $$v)},expression:"input.phonenumber"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":{
            required: true
          },"name":"ที่อยู่"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('div',[_vm._v("ที่อยู่")]),_c('custom-input',{attrs:{"type":"text","dense":"","solo":"","outlined":"","error-messages":errors},model:{value:(_vm.input.address),callback:function ($$v) {_vm.$set(_vm.input, "address", $$v)},expression:"input.address"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"รหัสไปรษณีย์","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('div',[_vm._v("รหัสไปรษณีย์")]),_c('div',[_c('ThaiAddressInput',{attrs:{"inputtype":'number',"type":'zipcode',"errorMessages":errors,"texttype":'number'},on:{"select":_vm.onSelectAddress},model:{value:(_vm.input.zipcode),callback:function ($$v) {_vm.$set(_vm.input, "zipcode", $$v)},expression:"input.zipcode"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":"เขต","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('div',[_vm._v("เขต")]),_c('ThaiAddressInput',{attrs:{"inputtype":'text',"type":'amphoe',"errorMessages":errors,"texttype":'text'},on:{"select":_vm.onSelectAddress},model:{value:(_vm.input.district),callback:function ($$v) {_vm.$set(_vm.input, "district", $$v)},expression:"input.district"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"แขวง","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('div',[_vm._v("แขวง")]),_c('ThaiAddressInput',{attrs:{"type":'district',"errorMessages":errors},on:{"select":_vm.onSelectAddress},model:{value:(_vm.input.subdistrict),callback:function ($$v) {_vm.$set(_vm.input, "subdistrict", $$v)},expression:"input.subdistrict"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"จังหวัด","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('div',[_vm._v("จังหวัด")]),_c('ThaiAddressInput',{attrs:{"inputtype":'text',"type":'province',"errorMessages":errors,"texttype":'text'},on:{"select":_vm.onSelectAddress},model:{value:(_vm.input.province),callback:function ($$v) {_vm.$set(_vm.input, "province", $$v)},expression:"input.province"}})]}}])})],1)],1),_c('div',{staticClass:"text-center py-6"},[_c('v-btn',{attrs:{"large":"","rounded":"","color":"primary"},on:{"click":_vm.nextStep}},[_vm._v(" ถัดไป")])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-0 pt-5"},[_c('div',{staticStyle:{"font-size":"20px","font-weight":"600"}},[_vm._v("เพิ่มคำสั่งซื้อ")])])}]

export { render, staticRenderFns }