<template>
  <div>
    <div class="pt-5 px-3 pb-10">
      <div style="text-align: center" class="mt-5">
        <img style="height: auto; width: 140px" src="@/assets/logo.png" />
      </div>
      <div>
        <!-- <input type="text" class="form-control col-12 col-md-8" > -->
        <ValidationObserver ref="observer">
          <label class="mt-5">ชื่อ</label>
          <ValidationProvider
            :rules="{
              required: true
            }"
            name="ชื่อ"
            v-slot="{ errors }"
          >
            <custom-input
              dense
              outlined
              placeholder="ชื่อ"
              v-model="input.first_name"
              solo
              :error-messages="errors"
            ></custom-input>
          </ValidationProvider>
          <label class="mt-5">นามสกุล</label>
          <ValidationProvider
            :rules="{
              required: true
            }"
            name="นามสกุล"
            v-slot="{ errors }"
          >
            <custom-input
              dense
              type="text"
              solo
              placeholder="นามสกุล"
              v-model="input.last_name"
              outlined
              :error-messages="errors"
            ></custom-input>
          </ValidationProvider>
          <label class="mt-5">เบอร์โทรศัพท์</label>
          <ValidationProvider
            :rules="{
              required: true,
              thai_phonenumber: true,
              digits: 10
            }"
            name="เบอร์โทรศัพท์"
            v-slot="{ errors }"
          >
            <custom-input
              dense
              solo
              type="number"
              maxlength="10"
              numberOnly
              name="phone"
              placeholder="เบอร์โทรศัพท์"
              v-model="input.phoneno"
              outlined
              :error-messages="errors"
            ></custom-input>
          </ValidationProvider>
          <ValidationProvider
            :rules="{
              required: true
            }"
            name="ตำแหน่ง"
            v-slot="{ errors }"
          >
            <label class="mt-5">ตำแหน่ง</label>
            <v-select
              solo
              outlined
              dense
              placeholder="ตำแหน่ง"
              :error-messages="errors"
              :show-labels="false"
              v-model="input.job"
              :items="jobOption"
              @input="
                input.department = ''
                input.location = ''
                input.branch = ''
              "
            >
            </v-select>
          </ValidationProvider>

          <div v-if="input.job !== null && ['PC', 'Sales'].includes(input.job)">
            <ValidationProvider
              v-if="['PC', 'Sales'].includes(input.job)"
              :rules="{
                required: true
              }"
              name="เขต"
              v-slot="{ errors }"
            >
              <label class="mt-5">เขต</label>
              <v-select
                solo
                outlined
                dense
                placeholder="เขต"
                :error-messages="errors"
                :show-labels="false"
                v-model="input.department"
                :items="departmentOptions"
              >
              </v-select>
            </ValidationProvider>
          </div>
          <div id="store" v-if="input.job !== null && input.job == 'PC'">
            <ValidationProvider
              v-if="input.job == 'PC'"
              :rules="{
                required: true
              }"
              name="ร้านค้า"
              v-slot="{ errors }"
            >
              <label class="mt-5">ร้านค้า</label>

              <v-select
                :disabled="!input.department"
                :background-color="!input.department ? '#eee' : ''"
                solo
                @input="input.branch = ''"
                placeholder="เลือกร้านค้า"
                dense
                outlined
                v-model="input.location"
                :items="locationOptions"
                :error-messages="errors"
              >
              </v-select>
            </ValidationProvider>
          </div>

          <div id="location" v-if="input.job !== null && input.job == 'PC'">
            <label class="mt-5">สาขา</label>
            <ValidationProvider
              v-if="input.job == 'PC'"
              :rules="{
                required: true
              }"
              name="สาขา"
              v-slot="{ errors }"
            >
              <v-select
                solo
                :disabled="!input.location"
                :placeholder="!input.location ? 'กรุณาเลือกร้านค้า' : 'เลือกสาขา'"
                dense
                :background-color="!input.location ? '#eee' : ''"
                outlined
                v-model="input.branch"
                :items="branchOptions"
                :error-messages="errors"
              >
              </v-select>
            </ValidationProvider>
          </div>
        </ValidationObserver>
        <div class="d-flex justify-center">
          <v-checkbox v-model="checkbox">
            <template v-slot:label>
              <div>
                ยอมรับ
                <span
                  @click="seeCondition"
                  style="color: red; text-decoration: underline; cursor: pointer"
                >
                  Term & Condition
                </span>
              </div>
            </template>
          </v-checkbox>
        </div>
        <div class="text-center">
          <v-btn
            @click="regis"
            :disabled="!checkbox || success"
            x-large
            color="#000"
            style="color: #fff"
            :loading="loading"
            >ลงทะเบียน</v-btn
          >
        </div>
      </div>
    </div>
    <v-dialog v-model="privacy" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card class="w-100">
        <v-toolbar
          dark
          class="position-fixed col-12"
          style="height: 82px; top: 0; z-index: 2"
          color="#000"
        >
          <v-btn icon dark @click="privacy = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Privacy</v-toolbar-title>
        </v-toolbar>

        <!-- <privacyth /> -->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
// import privacy from "./privacy";

export default {
  components: {
    //   privacy,
  },

  data() {
    return {
      success: false,
      error: false,
      privacy: false,
      jobOption: ['Sale Lead', 'Sales', 'PC', 'ช่าง', 'ช่าง (SCG)'],
      checkbox: false,
      loading: false
    }
  },
  computed: {
    input() {
      return this.$store.state.input
    },
    masterData() {
      return this.$store.state.masterData
    },
    departmentOptions() {
      let tempOptions = []
      if (this.masterData) {
        this.masterData.forEach((item) => {
          if (![...tempOptions].includes(item.department)) {
            tempOptions.push(item.department)
          }
        })
      }
      return tempOptions
    },
    locationOptions() {
      let tempOptions = []
      if (this.masterData) {
        if (this.input.department) {
          let filteredByDepartment = this.masterData.filter((el) => {
            return el.department === this.input.department
          })

          filteredByDepartment.forEach((item) => {
            if (![...tempOptions].includes(item.location)) {
              tempOptions.push(item.location)
            }
          })
        } else {
          this.masterData.forEach((item) => {
            if (![...tempOptions].includes(item.location)) {
              tempOptions.push(item.location)
            }
          })
        }
      }
      return tempOptions
    },
    branchOptions() {
      let tempOptions = []
      if (this.masterData) {
        if (this.input.department) {
          let tempFilter = []
          const filteredByDepartment = this.masterData.filter((el) => {
            return el.department === this.input.department
          })

          tempFilter = filteredByDepartment

          if (this.input.location) {
            tempFilter = filteredByDepartment.filter((el) => {
              return el.location === this.input.location
            })
          }

          tempFilter.forEach((item) => {
            if (![...tempOptions].includes(item.branch)) {
              tempOptions.push(item.branch)
            }
          })
        } else {
          this.masterData.forEach((item) => {
            if (![...tempOptions].includes(item.branch)) {
              tempOptions.push(item.branch)
            }
          })
        }
      }
      return tempOptions
    },
    profile() {
      return this.$store.state.profile
    },
    userid() {
      return this.$store.state.userid
    },
    pageid() {
      return this.$route.query.pageid
    }
  },
  methods: {
    async regis() {
      try {
        this.loading = true

        const isVaid = await this.$refs.observer.validate()

        if (isVaid && this.input && this.input.job) {
          const headers = {
            Authorization: `Bearer xxx xxx`
          }
          const dataCreate = {
            pageid: this.pageid,
            userid: this.userid,
            ...this.input
          }
          // console.log(dataCreate)
          const url = process.env.VUE_APP_API_URL + 'kingkitchen/createmember'

          const response = await axios.post(url, dataCreate, { headers })

          if (response && response.data && response.data.responsecode === 200) {
            await this.$store.dispatch('sendMessage', 'ลงทะเบียนพนักงานสำเร็จ')
            await this.$swal.fire({
              text: 'ลงทะเบียนพนักงานสำเร็จ',
              icon: 'success'
            })
            this.success = true
            this.loading = false
            this.$liff.closeWindow()
            window.close()
          } else {
            await this.$swal.fire({
              text: 'ลงทะเบียนไม่สำเร็จ',
              icon: 'error'
            })
            this.loading = false
          }
        } else {
          this.error = true
          this.loading = false
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },

    seeCondition() {
      this.privacy = true
    }
  }
}
</script>

<style lang="scss">
.req {
  border: 1px solid red;
}
</style>
