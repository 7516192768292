<template>
  <div class="Kanit">
    <div style="max-width: 500px" class="mx-auto pa-3">
      <div>
        <v-btn @click="$emit('prev')" text> <v-icon>mdi-chevron-left</v-icon> กลับ </v-btn>
      </div>
      <div class="mt-3">
        <div class="font-weight-bold" style="font-size: 23px">กรอกข้อมูลหลังติดตั้ง</div>
      </div>

      <div class="mt-4 px-3" :style="orderRead ? 'pointer-events: none;' : ''">
        <div class="headerafter">ปัญหาที่พบ</div>
        <ValidationObserver ref="observer">
          <v-checkbox
            class="mt-1"
            hide-details
            v-model="input.problems"
            value="หน้างานไม่พร้อม"
            label="หน้างานไม่พร้อม"
          ></v-checkbox>
          <v-checkbox
            hide-details
            v-model="input.problems"
            value="ตำแหน่งท่อน้ำ"
            label="ตำแหน่งท่อน้ำ"
          ></v-checkbox>
          <v-checkbox hide-details v-model="input.problems" value="Top" label="Top"></v-checkbox>
          <v-checkbox
            hide-details
            v-model="input.problems"
            value="ซิงค์"
            label="ซิงค์"
          ></v-checkbox>
          <v-checkbox
            hide-details
            v-model="input.problems"
            value="สินค้าผิด/มีปัญหา"
            label="สินค้าผิด/มีปัญหา"
          ></v-checkbox>
          <v-checkbox
            hide-details
            v-model="input.problems"
            value="ช่าง/การติดตั้ง"
            label="ช่าง/การติดตั้ง"
          ></v-checkbox>
          <v-checkbox hide-details v-model="input.problems" value="PC" label="PC"></v-checkbox>
          <v-checkbox
            hide-details
            v-model="input.problems"
            value="ลูกค้าสั่งผิด"
            label="ลูกค้าสั่งผิด"
          ></v-checkbox>
          <!-- <div v-if="errors && errors.length">
              <div class="v-messages">
                <div
                  class="error--text"
                  style="font-size: 15px; color: #b71c1c"
                >
                  {{ errors[0] }}
                </div>
              </div>
            </div> -->
          <!-- <ValidationProvider
            :rules="{
              required: input.problems && input.problems.length ? false : true,
            }"
            name="ปัญหาที่พบเจอ"
            v-slot="{ errors }"
          >
            <v-checkbox
              @change="
                otherproblemtext = '';
                $refs.observer.reset();
              "
              :error-messages="errors"
              v-model="otherproblem"
              label="อื่นๆ โปรดระบุ"
            ></v-checkbox>
          </ValidationProvider> -->
          <v-checkbox
            hide-details
            v-model="input.problems"
            @change="
              otherproblem = !otherproblem
              !otherproblem ? (otherproblemtext = '') : otherproblem
            "
            value="ปัญหาอื่น ๆ"
            label="อื่น ๆ โปรดระบุ"
          ></v-checkbox>
          <ValidationProvider
            :rules="{ required: otherproblem }"
            name="ปัญหาอื่นๆ"
            v-slot="{ errors }"
          >
            <div class="mt-2 pl-7">
              <v-text-field
                v-model="otherproblemtext"
                :error-messages="errors"
                outlined
                dense
                :background-color="otherproblem ? 'white' : '#eee'"
                :readonly="otherproblem == false"
                :disabled="otherproblem == false"
                placeholder="กรุณาระบุ"
              ></v-text-field>
            </div>
          </ValidationProvider>
          <div class="headerafter">ค่าใช้จ่ายเพิ่มเติม</div>
          <div>
            <ValidationProvider
              :rules="{ required: true }"
              name="ค่าใช้จ่ายเพิ่มเติม"
              v-slot="{ errors }"
            >
              <v-radio-group
                @change="
                  input.additionnalcostnote = ''
                  $refs.observer.reset()
                "
                v-model="input.additionnalcoststatus"
                row
                :hide-details="errors && errors.length ? false : true"
                :error-messages="errors"
              >
                <v-radio label="มี" :value="true"> </v-radio>
                <v-radio label="ไม่มี" :value="false"> </v-radio>
              </v-radio-group>
            </ValidationProvider>

            <ValidationProvider
              :rules="{ required: input.additionnalcoststatus }"
              name="รายละเอียดค่าใช้จ่ายเพิ่มเติม"
              v-slot="{ errors }"
            >
              <div class="mt-2">
                ระบุรายละเอียดค่าใช้จ่ายเพิ่มเติม
                <v-text-field
                  :background-color="input.additionnalcoststatus ? 'white' : '#eee'"
                  v-model="input.additionnalcostnote"
                  :error-messages="errors"
                  outlined
                  dense
                  :disabled="!input.additionnalcoststatus"
                  placeholder="ค่าใช้จ่ายเพิ่มเติม"
                ></v-text-field>
              </div>
            </ValidationProvider>
          </div>
          <template v-if="input.additionnalcoststatus">
            <div class="headerafter">วิธีชำระเงิน</div>
            <ValidationProvider :rules="{ required: true }" name="วิธีชำระเงิน" v-slot="{ errors }">
              <v-radio-group
                @change="$refs.observer.reset()"
                v-model="input.additionnalcostmethod"
                row
                :hide-details="errors && errors.length ? false : true"
                :error-messages="errors"
              >
                <v-radio label="เงินสด" value="เงินสด"> </v-radio>
                <v-radio label="โอนเงิน" value="โอนเงิน"> </v-radio>
              </v-radio-group>
            </ValidationProvider>
          </template>
          <div
            v-if="input.additionnalcostmethod == 'โอนเงิน' && input.additionnalcoststatus"
            class="mt-4"
          >
            <div class="text-center mt-3" style="font-size: small; text-decoration: underline">
              กรุณา Scan QR Code สำหรับชำระเงิน
            </div>
            <div class="text-center">
              <v-img class="mx-auto mt-2" width="150px" src="@/assets/qrcodepayment.png"></v-img>
            </div>
            <div class="text-center pt-4">
              <u>เลขบัญชี ธนาคาร กสิกรไทย 100-1-05036-9</u>
            </div>
            <div class="text-center pt-2">
              <u>ชื่อบัญชี บริษัท สยามเฮ้าส์ แอนด์ โฮม จำกัด</u>
            </div>
            <ValidationProvider :rules="{ required: true }" name="อัปโหลดสลิป" v-slot="{ errors }">
              <UploadDocument
                :opencamera="true"
                class="mt-4"
                v-model="input.additionnalcostreceiptimage"
              >
                <template v-slot="{ loading }">
                  <v-text-field
                    placeholder="กรุณาอัปโหลดสลิป"
                    append-icon="mdi-upload"
                    :opencamera="true"
                    :loading="loading"
                    :error-messages="errors"
                    readonly
                    outlined
                    :value="input.additionnalcostreceiptimage"
                  ></v-text-field>
                </template>
              </UploadDocument>
            </ValidationProvider>
          </div>
          <div class="mt-3" v-if="input.additionnalcoststatus">
            <ValidationProvider :rules="{ required: true }" name="จำนวนเงิน" v-slot="{ errors }">
              <CustomInput
                numberOnly
                :error-messages="errors"
                placeholder="จำนวนเงิน"
                v-model="input.additionnalcost"
                outlined
              ></CustomInput>
            </ValidationProvider>
          </div>

          <div class="mt-5 pb-10 d-flex" v-if="!orderRead">
            <div style="width: 50%" class="mr-5">
              <v-btn @click="$emit('oncancel')" large block color="#C4C4C4" dark>ยกเลิก</v-btn>
            </div>
            <div style="width: 50%">
              <v-btn :loading="saveloading" @click="onSave" block large color="#59BEA3" dark
                >บันทึก</v-btn
              >
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import UploadDocument from '@/components/UploadDocument.vue'
export default {
  props: ['orderid', 'orderRead', 'detaildata'],
  data() {
    return {
      saveloading: false,
      otherproblem: false,
      otherproblemtext: ''
    }
  },
  components: {
    UploadDocument
  },
  computed: {
    input() {
      return this.$store.state.afterinstallinput
    }
  },
  mounted() {
    // let problems = this.detaildata.problems.length > 0 ? this.detaildata.problems.split(","): '';
    let problems = this.detaildata.problems

    console.log(problems)
    if (problems) {
      this.input.problems = problems
    }
  },
  methods: {
    async onSave() {
      if (await this.$refs.observer.validate()) {
        // this.saveloading = true;
        var problems = []
        if (this.otherproblemtext) {
          problems = [...this.input.problems, this.otherproblemtext].join(',')
        } else {
          // problems = [...this.input.problems].join(",");
          problems = this.input.problems
        }

        var data = {
          orderid: this.orderid,
          afterinstallimages: this.input.afterinstallimages, // งานหลังติดตั้งเสร็จ array url
          afterinstallcustomerchecklist: this.input.afterinstallcustomerchecklist, // ลูกค้าถือป้าย checklist array url
          afterinstalldocumentchecklist: this.input.afterinstalldocumentchecklist, // เอกสาร checklist array url
          afterinstallother: this.input.afterinstallother, // optional, งานเพิ่มเติม array url
          problems, // ปัญหาที่พบ
          additionnalcoststatus: this.input.additionnalcoststatus, // ค่าใช้จ่ายเพิ่มเติม boolean >> true คือ มี, false คือ ไม่มี
          additionnalcostnote: this.input.additionnalcoststatus
            ? this.input.additionnalcostnote
            : null, // รายละเอียดค่าใช้จ่ายเพิ่มเติม ส่งมาถ้า additionnalcoststatus เป็น true
          additionnalcostmethod: this.input.additionnalcoststatus
            ? this.input.additionnalcostmethod
            : null, // วิธีชำระเงิน เงินสด, โอนเงิน ส่งมาถ้า additionnalcoststatus เป็น true
          additionnalcostreceiptimage:
            this.input.additionnalcoststatus && this.input.additionnalcostmethod == 'โอนเงิน'
              ? this.input.additionnalcostreceiptimage
              : null, // url รูปใบโอนเงิน ส่งมาถ้า additionnalcoststatus เป็น true และ additionnalcostmethod เป็นโอนเงิน
          additionnalcost: this.input.additionnalcoststatus ? this.input.additionnalcost : 0 // จำนวนเงิน ส่งมาถ้า additionnalcoststatus เป็น true
        }

        this.saveloading = true

        axios
          .put(process.env.VUE_APP_API_URL + 'kingkitchen/technicianFillDataAfterInstall', data, {
            headers: { AUTHORIZATION: 'Bearer xxx xxx' }
          })
          .then(async (response) => {
            this.saveloading = false
            if (response.data.responsecode == 200) {
              await this.$swal.fire({ text: 'สำเร็จ', icon: 'success' })
              let new_input = {
                afterinstallimages: [],
                afterinstallcustomerchecklist: [],
                afterinstalldocumentchecklist: [],
                afterinstallother: [],
                problems: [],
                additionnalcoststatus: '',
                additionnalcostnote: '',
                additionnalcostmethod: '',
                additionnalcostreceiptimage: '',
                additionnalcost: ''
              }
              this.$store.commit('setAfterinstallinput', new_input)
              this.$emit('onsave')
            }
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.headerafter {
  font-weight: bold;
  font-size: 17px;
}
</style>
