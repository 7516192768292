<template>
  <div>
    <div style="font-size: 20px">รายการสินค้า</div>
    <v-expansion-panels multiple flat focusable class="elevation-1">
      <v-expansion-panel v-for="(item, i) in productdetails" :key="i">
        <v-expansion-panel-header>{{ item.product }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <div>
            <div class="mt-2">
              <div v-if="item.photos && item.photos.length">
                <v-row dense>
                  <v-col
                    @click="
                      imageshow = img
                      showImage = true
                    "
                    :key="k"
                    cols="4"
                    v-for="(img, k) in item.photos"
                  >
                    <v-img aspect-ratio="1" :src="img"></v-img>
                  </v-col>
                </v-row>
              </div>
              <div v-if="item.format">
                <b>รูปแบบการติดตั้ง:</b>&nbsp;
                <span class="datalabel">{{ item.format }}</span>
              </div>
              <div>
                <b>ซิงค์:</b>&nbsp;<span class="datalabel">{{ item.sink }}</span>
              </div>
              <div v-if="item.HasDrillSink">
                <b>เจาะซิงค์</b>
                <div v-if="item.simplePhotoDrillSink && item.simplePhotoDrillSink.length">
                  <v-row dense>
                    <v-col
                      @click="
                        imageshow = img_sink
                        showImage = true
                      "
                      :key="k"
                      cols="4"
                      v-for="(img_sink, k) in item.simplePhotoDrillSink"
                    >
                      <v-img aspect-ratio="1" :src="img_sink"></v-img>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div v-if="item.HasDrillStove">
                <b>เจาะเตา</b>
                <div v-if="item.simplePhotoDrillStove && item.simplePhotoDrillStove.length">
                  <v-row dense>
                    <v-col
                      @click="
                        imageshow = img_stove
                        showImage = true
                      "
                      :key="k"
                      cols="4"
                      v-for="(img_stove, k) in item.simplePhotoDrillStove"
                    >
                      <v-img aspect-ratio="1" :src="img_stove"></v-img>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div>
                <b>อุปกรณ์อื่นๆ:</b>&nbsp;
                <span class="datalabel">{{ item.other }}</span>
              </div>
              <div>
                <b>หมายเหตุ:</b>&nbsp;
                <span class="datalabel">{{ item.remark || '-' }}</span>
              </div>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-dialog width="600px" v-model="showImage">
      <v-card @click="showImage = false" v-if="showImage">
        <v-img :src="imageshow"></v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    productdetails: {
      type: Array
    }
  },
  data() {
    return {
      showImage: false,
      imageShow: null
    }
  }
}
</script>
