<template>
  <div class="Kanit">
    <div style="max-width: 500px" class="mx-auto pa-3 pb-7">
      <div>
        <v-btn @click="$emit('close')" text> <v-icon>mdi-chevron-left</v-icon> กลับ </v-btn>
      </div>
      <div class="mt-3">
        <div class="font-weight-bold" style="font-size: 23px">กรอกข้อมูลก่อนติดตั้ง</div>
      </div>

      <div class="mt-4 px-3">
        <ValidationObserver ref="observer">
          <div class="headerafter mb-1">บ้านเลขที่</div>
          <ValidationProvider :rules="{ required: true }" name="บ้านเลขที่" v-slot="{ errors }">
            <UploadMultipleFile
              :opencamera="false"
              :key="keyupload"
              :multiple="true"
              :max="!orderRead ? 15 : 0"
              v-model="input.beforeinstallhousenoimages"
              :errors="errors"
              :readOnly="orderRead"
            ></UploadMultipleFile>
          </ValidationProvider>
          <div class="headerafter mb-1 mt-4">กล่องสินค้า</div>

          <ValidationProvider :rules="{ required: true }" name="กล่องสินค้า" v-slot="{ errors }">
            <UploadMultipleFile
              :opencamera="false"
              :key="keyupload"
              :multiple="true"
              :max="!orderRead ? 15 : 0"
              v-model="input.beforeinstallproductimages"
              :errors="errors"
              :readOnly="orderRead"
            ></UploadMultipleFile>
          </ValidationProvider>
          <div class="headerafter mb-1 mt-4">หน้างานก่อนติดตั้ง</div>

          <ValidationProvider
            :rules="{ required: true }"
            name="หน้างานก่อนติดตั้ง"
            v-slot="{ errors }"
          >
            <UploadMultipleFile
              :opencamera="false"
              :key="keyupload"
              :multiple="true"
              :max="!orderRead ? 15 : 0"
              v-model="input.beforeinstalljobsiteimages"
              :errors="errors"
              :readOnly="orderRead"
            ></UploadMultipleFile>
          </ValidationProvider>
        </ValidationObserver>
      </div>
      <div class="text-center mt-8">
        <v-btn
          v-if="!orderRead"
          :loading="loading"
          @click="onSave"
          dark
          large
          class="px-9"
          color="#59BEA3"
          >บันทึก</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import UploadMultipleFile from '@/components/UploadMultipleFile.vue'
export default {
  props: ['detaildata', 'orderRead'],
  components: {
    UploadMultipleFile
  },
  computed: {},
  data() {
    return {
      input: {
        beforeinstallhousenoimages: [], // บ้านเลขที่ array url
        beforeinstallproductimages: [], // กล่องสินค้า array url
        beforeinstalljobsiteimages: [] // หน้างานก่อนติดตั้ง array url
      },
      keyupload: 0,
      otherproblem: false,
      loading: false,
      otherproblemtext: ''
    }
  },
  mounted() {
    Object.assign(this.input, JSON.parse(JSON.stringify({ ...this.detaildata })))
  },
  methods: {
    async onSave() {
      var isValid = await this.$refs.observer.validate()

      console.log(isValid)

      if (!isValid) {
        return
      }
      var data = {
        orderid: this.detaildata.id,
        beforeinstallhousenoimages: this.input.beforeinstallhousenoimages, // บ้านเลขที่ array url
        beforeinstallproductimages: this.input.beforeinstallproductimages, // กล่องสินค้า array url
        beforeinstalljobsiteimages: this.input.beforeinstalljobsiteimages // หน้างานก่อนติดตั้ง array url
      }
      this.loading = true
      axios
        .put(this.serverUrl + 'kingkitchen/technicianFillDataBeforeInstall', data, {
          headers: {
            AUTHORIZATION: 'Bearer xxx xxx'
          }
        })
        .then(async (response) => {
          this.loading = false
          if (response.data.responsecode == 200) {
            await this.$swal.fire({
              text: 'สำเร็จ',
              icon: 'success'
            })
            this.$emit('onsave')
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.headerafter {
  font-weight: bold;
  font-size: 19px;
}
</style>
