export default {
  computed: {
    profile() {
      return this.$store.state.profile
    },
    query() {
      return this.$store.state.query
    },
    userid() {
      return this.$store.state.userid
    },
    serverUrl() {
      return 'https://api-enterprise.zwiz.app/'
    }
  }
}
