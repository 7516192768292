<template>
  <div style="max-width: 500px" class="mx-auto">
    <div class="text-center pt-10">
      <v-icon size="90" color="primary"> mdi-check-circle </v-icon>
      <div class="Kanit mt-3" style="font-size: 20px">
        ระบบได้ทำการแจ้งเตือนไป<br />ยังลูกค้าเรียบร้อยแล้ว
      </div>
      <div class="Kanit">
        <v-btn @click="$liff.closeWindow()" class="px-10 mt-10" depressed dark color="#C4C4C4"
          >ปิด</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
