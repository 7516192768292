<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  components: {},

  data: () => ({
    //
  })
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="css">
/* @import "~bootstrap/dist/css/bootstrap.css"; */
.rsu {
  font-family: 'rsu', sans-serif !important;
  font-size: 1.15rem;
}

.Montserrat {
  font-family: 'Montserrat', sans-serif !important;
}

.Kanit {
  font-family: 'Kanit', sans-serif !important;
}

.swal2-modal {
  font-family: 'Montserrat', 'Kanit', sans-serif !important;
}
.flex-all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.v-application ul,
.v-application ol {
  padding-left: 0px !important;
}

.disabled-button {
  background-color: gray !important;
}
</style>
