var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mx-auto",staticStyle:{"max-width":"550px"}},[_c('div',{staticClass:"pa-3 pt-6"},[_c('div',{staticStyle:{"font-weight":"bold"}},[_vm._v("กรุณาประเมินความพึงพอใจในการให้บริการ")]),_c('br'),_c('div',{staticClass:"mt-3"},[_vm._m(0),_c('br'),_c('ValidationObserver',{ref:"observer"},[_c('ValidationProvider',{attrs:{"name":"การประสานงานของเจ้าหน้าที่ในการนัดหมายติดตั้ง","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_vm._v("1.1 การประสานงานของเจ้าหน้าที่ในการนัดหมายติดตั้ง")]),_c('v-radio-group',{attrs:{"error-messages":errors,"row":""},model:{value:(_vm.input.installation.coordinate),callback:function ($$v) {_vm.$set(_vm.input.installation, "coordinate", $$v)},expression:"input.installation.coordinate"}},_vm._l((4),function(n){return _c('v-radio',{key:n,attrs:{"value":n,"label":n.toString()}})}),1)]}}])}),_c('ValidationProvider',{attrs:{"name":"ความตรงต่อเวลาและความสุภาพของเจ้าหน้าติดตั้ง","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_vm._v("1.2 ความตรงต่อเวลาและความสุภาพของเจ้าหน้าติดตั้ง")]),_c('v-radio-group',{attrs:{"error-messages":errors,"row":""},model:{value:(_vm.input.installation.timeAndPolite),callback:function ($$v) {_vm.$set(_vm.input.installation, "timeAndPolite", $$v)},expression:"input.installation.timeAndPolite"}},_vm._l((4),function(n){return _c('v-radio',{key:n,attrs:{"value":n,"label":n.toString()}})}),1)]}}])}),_c('ValidationProvider',{attrs:{"name":"ความเรียบร้อยและความสะดวกในการใช้งานหลังการติดตั้ง","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_vm._v("1.3 ความเรียบร้อยและความสะดวกในการใช้งานหลังการติดตั้ง")]),_c('v-radio-group',{attrs:{"error-messages":errors,"row":""},model:{value:(_vm.input.installation.neatness),callback:function ($$v) {_vm.$set(_vm.input.installation, "neatness", $$v)},expression:"input.installation.neatness"}},_vm._l((4),function(n){return _c('v-radio',{key:n,attrs:{"value":n,"label":n.toString()}})}),1)]}}])}),_c('ValidationProvider',{attrs:{"name":"ความพึงพอใจในการได้รับบริการโดยรวม","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_vm._v("1.4 ความพึงพอใจในการได้รับบริการโดยรวม")]),_c('v-radio-group',{attrs:{"error-messages":errors,"row":""},model:{value:(_vm.input.installation.complacence),callback:function ($$v) {_vm.$set(_vm.input.installation, "complacence", $$v)},expression:"input.installation.complacence"}},_vm._l((4),function(n){return _c('v-radio',{key:n,attrs:{"value":n,"label":n.toString()}})}),1)]}}])}),_c('br'),_c('div',[_c('b',[_vm._v("2. ประเมิน PC")])]),_c('br'),_c('ValidationProvider',{attrs:{"name":"PC ได้นำเสนอข้อมูลสินค้า, ให้ความรู้ความเข้าใจ, ประโยชน์ที่ลูกค้าจะได้รับ\n","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_vm._v(" 2.1 PC ได้นำเสนอข้อมูลสินค้า, ให้ความรู้ความเข้าใจ, ประโยชน์ที่ลูกค้าจะได้รับ ")]),_c('v-radio-group',{attrs:{"error-messages":errors,"row":""},model:{value:(_vm.input.pc.offer),callback:function ($$v) {_vm.$set(_vm.input.pc, "offer", $$v)},expression:"input.pc.offer"}},_vm._l((4),function(n){return _c('v-radio',{key:n,attrs:{"value":n,"label":n.toString()}})}),1)]}}])}),_c('ValidationProvider',{attrs:{"name":"ลูกค้าได้รับทราบถึงความแตกต่างของชุดครัวคิงส์เมื่อเทียบกับชุดครัวแบรนด์อื่น\n","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_vm._v(" 2.2 ลูกค้าได้รับทราบถึงความแตกต่างของชุดครัวคิงส์เมื่อเทียบกับชุดครัวแบรนด์อื่น ")]),_c('v-radio-group',{attrs:{"error-messages":errors,"row":""},model:{value:(_vm.input.pc.compare),callback:function ($$v) {_vm.$set(_vm.input.pc, "compare", $$v)},expression:"input.pc.compare"}},_vm._l((4),function(n){return _c('v-radio',{key:n,attrs:{"value":n,"label":n.toString()}})}),1)]}}])}),_c('ValidationProvider',{attrs:{"name":"ลูกค้าได้รับการแนะนำถึงสิทธิประโยชน์ และโปรโมชั่นการขายต่างๆ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_vm._v("2.3 ลูกค้าได้รับการแนะนำถึงสิทธิประโยชน์ และโปรโมชั่นการขายต่างๆ")]),_c('v-radio-group',{attrs:{"error-messages":errors,"row":""},model:{value:(_vm.input.pc.benefit),callback:function ($$v) {_vm.$set(_vm.input.pc, "benefit", $$v)},expression:"input.pc.benefit"}},_vm._l((4),function(n){return _c('v-radio',{key:n,attrs:{"value":n,"label":n.toString()}})}),1)]}}])}),_c('ValidationProvider',{attrs:{"name":"ลูกค้าได้รับการแนะนำถึงสิทธิประโยชน์ และโปรโมชั่นการขายต่างๆ\n\n","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_vm._v("2.4 การต้อนรับลูกค้า, กิริยาวาจาและบุคลิกภาพของ PC")]),_c('v-radio-group',{attrs:{"error-messages":errors,"row":""},model:{value:(_vm.input.pc.welcome),callback:function ($$v) {_vm.$set(_vm.input.pc, "welcome", $$v)},expression:"input.pc.welcome"}},_vm._l((4),function(n){return _c('v-radio',{key:n,attrs:{"value":n,"label":n.toString()}})}),1)]}}])}),_c('ValidationProvider',{attrs:{"name":"การติดตามดูแลเอาใจใส่และตอบข้อสักถามลูกค้าของ PC\n","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_vm._v("2.5 การติดตามดูแลเอาใจใส่และตอบข้อสักถามลูกค้าของ PC")]),_c('v-radio-group',{attrs:{"error-messages":errors,"row":""},model:{value:(_vm.input.pc.care),callback:function ($$v) {_vm.$set(_vm.input.pc, "care", $$v)},expression:"input.pc.care"}},_vm._l((4),function(n){return _c('v-radio',{key:n,attrs:{"value":n,"label":n.toString()}})}),1)]}}])}),_c('br'),_c('div',[_c('b',[_vm._v("3. เหตุผลสำคัญที่ลูกค้าตัดสินใจซื้อชุดครัวคิงส์ ")])]),_c('br'),_c('ValidationProvider',{attrs:{"name":"ราคา","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_vm._v("3.1 ราคา")]),_c('v-radio-group',{attrs:{"error-messages":errors,"row":""},model:{value:(_vm.input.importantReason.price),callback:function ($$v) {_vm.$set(_vm.input.importantReason, "price", $$v)},expression:"input.importantReason.price"}},_vm._l((4),function(n){return _c('v-radio',{key:n,attrs:{"value":n,"label":n.toString()}})}),1)]}}])}),_c('ValidationProvider',{attrs:{"name":"ความสวยงาม","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_vm._v("3.2 ความสวยงาม")]),_c('v-radio-group',{attrs:{"error-messages":errors,"row":""},model:{value:(_vm.input.importantReason.beautiful),callback:function ($$v) {_vm.$set(_vm.input.importantReason, "beautiful", $$v)},expression:"input.importantReason.beautiful"}},_vm._l((4),function(n){return _c('v-radio',{key:n,attrs:{"value":n,"label":n.toString()}})}),1)]}}])}),_c('ValidationProvider',{attrs:{"name":"คุณสมบัติสินค้า เช่น กันน้ำ, กันปลวก ฯลฯ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_vm._v("3.3 คุณสมบัติสินค้า เช่น กันน้ำ, กันปลวก ฯลฯ")]),_c('v-radio-group',{attrs:{"error-messages":errors,"row":""},model:{value:(_vm.input.importantReason.property),callback:function ($$v) {_vm.$set(_vm.input.importantReason, "property", $$v)},expression:"input.importantReason.property"}},_vm._l((4),function(n){return _c('v-radio',{key:n,attrs:{"value":n,"label":n.toString()}})}),1)]}}])}),_c('ValidationProvider',{attrs:{"name":"โปรโมชั่นส่งเสริมการขาย เข่น ของแถม, ส่วนลด ฯลฯ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_vm._v("3.4 โปรโมชั่นส่งเสริมการขาย เข่น ของแถม, ส่วนลด ฯลฯ")]),_c('v-radio-group',{attrs:{"error-messages":errors,"row":""},model:{value:(_vm.input.importantReason.promotion),callback:function ($$v) {_vm.$set(_vm.input.importantReason, "promotion", $$v)},expression:"input.importantReason.promotion"}},_vm._l((4),function(n){return _c('v-radio',{key:n,attrs:{"value":n,"label":n.toString()}})}),1)]}}])}),_c('ValidationProvider',{attrs:{"name":"บริการส่งพร้อมติดตั้งฟรี","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_vm._v("3.5 บริการส่งพร้อมติดตั้งฟรี")]),_c('v-radio-group',{attrs:{"error-messages":errors,"row":""},model:{value:(_vm.input.importantReason.freeService),callback:function ($$v) {_vm.$set(_vm.input.importantReason, "freeService", $$v)},expression:"input.importantReason.freeService"}},_vm._l((4),function(n){return _c('v-radio',{key:n,attrs:{"value":n,"label":n.toString()}})}),1)]}}])}),_c('ValidationProvider',{attrs:{"name":"เชื่อมั่นในแบรนด์คิงส์","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_vm._v("3.6 เชื่อมั่นในแบรนด์คิงส์")]),_c('v-radio-group',{attrs:{"error-messages":errors,"row":""},model:{value:(_vm.input.importantReason.trustBrain),callback:function ($$v) {_vm.$set(_vm.input.importantReason, "trustBrain", $$v)},expression:"input.importantReason.trustBrain"}},_vm._l((4),function(n){return _c('v-radio',{key:n,attrs:{"value":n,"label":n.toString()}})}),1)]}}])}),_c('ValidationProvider',{attrs:{"name":"พนักงานขาย (PC)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_vm._v("3.7 พนักงานขาย (PC)​")]),_c('v-radio-group',{attrs:{"error-messages":errors,"row":""},model:{value:(_vm.input.importantReason.pc),callback:function ($$v) {_vm.$set(_vm.input.importantReason, "pc", $$v)},expression:"input.importantReason.pc"}},_vm._l((4),function(n){return _c('v-radio',{key:n,attrs:{"value":n,"label":n.toString()}})}),1)]}}])}),_c('ValidationProvider',{attrs:{"name":"การให้บริการออกแบบครัว","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_vm._v("3.8 การให้บริการออกแบบครัว​")]),_c('v-radio-group',{attrs:{"error-messages":errors,"row":""},model:{value:(_vm.input.importantReason.design),callback:function ($$v) {_vm.$set(_vm.input.importantReason, "design", $$v)},expression:"input.importantReason.design"}},_vm._l((4),function(n){return _c('v-radio',{key:n,attrs:{"value":n,"label":n.toString()}})}),1)]}}])}),_c('br'),_c('ValidationProvider',{attrs:{"name":"ข้อเสนอแนะ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('b',[_vm._v("4. ข้อเสนอแนะเพิ่มเติมเพื่อนำไปปรับปรุงคุณภาพการให้บริการ")])]),_c('br'),_c('v-textarea',{attrs:{"outlined":"","background-color":"white","rows":"3","error-messages":errors},model:{value:(_vm.input.note),callback:function ($$v) {_vm.$set(_vm.input, "note", $$v)},expression:"input.note"}})]}}])})],1)],1),_c('div',{staticClass:"px-13 mt-2"},[_c('v-btn',{attrs:{"loading":_vm.saveloading,"large":"","dark":"","block":"","color":"#59BEA3"},on:{"click":_vm.saveData}},[_vm._v("ส่ง")])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b',[_vm._v("1. ประเมินบริการติดตั้ง")])])}]

export { render, staticRenderFns }