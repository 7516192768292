<template>
  <div class="upload-multiple-image">
    <v-layout wrap fill-height>
      <v-flex v-if="images.length < limit" style="cursor: pointer; height: 100px">
        <div
          class="btn-upload"
          style="
            border-radius: 10px;
            border: 2px dashed rgb(255 97 97);
            display: inline-table;
            width: 100%;
            height: 100%;
            padding-top: 13px;
          "
          :style="errors && errors.length && 'border-color:red'"
          @click="onClickUploadEvent"
          @dragover.prevent
          @drop.prevent="onFileChange($event)"
        >
          <v-responsive>
            <v-row column justify="center" fill-height align="center">
              <v-col class="pt-1" v-if="!loading">
                <div
                  class="text-center"
                  style="color: #000"
                  :style="errors && errors.length && 'color:red'"
                >
                  <div class="pt-3"><v-icon color="rgb(255 97 97)" large>camera_alt</v-icon></div>
                  <div>{{ label ? label : 'เลือกไฟล์เพื่ออัพโหลด' }}</div>
                </div>
              </v-col>
              <v-col v-else class="text-center">
                <v-progress-circular indeterminate color="primary" size="30"></v-progress-circular>
              </v-col>
            </v-row>
          </v-responsive>
        </div>
      </v-flex>

      <v-flex xs12 v-if="errors && errors.length">
        <div class="v-messages">
          <div class="error--text" style="font-size: 15px; color: #b71c1c">
            {{ errors[0] }}
          </div>
        </div>
      </v-flex>
    </v-layout>

    <div class="form-control has-error" v-if="isAlert">
      <span class="text-error">{{ 'Please try to upload file again' }}</span>
    </div>
    <input
      type="file"
      :multiple="!isAndroidOS"
      class="form-control-file pt-2"
      name="photoUpload"
      ref="photoUpload"
      data-vv-as="File"
      accept="image/*;capture=camera"
      :capture="opencamera"
      @change="onFileChange($event)"
      style="overflow: hidden; position: fixed; width: 1px; height: 1px; z-index: -1; opacity: 0"
    />
    <div class="mt-4">
      <v-row>
        <v-col
          @mouseover="hover = k"
          @mouseleave="hover = ''"
          v-for="(item, k) in value"
          :key="k"
          cols="3"
          class="pr-1"
        >
          <div style="position: relative">
            <v-img
              @click="showPreview(item)"
              style="border-radius: 6px"
              aspect-ratio="1"
              :src="item"
              @error="() => (item = null)"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <div style="position: absolute; right: -5px; top: -5px; z-index: 1" v-if="!readOnly">
              <v-btn @click.stop="deleteImg(k)" x-small fab color="primary"
                ><v-icon small>close</v-icon></v-btn
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-dialog width="550px" v-model="previewDialog" persistent>
      <v-card v-if="previewDialog">
        <div class="text-right">
          <v-btn @click="previewDialog = false" icon><v-icon>close</v-icon></v-btn>
        </div>
        <v-img :src="currentImg"></v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UpdateMultipleFileMixin from './UpdateMultipleFileMixin.js'
export default {
  mixins: [UpdateMultipleFileMixin],
  props: {
    opencamera: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      previewDialog: false,
      currentImg: ''
    }
  },
  computed: {
    isAndroidOS() {
      let isAndriod = false
      const userAgent = navigator.userAgent || navigator.vendor || window.opera

      if (/windows phone/i.test(userAgent)) {
        isAndriod = true
      }

      if (/android/i.test(userAgent)) {
        isAndriod = true
      }

      return isAndriod
    }
  },
  methods: {
    onClickUploadEvent() {
      this.$refs.photoUpload.click()
    },
    showPreview(item) {
      this.currentImg = item
      this.previewDialog = true
    }
  }
}
</script>

<style scoped lang="scss">
.btn-upload {
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}
</style>
