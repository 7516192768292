<template>
  <v-text-field
    :oninput="
      maxlength !== undefined
        ? `javascript: if (this.value.length > ${maxlength}) this.value = this.value.slice(0, ${maxlength});`
        : ''
    "
    :value="datavalue"
    :outlined="outlined"
    :placeholder="placeholder"
    :solo="solo"
    :filled="filled"
    :append-icon="appendIcon"
    :prepend-icon="prependIcon"
    :prepend-inner-icon="prependInnerIcon"
    :append-outer-icon="appendOuterIcon"
    :autofocus="autofocus"
    :background-color="backgroundColor"
    :clearIcon="clearIcon"
    :clearable="clearable"
    :single-line="singleLine"
    :solo-inverted="soloInverted"
    :readonly="readonly"
    :reverse="reverse"
    :type="type"
    :rounded="rounded"
    :dense="dense"
    :dark="dark"
    :error="error"
    :error-messages="errorMessages"
    :disabled="disabled"
    @keypress="onKeyPress"
    @paste="onKeyPress"
    @input="onInput($event)"
  ></v-text-field>
</template>

<script>
export default {
  props: [
    'value',
    'maxlength',
    'numberOnly',
    'decimalNumber',
    'dark',
    'disabled',
    'errorMessages',
    'error',
    'dense',
    'hideDetails',
    'prependIcon',
    'prependInnerIcon',
    'rounded',
    'type',
    'reverse',
    'readonly',
    'soloInverted',
    'singleLine',
    'clearIcon',
    'clearable',
    'backgroundColor',
    'outlined',
    'placeholder',
    'solo',
    'filled',
    'appendIcon',
    'appendOuterIcon',
    'autofocus'
  ],
  computed: {
    datavalue() {
      var result = this.value
      if (this.currency !== undefined || this.currency) {
      }
      return result
    }
  },
  methods: {
    onKeyPress(event) {
      if (this.numberOnly !== undefined || this.numberOnly) {
        this.onlyNumber(event)
      } else if (this.decimalNumber !== undefined || this.decimalNumber) {
        this.onlyDecimalNumber(event)
      }
    },

    onlyNumber(event) {
      var result = event.charCode >= 48 && event.charCode <= 57
      if (result) {
        // return result;
      } else {
        event.preventDefault()
      }
    },
    onlyDecimalNumber(event) {
      var result = (event.charCode >= 48 && event.charCode <= 57) || event.charCode === 46
      if (result) {
        // return result;
      } else {
        event.preventDefault()
      }
    },
    onInput(e) {
      this.$emit('input', e)
    }
  }
}
</script>

<style lang="scss" scoped></style>
