var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pt-5 px-3 pb-10"},[_vm._m(0),_c('div',[_c('ValidationObserver',{ref:"observer"},[_c('label',{staticClass:"mt-5"},[_vm._v("ชื่อ")]),_c('ValidationProvider',{attrs:{"rules":{
            required: true
          },"name":"ชื่อ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('custom-input',{attrs:{"dense":"","outlined":"","placeholder":"ชื่อ","solo":"","error-messages":errors},model:{value:(_vm.input.first_name),callback:function ($$v) {_vm.$set(_vm.input, "first_name", $$v)},expression:"input.first_name"}})]}}])}),_c('label',{staticClass:"mt-5"},[_vm._v("นามสกุล")]),_c('ValidationProvider',{attrs:{"rules":{
            required: true
          },"name":"นามสกุล"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('custom-input',{attrs:{"dense":"","type":"text","solo":"","placeholder":"นามสกุล","outlined":"","error-messages":errors},model:{value:(_vm.input.last_name),callback:function ($$v) {_vm.$set(_vm.input, "last_name", $$v)},expression:"input.last_name"}})]}}])}),_c('label',{staticClass:"mt-5"},[_vm._v("เบอร์โทรศัพท์")]),_c('ValidationProvider',{attrs:{"rules":{
            required: true,
            thai_phonenumber: true,
            digits: 10
          },"name":"เบอร์โทรศัพท์"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('custom-input',{attrs:{"dense":"","solo":"","type":"number","maxlength":"10","numberOnly":"","name":"phone","placeholder":"เบอร์โทรศัพท์","outlined":"","error-messages":errors},model:{value:(_vm.input.phoneno),callback:function ($$v) {_vm.$set(_vm.input, "phoneno", $$v)},expression:"input.phoneno"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":{
            required: true
          },"name":"ตำแหน่ง"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('label',{staticClass:"mt-5"},[_vm._v("ตำแหน่ง")]),_c('v-select',{attrs:{"solo":"","outlined":"","dense":"","placeholder":"ตำแหน่ง","error-messages":errors,"show-labels":false,"items":_vm.jobOption},on:{"input":function($event){_vm.input.department = ''
              _vm.input.location = ''
              _vm.input.branch = ''}},model:{value:(_vm.input.job),callback:function ($$v) {_vm.$set(_vm.input, "job", $$v)},expression:"input.job"}})]}}])}),(_vm.input.job !== null && ['PC', 'Sales'].includes(_vm.input.job))?_c('div',[(['PC', 'Sales'].includes(_vm.input.job))?_c('ValidationProvider',{attrs:{"rules":{
              required: true
            },"name":"เขต"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('label',{staticClass:"mt-5"},[_vm._v("เขต")]),_c('v-select',{attrs:{"solo":"","outlined":"","dense":"","placeholder":"เขต","error-messages":errors,"show-labels":false,"items":_vm.departmentOptions},model:{value:(_vm.input.department),callback:function ($$v) {_vm.$set(_vm.input, "department", $$v)},expression:"input.department"}})]}}],null,false,2236332440)}):_vm._e()],1):_vm._e(),(_vm.input.job !== null && _vm.input.job == 'PC')?_c('div',{attrs:{"id":"store"}},[(_vm.input.job == 'PC')?_c('ValidationProvider',{attrs:{"rules":{
              required: true
            },"name":"ร้านค้า"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('label',{staticClass:"mt-5"},[_vm._v("ร้านค้า")]),_c('v-select',{attrs:{"disabled":!_vm.input.department,"background-color":!_vm.input.department ? '#eee' : '',"solo":"","placeholder":"เลือกร้านค้า","dense":"","outlined":"","items":_vm.locationOptions,"error-messages":errors},on:{"input":function($event){_vm.input.branch = ''}},model:{value:(_vm.input.location),callback:function ($$v) {_vm.$set(_vm.input, "location", $$v)},expression:"input.location"}})]}}],null,false,3178271610)}):_vm._e()],1):_vm._e(),(_vm.input.job !== null && _vm.input.job == 'PC')?_c('div',{attrs:{"id":"location"}},[_c('label',{staticClass:"mt-5"},[_vm._v("สาขา")]),(_vm.input.job == 'PC')?_c('ValidationProvider',{attrs:{"rules":{
              required: true
            },"name":"สาขา"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{attrs:{"solo":"","disabled":!_vm.input.location,"placeholder":!_vm.input.location ? 'กรุณาเลือกร้านค้า' : 'เลือกสาขา',"dense":"","background-color":!_vm.input.location ? '#eee' : '',"outlined":"","items":_vm.branchOptions,"error-messages":errors},model:{value:(_vm.input.branch),callback:function ($$v) {_vm.$set(_vm.input, "branch", $$v)},expression:"input.branch"}})]}}],null,false,346848507)}):_vm._e()],1):_vm._e()],1),_c('div',{staticClass:"d-flex justify-center"},[_c('v-checkbox',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" ยอมรับ "),_c('span',{staticStyle:{"color":"red","text-decoration":"underline","cursor":"pointer"},on:{"click":_vm.seeCondition}},[_vm._v(" Term & Condition ")])])]},proxy:true}]),model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})],1),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticStyle:{"color":"#fff"},attrs:{"disabled":!_vm.checkbox || _vm.success,"x-large":"","color":"#000","loading":_vm.loading},on:{"click":_vm.regis}},[_vm._v("ลงทะเบียน")])],1)],1)]),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.privacy),callback:function ($$v) {_vm.privacy=$$v},expression:"privacy"}},[_c('v-card',{staticClass:"w-100"},[_c('v-toolbar',{staticClass:"position-fixed col-12",staticStyle:{"height":"82px","top":"0","z-index":"2"},attrs:{"dark":"","color":"#000"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.privacy = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Privacy")])],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5",staticStyle:{"text-align":"center"}},[_c('img',{staticStyle:{"height":"auto","width":"140px"},attrs:{"src":require("@/assets/logo.png")}})])}]

export { render, staticRenderFns }