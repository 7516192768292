import axios from 'axios'

export default {
  props: ['value', 'max', 'noShow', 'errors', 'color', 'label', 'readOnly'],
  data: function () {
    return {
      hover: '',
      loading: false,
      limit: this.max,
      images: this.value,
      isAlert: false,
      selectedImage: null,
      showDrag: false,
      loading: false
    }
  },
  mounted: function () {
    let vm = this
    // if (vm.images.length >= 1) {
    //   vm.selectedImage = vm.images[0]
    // }
  },
  computed: {
    lastIndex: function () {
      return this.images.length - 1
    }
  },
  methods: {
    deleteImg(k) {
      this.$swal
        .fire({
          text: 'ยืนยันการลบ ?',
          showCancelButton: true
        })
        .then((result) => {
          if (result.value) {
            this.value.splice(k, 1)
          }
        })
    },
    onMove: function (index, direction) {
      var vm = this
      if (direction === 'left') {
        vm.images.splice(index, 0, vm.images.splice(index - 1, 1)[0])
      } else if (direction === 'right') {
        vm.images.splice(index, 0, vm.images.splice(index + 1, 1)[0])
      }
    },
    fileResize: function (file) {
      return new Promise((resolve) => {
        let vm = this
        var reader = new FileReader()
        reader.onloadend = function () {
          var tempImg = new Image()
          tempImg.src = reader.result
          tempImg.onload = function () {
            var MAX_WIDTH = 1000
            var MAX_HEIGHT = 1000
            var tempW = tempImg.width
            var tempH = tempImg.height
            if (tempW > tempH) {
              if (tempW > MAX_WIDTH) {
                tempH *= MAX_WIDTH / tempW
                tempW = MAX_WIDTH
              }
            } else {
              if (tempH > MAX_HEIGHT) {
                tempW *= MAX_HEIGHT / tempH
                tempH = MAX_HEIGHT
              }
            }

            var canvas = document.createElement('canvas')
            canvas.width = tempW
            canvas.height = tempH
            var ctx = canvas.getContext('2d')
            ctx.drawImage(this, 0, 0, tempW, tempH)
            var dataURL = canvas.toDataURL('image/jpeg')
            var data = 'image=' + dataURL
            var obj = vm.dataURItoBlob(data)
            obj.name = file.name
            resolve(obj)
          }
        }
        reader.readAsDataURL(file)
      })
    },
    dataURItoBlob: function (dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString
      if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1])
      else byteString = unescape(dataURI.split(',')[1])

      // separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length)
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }

      return new Blob([ia], { type: mimeString })
    },
    checkMove: function (event) {
      let vm = this
      vm.$emit('input', vm.images)
    },
    onFileChange: async function (event) {
      let vm = this
      let files = event.target.files || event.dataTransfer.files

      var isAllImage = true
      for (var i = 0; i < files.length; i++) {
        if (!files[i].type.includes('image')) {
          isAllImage = false
          break
        }
      }
      if (isAllImage) {
        vm.loading = true
        if (!files.length) return
        if (files.length > vm.max - (vm.value ? vm.value.length : 0))
          return vm.$swal.fire({
            type: 'error',
            text: `สามารถอัพโหลดรูปภาพตัวอย่างได้ไม่เกิน ${vm.max} รูป`
          })

        var allUpload = []
        for (let file of files) {
          var obj = await vm.fileResize(file)
          allUpload.push(vm.upload(obj))
        }

        Promise.all(allUpload).then((values) => {
          vm.loading = false
          vm.$emit('input', vm.images)
          vm.$emit('onUploaded', vm.images)
          event.target.value = ''
        })
      } else {
        this.$swal.fire({
          text: 'สามารถอัพโหลดได้เฉพาะรูปภาพเท่านั้น'
        })
      }
    },
    getSignedRequest: async function (data, callback) {
      let url = `https://api-enterprise.zwiz.app/sign-s3?file-name=${data.name}&file-type=${data.type}`

      try {
        var response = await axios.get(url)

        // JSON responses are automatically parsed.
        response = response.data
        response.file = data.file
        this.uploadfile(response, function () {
          callback(null, response.url)
        })
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error)
        /* eslint-enable no-console */
        callback(new Error('session expire. Please login again.'))
      }
    },
    uploadfile: function (data, callback) {
      var vm = this
      let signedRequest = data.signedRequest
      let request = data.file
      const xhr = new XMLHttpRequest()
      xhr.open('PUT', signedRequest)
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            vm.loading = false
            callback()
          } else {
            alert('Could not upload file.')
          }
        }
      }
      xhr.send(request)
    },
    upload(file) {
      return new Promise((resolve) => {
        let data = {
          name: file.name,
          type: file.type,
          file: file
        }
        let vm = this
        vm.loading = true
        this.getSignedRequest(data, function (error, url) {
          if (error) {
            console.error(error)
            vm.loading = false
            return
          }

          url = encodeURI(url)
          vm.images.push(url)
          // if (vm.images.length >= 1 && vm.selectedImage == null) {
          //     vm.selectedImage = vm.images[0]
          // }
          vm.selectedImage = url
          resolve()

          // vm.$emit('onUploaded',url)
        })
      })
    },
    removeImage: function (index) {
      let vm = this
      if (index > 0) {
        vm.selectedImage = vm.images[index - 1]
      } else if (vm.images.length > 0) {
        vm.selectedImage = vm.images[0]
      } else {
        vm.selectedImage = null
      }
      // if (vm.images[index] === vm.selectedImage) {
      //     vm.selectedImage = null
      // }
      vm.images.splice(index, 1)

      vm.$emit('input', vm.images)
    }
  },
  watch: {
    value: function (newVal, oldVal) {
      let vm = this
      if (newVal != oldVal) {
        vm.images = []
        if (newVal) {
          newVal.forEach(function (index, val) {
            vm.images.push(val)
          })
        }

        if (vm.images.length >= 1) {
          vm.selectedImage = vm.images[0]
        } else {
          vm.selectedImage = null
        }
      }
    }
  }
}
