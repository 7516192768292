<template>
  <div ref="mainproduct">
    <v-slide-x-transition leave-absolute hide-on-leave>
      <Step1
        @close="close"
        v-if="step == 1"
        @next="step++"
        :orderRead="orderRead"
        :detaildata="detaildata"
      ></Step1>
      <Step2
        :orderid="detaildata.id"
        v-if="step == 2"
        @next="step++"
        @prev="step--"
        @onsave="$emit('onsave')"
        @oncancel="step--"
        :orderRead="orderRead"
        :detaildata="detaildata"
      ></Step2>
    </v-slide-x-transition>
  </div>
</template>

<script>
import Step1 from './Step1.vue'
import Step2 from './Step2.vue'

export default {
  props: ['detaildata', 'orderRead'],
  components: {
    Step1,
    Step2
  },
  data() {
    return {
      step: 1
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
