<template>
  <div ref="mainproduct">
    <v-slide-x-transition leave-absolute hide-on-leave>
      <OrderCalendar @close="close" v-if="step == 1" @next="step++"></OrderCalendar>
      <Form v-if="step == 2" @next="step++" @prev="step--"></Form>
      <Product v-if="step == 3" @next="step++" @prev="step--"></Product>
      <SuccessPage v-if="step == 4" @next="step++" @prev="step--"></SuccessPage>
    </v-slide-x-transition>
  </div>
</template>

<script>
import OrderCalendar from './OrderCalendar.vue'
import Form from './Form.vue'
import Product from './Product.vue'
import SuccessPage from './SuccessPage.vue'
export default {
  components: {
    OrderCalendar,
    Form,
    Product,
    SuccessPage
  },
  data() {
    return {
      step: 1
    }
  },
  methods: {
    close() {
      this.$store.state.inputCreateOrder = {
        appointmentdate: '', // YYYY-MM-DD
        appointmenttime: 'เช้า', // เช้า, บ่าย
        customerid: '',
        name: '',
        phonenumber: '',
        address: '',
        zipcode: '',
        subdistrict: '',
        district: '',
        province: '',
        productdetails: '', // array object ไม่ต้อง stringify
        saleid: '' // employeeid
      }
      this.$emit('close')
    }
  }
}
</script>
