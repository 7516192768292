import axios from 'axios'
import Swal from 'sweetalert2'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    serverUrl: 'https://api-enterprise.zwiz.app/',
    query: {},
    profile: {},
    userData: {},
    branch: '',
    afterinstallinput: {
      afterinstallimages: [], // งานหลังติดตั้งเสร็จ array url
      afterinstallcustomerchecklist: [], // ลูกค้าถือป้าย checklist array url
      afterinstalldocumentchecklist: [], // เอกสาร checklist array url
      afterinstallother: [], // optional, งานเพิ่มเติม array url
      problems: [], // ปัญหาที่พบ
      additionnalcoststatus: '', // ค่าใช้จ่ายเพิ่มเติม boolean >> true คือ มี, false คือ ไม่มี
      additionnalcostnote: '', // รายละเอียดค่าใช้จ่ายเพิ่มเติม ส่งมาถ้า additionnalcoststatus เป็น true
      additionnalcostmethod: '', // วิธีชำระเงิน เงินสด, โอนเงิน ส่งมาถ้า additionnalcoststatus เป็น true
      additionnalcostreceiptimage: '', // url รูปใบโอนเงิน ส่งมาถ้า additionnalcoststatus เป็น true และ additionnalcostmethod เป็นโอนเงิน
      additionnalcost: '' // จำนวนเงิน ส่งมาถ้า additionnalcoststatus เป็น true
    },
    input: {
      first_name: '',
      last_name: '',
      department: '',
      phoneno: '',
      location: '', // ห้าง
      branch: '', // สาขา
      job: '',
      employeeid: ''
    },
    inputCreateOrder: {
      amount: 1,
      appointmentdate: '', // YYYY-MM-DD
      appointmenttime: 'เช้า', // เช้า, บ่าย
      customerid: '',
      name: '',
      phonenumber: '',
      address: '',
      zipcode: '',
      subdistrict: '',
      district: '',
      province: '',
      productdetails: '', // array object ไม่ต้อง stringify
      saleid: '' // employeeid
    },
    installationForm: {
      orderid: '',
      jobsiteimages: {
        left: [],
        front: [],
        right: [],
        sewer: [],
        pile: [],
        window: [],
        plug: [],
        lotus: [],
        pellicle: []
      },
      googlemap: '',
      address: '',
      zipcode: '',
      subdistrict: '',
      district: '',
      province: '',
      customerid: '',
      isSaleEditOrder: false
    },
    masterData: []
  },
  mutations: {
    setAfterinstallinput(state, payload) {
      state.afterinstallinput = payload
    }
  },
  actions: {
    getBranch({ state }) {
      var vm = this
      return new Promise(async (resolve) => {
        var url = process.env.VUE_APP_API_URL + 'kingkitchen/getBranch'
        axios
          .get(url, {
            headers: {
              AUTHORIZATION: 'Bearer xxx xxx'
            }
          })
          .then((response) => {
            state.branch = response.data.data

            resolve()
          })
      })
    },
    sendMessage({ state }, message) {
      var vm = this
      return new Promise(async (resolve) => {
        var url = process.env.VUE_APP_API_URL + 'zwiz-pushmsg-webbrowser'
        var data = {
          pageid: state.query.pageid,
          userid: state.userid,
          message
        }
        if (await window.liff.isInClient()) {
          try {
            await window.liff.sendMessages([
              {
                text: message,
                type: 'text'
              }
            ])
            resolve()
          } catch (error) {
            alert(JSON.stringify(error))
            alert(JSON.stringify(error.message))
          }
        } else {
          axios
            .post(url, data, {
              headers: {
                AUTHORIZATION: 'Bearer xxx xxx'
              }
            })
            .then(async (response) => {
              if (response.data.responsecode == 200) {
                resolve()
              } else {
                if (response.data.reason) {
                  await Swal.fire({
                    text: response.data.reason
                  })
                  resolve()
                }
              }
            })
        }
      })
    },
    messengerClose() {
      MessengerExtensions.requestCloseBrowser(
        function success() {},
        function error() {
          window.close()
        }
      )
    }
  },
  modules: {}
})
