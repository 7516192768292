<template>
  <div>
    <v-container>
      <div style="text-align: center" class="mt-1">
        <img style="height: auto; width: 80px" src="@/assets/logo.png" />
      </div>
      <div class="pt-8 px-4">
        <div class="font-weight-bold" style="font-size: 25px; position: relative">
          รายการติดตั้งของฉัน
        </div>
      </div>
      <div class="mt-8 pb-15">
        <div>
          <div v-for="(item, k) in orders" :key="k" class="mb-4">
            <v-card @click="showDetail(item)" class="pa-3 rounded-xl" elevation="10">
              <div class="d-flex">
                <div>
                  <v-img
                    v-if="
                      item.jobsiteimages &&
                      item.jobsiteimages.left &&
                      item.jobsiteimages.left.length
                    "
                    class="elevation-1"
                    style="border-radius: 13px"
                    width="110px"
                    height="110px"
                    :src="item.jobsiteimages.left[0]"
                  ></v-img>
                  <v-img
                    v-else
                    class="elevation-1"
                    style="border-radius: 13px"
                    width="110px"
                    height="110px"
                    src="@/assets/empty.png"
                  ></v-img>
                </div>
                <div class="ml-3" style="position: relative; width: 100%">
                  <div class="font-weight-bold">{{ item.name }}</div>
                  <div style="font-size: 17px; color: grey">
                    ชุดครัว {{ getProductName(item.productdetails) }}
                  </div>
                  <div style="position: absolute; right: 0px; bottom: -4px; font-size: 13px">
                    {{ moment(item.appointmentdate).format('DD/MM/YYYY') }}
                  </div>
                </div>
              </div>
            </v-card>
          </div>
          <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler"></infinite-loading>
        </div>
      </div>
      <v-dialog v-model="showDetailDialog" fullscreen transition="dialog-bottom-transition">
        <v-card>
          <order-detail
            v-if="showDetailDialog"
            :detaildata="detaildata"
            @close="showDetailDialog = false"
          ></order-detail>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import InfiniteLoading from 'vue-infinite-loading'
import OrderDetail from '../TechnicianManager/OrderDetail.vue'
export default {
  components: {
    InfiniteLoading,
    OrderDetail
  },
  data() {
    return {
      showDetailDialog: false,
      detaildata: null,
      limit: 5,
      offset: 0,
      orders: [],
      state: null,
      infiniteId: 0
    }
  },
  async mounted() {
    //   await this.getOrder();
  },
  computed: {
    moment() {
      return moment
    },
    userdata() {
      return this.$store.state.userData
    }
  },
  methods: {
    async getOrder(type) {
      let response = await axios.get(process.env.VUE_APP_API_URL + 'kingkitchen/customer/order', {
        params: {
          limit: this.limit,
          offset: this.offset,
          customerId: this.$store.state.userData.customerid
        },
        headers: {
          AUTHORIZATION: 'Bearer xxx xxx'
        }
      })
      if (response.data.responsecode == 200) {
        let result = response.data.data
        if (type == 'add') {
          this.orders.push(...result)
        } else {
          this.orders = result
        }
      }
    },
    getProductName(item) {
      return item.map((el) => el.product).join(', ')
    },
    showDetail(val) {
      this.detaildata = val
      this.showDetailDialog = true
    },
    async infiniteHandler(state) {
      this.state = state
      var length = await this.getOrder('add')
      this.offset += 5
      if (length) {
        this.state.loaded()
      } else {
        this.state.complete()
      }
    }
  }
}
</script>
