<template>
  <div>
    <div v-show="tab == 'ordercustomer'">
      <CustomerOrder></CustomerOrder>
    </div>
    <div v-show="tab == 'profile'">
      <Profile></Profile>
    </div>
    <v-bottom-navigation
      fixed
      x-large
      v-model="tab"
      color="primary"
      grow
      style="bottom: -2px; max-width: 500px; left: 50%; transform: translateX(-50%)"
    >
      <v-btn value="ordercustomer" height="100%">
        <v-icon large>mdi-menu</v-icon>
      </v-btn>

      <v-btn value="profile" height="100%">
        <v-icon large>mdi-account-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import CustomerOrder from './CustomerOrder.vue'
import Profile from '../TechnicianManager/Profile.vue'
export default {
  data() {
    return {
      tab: 'ordercustomer'
    }
  },
  components: {
    Profile,
    CustomerOrder
  }
}
</script>

<style lang="scss" scoped></style>
