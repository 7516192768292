<template>
  <div>
    <div v-if="loading" class="text-center pt-16">
      <v-progress-circular size="80" indeterminate color="primary"></v-progress-circular>
    </div>
    <v-container v-else class="pt-3 Kanit pb-10">
      <v-btn
        @click="onBack"
        v-if="query && query.hasOwnProperty('canBack') && query.canBack"
        icon
        x-large
      >
        <v-icon x-large> mdi-chevron-left </v-icon>
      </v-btn>
      <h3 class="mt-1 bold text-decoration-underline">
        <u>แจ้งข้อมูลสำหรับการติดตั้ง</u>
      </h3>

      <div>
        <ValidationObserver ref="observer">
          <div class="mt-5" style="font-weight: bold; line-height: 13px">กรอกที่อยู่</div>
          <div style="font-size: 13px" class="red--text mb-2">
            *กรุณาตรวจสอบความถูกต้องของที่อยู่
          </div>
          <div>ที่อยู่</div>
          <ValidationProvider rules="required" name="ที่อยู่" v-slot="{ errors }">
            <custom-input
              type="text"
              dense
              solo
              v-model="input.address"
              outlined
              :error-messages="errors"
            ></custom-input>
          </ValidationProvider>
          <ValidationProvider name="รหัสไปรษณีย์" rules="required" v-slot="{ errors }">
            <div>รหัสไปรษณีย์</div>
            <ThaiAddressInput
              :inputtype="'number'"
              @select="onSelectAddress"
              v-model="input.zipcode"
              :type="'zipcode'"
              :errorMessages="errors"
              :texttype="'number'"
            ></ThaiAddressInput>
          </ValidationProvider>
          <!-- <label class="mt-5">เขต</label> -->
          <ValidationProvider name="เขต" rules="required" v-slot="{ errors }">
            <div>เขต</div>
            <ThaiAddressInput
              :inputtype="'text'"
              @select="onSelectAddress"
              v-model="input.district"
              :type="'amphoe'"
              :errorMessages="errors"
              :texttype="'text'"
            ></ThaiAddressInput>
          </ValidationProvider>
          <!-- <label class="mt-5">แขวง</label> -->
          <ValidationProvider name="แขวง" rules="required" v-slot="{ errors }">
            <div>แขวง</div>
            <ThaiAddressInput
              :inputtype="'text'"
              @select="onSelectAddress"
              v-model="input.subdistrict"
              :type="'district'"
              :errorMessages="errors"
              :texttype="'text'"
            ></ThaiAddressInput>
          </ValidationProvider>
          <!-- <label class="mt-5">จังหวัด</label> -->
          <ValidationProvider name="จังหวัด" rules="required" v-slot="{ errors }">
            <div>จังหวัด</div>
            <div>
              <ThaiAddressInput
                :inputtype="'text'"
                @select="onSelectAddress"
                v-model="input.province"
                :type="'province'"
                :errorMessages="errors"
                :texttype="'text'"
              ></ThaiAddressInput>
            </div>
          </ValidationProvider>
        </ValidationObserver>
      </div>

      <div class="px-3">
        <v-layout
          @click="gmapDialog = true"
          row
          wrap
          v-ripple
          class="mt-2 py-2 elevation-1"
          style="border: 1px solid grey; border-radius: 7px"
          :style="input.googlemap ? 'background-color: rgb(245 255 245)' : ''"
        >
          <v-flex class="text-center pt-1" xs2>
            <v-icon color="primary" size="36">mdi-map-marker</v-icon>
          </v-flex>
          <v-flex xs10>
            <template v-if="input.googlemap">
              <div class="green--text bold">ปักหมุดที่อยู่แล้ว</div>
              <div class="gray2">กรุณากดปุ่มเพื่อเปลี่ยนสถานที่</div>
            </template>
            <template v-else>
              <div class="gray2 bold">ปักหมุดที่อยู่</div>

              <div class="gray2">กรุณากดปุ่มเพื่อปักหมุดสถานที่</div>
            </template>
          </v-flex>
        </v-layout>
      </div>
      <div class="mt-8">
        <ProductDetailList :productdetails="productdetails" />
        <div>
          <v-checkbox v-model="confirmCB">
            <template v-slot:label>
              <div style="font-size: 13px">
                ยอมรับ<span
                  @click.stop="showConfirmDialog = true"
                  style="color: #ff7878; text-decoration-line: underline"
                  >ข้อควรทราบและข้อตรวจสอบก่อนติดตั้ง</span
                >
              </div>
            </template>
          </v-checkbox>
        </div>
        <div class="d-flex">
          <div class="flex-grow-1 mr-5">
            <v-btn @click="onClose" block color="#C4C4C4"> ยกเลิก </v-btn>
          </div>
          <div class="flex-grow-1">
            <v-btn block :disabled="!confirmCB" @click="onNext" color="#59BEA3" :dark="confirmCB"
              >ถัดไป</v-btn
            >
          </div>
        </div>
      </div>
    </v-container>
    <!-- <v-dialog
      v-model="showExample"
      persistent
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card class="p-2" style="overflow: hidden">
        <div class="text-right mt-1">
          <v-btn icon @click="showExample = false">
            <v-icon>close</v-icon>
          </v-btn>
        </div>
        <div>
          <div>
            <v-flex style="min-height: 60vh" class="flex-all-center" xs12>
              <v-img contain :src="exMain"></v-img>
            </v-flex>
          </div>
          <v-layout row>
            <v-flex
              v-for="(item, index) in items"
              :key="`showExpic-${index}`"
              @click="exMain = item"
              xs3
            >
              <v-img contain :height="imgSize" :src="item"></v-img>
            </v-flex>
          </v-layout>
        </div>
      </v-card>
    </v-dialog> -->
    <v-dialog transition="dialog-bottom-transition" v-model="gmapDialog" fullscreen>
      <v-card style="height: 100%" v-if="gmapDialog && showmap">
        <gmap v-model="input.googlemap" @close="gmapDialog = false" name="ปักหมุดที่อยู่"></gmap>
        <!-- v-model="input.googlemap" -->
        <!-- :lastordercoords="lastordercoords" -->

        <!-- <gmap @close="gmapDialog = false"></gmap> -->
      </v-card>
    </v-dialog>
    <v-dialog v-model="showConfirmDialog" width="600px" persistent>
      <v-card>
        <div class="text-right">
          <v-btn icon @click="showConfirmDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </div>
        <div class="pa-3 Kanit">
          <span style="font-weight: bold">สิ่งที่ลูกค้าควรทราบและตรวจสอบก่อนติดตั้ง</span>
          <br />
          - ชื่อที่อยู่สถานที่จัดส่งติดตั้งถูกต้อง <br />- แบบติดตั้งถูกต้องตรงตามที่ลูกค้าต้องการ
          <br />- หน้างานพร้อมติดตั้ง <br /><span class="mt-2" style="font-weight: bold"
            >ข้อควรรู้</span
          >
          <br />-
          การเตรียมพื้นที่ให้พร้อมก่อนติดตั้งชุดครัว(ระยะของท่อน้ำทิ้ง,อุปกรณ์ต่อเนื่องต่างๆเช่นสายน้ำดี)
          <br />- การใช้งานและดูแลรักษา <br />- ข้อควรระวัง
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import ThaiAddressInput from '@/components/ThaiAddressInput.vue'
import ProductDetailList from '../ProductDetailList.vue'
import Gmap from '../GmapAddress.vue'
import pdf from 'pdfvuer'
// import privacy from "./privacy";

export default {
  components: {
    //   privacy,
    ThaiAddressInput,
    Gmap,
    pdf,
    ProductDetailList
  },
  data() {
    return {
      input: {
        googlemap: '',
        address: '',
        zipcode: '',
        subdistrict: '',
        district: '',
        province: '',
        customerid: '',
        jobsiteimages: null
      },
      saveloading: false,
      loading: true,
      showConfirmDialog: false,
      confirmCB: false,
      productdetails: [],
      gmapDialog: false,
      showmap: false,
      imgSize: 'auto',
      showExample: false,
      keyupload: 0,
      refNoImage: [],
      img: require('@/assets/empty.png'),
      userData: {},
      imgSize: 120,
      errors: false,

      exMain: require('@/assets/logo_cotto.jpeg'),
      items: [
        require('@/assets/empty.png'),
        require('@/assets/empty.png'),
        require('@/assets/logo_cotto.jpeg'),
        require('@/assets/empty.png')
      ],
      customerstatus: false
    }
  },
  mounted() {
    var vm = this
    let externalScript = document.createElement('script')
    externalScript.setAttribute(
      'src',
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyBw-vWPQ7LJa6aaUjaOrB2_-pcWYgEdDQ0&language=th&libraries=places`
    )
    externalScript.async = true
    externalScript.defer = true
    externalScript.onload = () => {
      vm.showmap = true
      // resolve();
    }
    document.head.appendChild(externalScript)
    this.getOrderForm()
  },
  methods: {
    showExampleImg() {
      var url = window.location.origin + '/exampleinstallation.pdf'
      liff.openWindow({
        url,
        external: true
      })
    },
    getOrderForm() {
      let customerId
      if (this.query.usertype == 'customer') {
        customerId = this.$store.state.userData.customerid
      } else {
        customerId = this.$store.state.userData.employeeid
      }
      axios
        .get(
          process.env.VUE_APP_API_URL + 'kingkitchen/installation/' + this.query.orderid + '/order',
          {
            params: {
              customerId: customerId
            },
            headers: {
              AUTHORIZATION: 'Bearer xxxx xxxx'
            }
          }
        )
        .then((response) => {
          if (response.data.responsecode == 200 && response.data.data) {
            Object.assign(this.input, response.data.data)
            this.customerstatus = response.data.data.customerstatus
            this.productdetails = response.data.data.productdetails
          }
          this.loading = false
        })
    },
    onSelectAddress(address) {
      this.input.district = address.amphoe
      this.input.province = address.province
      this.input.subdistrict = address.district
      this.input.zipcode = address.zipcode
    },
    onClose() {
      this.$liff.closeWindow()
    },
    async onNext() {
      let valid = await this.$refs.observer.validate()
      if (!valid) {
        this.$nextTick(() => {
          var el = this.$el.querySelector('.v-messages.error--text:first-of-type')
          if (!el) {
            el = this.$el.querySelector('.error--text')
          }
          this.$vuetify.goTo(el)
          return
        })
        return
      }

      if (this.input.googlemap) {
        let form = JSON.parse(JSON.stringify(this.$store.state.installationForm))
        form.orderid = this.query.orderid
        form.googlemap = this.input.googlemap
        form.address = this.input.address
        form.zipcode = this.input.zipcode
        form.subdistrict = this.input.subdistrict
        form.district = this.input.district
        form.province = this.input.province
        form.customerid =
          this.query.usertype == 'customer'
            ? this.$store.state.userData.customerid
            : this.$store.state.userData.employeeid
        form.jobsiteimages = this.input.jobsiteimages
        form.customerstatus = this.customerstatus
        form.productdetails = this.productdetails
        this.$emit('next', form)
      } else {
        this.$swal.fire({
          text: 'กรุณาปักหมุดที่อยู่ของคุณ'
        })
      }
    },
    onBack() {
      this.$store.state.query.view = 'Manager'
    }
  },
  computed: {
    profile() {
      return this.$store.state.profile
    },
    userid() {
      return this.$store.state.userid
    },
    usertype() {
      return this.$route.query.usertype ? this.$route.query.usertype : 'customer' // customer, employee
    },
    query() {
      return this.$store.state.query
    },
    fullAddress() {
      return `${this.address.address} แขวง ${this.address.subDistrict} เขต ${this.address.district} ${this.address.province} ${this.address.postCode}`
    },
    form() {
      return this.$store.state.installationForm
    }
  }
}
</script>

<style lang="scss">
.req {
  border: 1px solid red;
}
.radius-15 {
  border: 0.5px solid transparent;
  border-radius: 15px !important;
}
.border-cus {
  border: 1px dashed red;
}
.datalabel {
  // font-weight: bold;
  color: rgb(255, 116, 116);
}
</style>
