<template>
  <div>
    <v-slide-x-transition leave-absolute hide-on-leave>
      <step-1 v-if="step == 1" @next="step++"></step-1>
      <step-2 v-if="step == 2" @prev="step--"></step-2>
    </v-slide-x-transition>
  </div>
</template>

<script>
import Step1 from './Step1.vue'
import Step2 from './Step2.vue'
export default {
  components: {
    Step1,
    Step2
  },
  data() {
    return {
      step: 1
    }
  }
}
</script>
