<template>
  <div class="Kanit" style="height: 100%">
    <form v-show="false" method="post" action name="f1">
      <input v-model="xxx" type="text" name="p_name" size="8" />
    </form>
    <div
      ref="gmap"
      style="
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
      "
    >
      <div style="flex-grow: 1">
        <v-text-field
          outlined
          solo
          v-model="text"
          hide-details
          @click:append="setCurrent"
          @hover:append="setCurrent"
          @blur="onBlur"
          :append-icon="'mdi-crosshairs-gps'"
          id="pac-input"
          type="text"
          color="primary"
          placeholder="กรอกสถานที่"
        ></v-text-field>
      </div>
      <div style="flex-grow: 13; overflow-y: auto; height: 100%" id="map" ref="map"></div>
      <div style="flex-grow: 1">
        <v-card class="pa-2">
          <v-row dense>
            <v-col cols="6">
              <v-btn @click="$emit('close')" dark color="#C4C4C4" block>ยกเลิก</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn @click="onSave" dark color="#59BEA3" block>ยืนยัน</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </div>

      <div v-if="placesinfo" class="addressinfo">
        <div v-if="infoloading" class="text-xs-center">
          <v-progress-circular size="18" indeterminate></v-progress-circular>
        </div>
        <div v-else v-html="placesinfo"></div>
      </div>
    </div>
  </div>
</template>

<script>
var map
export default {
  components: {},
  props: {
    lastordercoords: {
      type: Object
    },
    error: {
      type: Boolean
    },
    placesinfo: {
      type: String
    },
    shoplocation: {
      default: undefined
    },
    radius: {
      default: 17000
    },
    radiusColor: {
      default: '#FF0000'
    },
    showRadius: {
      default: true
    },
    scrollable: {
      default: true
    }
  },
  data() {
    return {
      countError: 0,
      text: '',
      map: '',
      xxx: 'xxx',
      infoloading: false,
      haveGPS: false,
      autocomplete: null,
      storelocation: {
        lat: 0,
        lng: 0
      },
      newwin: '',
      markerposition: {
        lat: null,
        lng: null
      },
      currentCoords: {
        lat: 0,
        lng: 0
      },
      marker: null,
      directionsService: '',
      directionsRenderer: ''
    }
  },
  computed: {
    isPcfacebook() {
      /* eslint-disable no-useless-escape */

      return !(
        // device detection
        (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
            navigator.userAgent
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            navigator.userAgent.substr(0, 4)
          )
        )
      )
      /* eslint-enable no-useless-escape */
    }
  },
  async mounted() {
    var vm = this
    try {
      if (this.shoplocation && this.shoplocation.length > 0 && this.shoplocation[0].lat_long) {
        let lat_lng = this.shoplocation[0].lat_long.split(',')
        if (lat_lng.length == 2)
          this.storelocation = {
            lat: parseFloat(lat_lng[0]),
            lng: parseFloat(lat_lng[1])
          }
      }
    } catch (error) {
      //
      // eslint-disable-next-line no-console
      console.log(error)
    }
    if (this.isPcfacebook) {
      vm.openpopup()
    } else {
      var options = {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0
      }

      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          vm.currentCoords.lat = coords.latitude
          vm.currentCoords.lng = coords.longitude
          vm.haveGPS = true
          vm.initMap()
        },
        (err) => {
          vm.currentCoords.lat = vm.storelocation.lat
          vm.currentCoords.lng = vm.storelocation.lng
          vm.haveGPS = false
          vm.initMap()

          // eslint-disable-next-line no-console
          console.warn(`ERROR(${err.code}): ${err.message}`)
        },
        options
      )
    }
  },
  methods: {
    onSave() {
      var vm = this
      vm.$emit('input', vm.markerposition)
      vm.$emit('close')
    },
    onBlur() {
      this.text = ''
    },
    openpopup() {
      var vm = this
      this.newwin = window.open(
        window.location.href.replace('InstallationForm', 'GetCurrentLocation'),
        'hello',
        'width=1,height=1'
      )

      if (!this.newwin || this.newwin.closed || typeof this.newwin.closed == 'undefined') {
        //POPUP BLOCKED
        vm.currentCoords.lat = vm.storelocation.lat
        vm.currentCoords.lng = vm.storelocation.lng
        vm.initMap()
      } else {
        this.newwin.onbeforeunload = () => {
          var popupcoords = window.document.f1.p_name.value.split(',')
          vm.currentCoords.lat = parseFloat(popupcoords[0])
          vm.currentCoords.lng = parseFloat(popupcoords[1])
          vm.initMap()
        }
      }
    },
    initMap() {
      var vm = this
      if (!(vm.currentCoords.lat && vm.currentCoords.lng)) {
        vm.currentCoords.lat = vm.storelocation.lat
        vm.currentCoords.lng = vm.storelocation.lng
      }

      var latlng = null
      if (vm.lastordercoords) {
        // eslint-disable-next-line no-undef
        latlng = new google.maps.LatLng({
          lat: parseFloat(vm.lastordercoords.lat),
          lng: parseFloat(vm.lastordercoords.lng)
        })
      } else {
        // eslint-disable-next-line no-undef
        latlng = new google.maps.LatLng({
          lat: vm.currentCoords.lat,
          lng: vm.currentCoords.lng
        })
      }
      map = new window.google.maps.Map(document.getElementById('map'), {
        center: latlng,
        zoom: 15,
        clickableIcons: false,
        zoomControl: true,
        zoomControlOptions: {
          // eslint-disable-next-line no-undef
          position: google.maps.ControlPosition.RIGHT_CENTER
        },
        gestureHandling: 'greedy',
        streetViewControl: false,
        scrollwheel: this.scrollable
      })

      map.addListener('center_changed', function () {
        vm.marker.setPosition(map.getCenter())
      })
      map.addListener('dragstart', function () {
        vm.infoloading = true
      })
      map.addListener('idle', function () {
        vm.markerposition.lat = vm.marker.getPosition().lat()
        vm.markerposition.lng = vm.marker.getPosition().lng()
        vm.infoloading = false
      })
      var input = document.getElementById('pac-input')
      // eslint-disable-next-line no-undef
      this.autocomplete = new google.maps.places.Autocomplete(input)
      this.autocomplete.bindTo('bounds', map)
      this.autocomplete.setFields(['address_components', 'geometry', 'icon', 'name'])
      this.autocomplete.addListener('place_changed', function () {
        var place = vm.autocomplete.getPlace()
        vm.marker.setPosition(place.geometry.location)
        vm.markerposition.lat = vm.marker.getPosition().lat()
        vm.markerposition.lng = vm.marker.getPosition().lng()
        map.panTo(vm.marker.getPosition())
        if (!place.geometry) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          window.alert("No details available for input: '" + place.name + "'")
          return
        }
      })
      // eslint-disable-next-line no-undef
      vm.marker = new google.maps.Marker({ map })
      vm.marker.setPosition(latlng)
      vm.markerposition.lat = vm.marker.getPosition().lat()
      vm.markerposition.lng = vm.marker.getPosition().lng()

      map.addListener('click', function (e) {
        if (!vm.marker) {
          // eslint-disable-next-line no-undef
          vm.marker = new google.maps.Marker({ map })
        }
        document.getElementById('map').focus()

        vm.marker.setPosition(e.latLng)
        map.panTo(vm.marker.getPosition())
        vm.markerposition.lat = vm.marker.getPosition().lat()
        vm.markerposition.lng = vm.marker.getPosition().lng()
      })

      if (vm.shoplocation && vm.shoplocation.length > 0) {
        vm.shoplocation.map((el) => {
          var location = null
          try {
            location = el.lat_long.split(',')
            if (location.length > 1) {
              // eslint-disable-next-line no-undef
              var shoplatlng = new google.maps.LatLng({
                lat: parseFloat(location[0]),
                lng: parseFloat(location[1])
              })

              if (vm.showRadius) {
                // eslint-disable-next-line no-undef
                new google.maps.Circle({
                  strokeColor: this.radiusColor,
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: this.radiusColor,
                  fillOpacity: 0.35,
                  map: map,
                  center: shoplatlng,
                  radius: this.radius // meters
                })
              }
              // eslint-disable-next-line no-undef
              new google.maps.Marker({
                position: shoplatlng,
                label: el.name,
                map: map
              })
            }
          } catch (error) {
            //
          }
        })
      }
    },
    setCurrent() {
      var vm = this
      var options = {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0
      }
      if (!vm.haveGPS) {
        this.countError++
        if (this.countError > 2) {
          alert(
            'Please try again or close and re-open this window in order to allow location permissions'
          )
        }

        navigator.geolocation.getCurrentPosition(
          ({ coords }) => {
            vm.currentCoords.lat = coords.latitude
            vm.currentCoords.lng = coords.longitude

            vm.haveGPS = true
          },
          (err) => {
            vm.currentCoords.lat = vm.storelocation.lat
            vm.currentCoords.lng = vm.storelocation.lng

            vm.haveGPS = false

            // eslint-disable-next-line no-console
            console.warn(`ERROR(${err.code}): ${err.message}`)
          },
          options
        )
      } else {
        // eslint-disable-next-line no-undef
        var latlng = new google.maps.LatLng({
          lat: vm.currentCoords.lat,
          lng: vm.currentCoords.lng
        })
        if (!vm.marker) {
          // eslint-disable-next-line no-undef
          vm.marker = new google.maps.Marker({ map })
        }
        vm.marker.setPosition(latlng)
        map.panTo(vm.marker.getPosition())
        vm.markerposition.lat = vm.marker.getPosition().lat()
        vm.markerposition.lng = vm.marker.getPosition().lng()
      }
    }
  },
  watch: {
    // shoplocation(newVal) {
    //   if (newVal && newVal.length > 0 && newVal[0].lat_long) {
    //     try {
    //       let lat_lng = newVal[0].lat_long.split(",");
    //       if (lat_lng.length == 2)
    //         this.storelocation = {
    //           lat: parseFloat(lat_lng[0]),
    //           lng: parseFloat(lat_lng[1]),
    //         };
    //     } catch (error) {
    //       //
    //     }
    //   }
    // },
    // markerposition: {
    //   deep: true,
    //   handler() {
    //     var vm = this;
    //     vm.$emit("input", vm.markerposition);
    //   },
    // },
  }
}
</script>

<style lang="scss">
.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0px;
}
.addressinfo {
  background-color: #505c77;
  width: 190px;
  min-height: 40px;
  bottom: 60%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translateX(-50%);
  position: absolute;
  word-break: break-word;
  color: white;
  padding: 7px;
  border-radius: 3px;
  font-size: 10px;
}
</style>
