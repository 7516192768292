<template>
  <div>
    <div class="pt-8 px-4">
      <div class="font-weight-bold" style="font-size: 25px; position: relative">
        ข้อมูลการสั่งซื้อ
        <div style="position: absolute; right: 0px; top: -15px">
          <v-btn
            @click="
              createOrderDialog = true
              $store.state.inputCreateOrder.appointmentdate = ''
            "
            x-large
            icon
            elevation="3"
            color="primary"
          >
            <v-icon large>mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="mt-6">
        <v-text-field
          @input="changeActive(active)"
          v-model="search"
          class="boxshadow-none"
          solo-inverted
          dense
          placeholder="ค้นหาชื่อ หรือ Customer ID"
        ></v-text-field>
      </div>
      <div class="text-center">
        <v-btn
          @click="changeActive('pending')"
          class="mr-2"
          rounded
          :outlined="active !== 'pending'"
          color="primary"
          :loading="loading"
          >ยังไม่ตรวจสอบ</v-btn
        >
        <v-btn
          @click="changeActive('approved')"
          rounded
          class="ml-2"
          :outlined="active !== 'approved'"
          color="primary"
          :loading="loading"
          >ตรวจสอบแล้ว</v-btn
        >
      </div>
      <div class="mt-8 pb-15">
        <div>
          <div v-for="(item, k) in orders" :key="k" class="mb-4">
            <v-card class="px-3 pb-3 pt-1 rounded-xl" elevation="5" style="border: 1px solid grey">
              <div class="text-right" v-if="$store.state.userData.job == 'Sales'">
                <v-btn
                  text
                  class="primary--text pr-2"
                  style="font-size: 14px; cursor: pointer"
                  @click="onEditData(item)"
                >
                  แก้ไขข้อมูล
                </v-btn>
                <v-btn icon ripple @click="onDeleteOrder(item.id)">
                  <v-icon>close</v-icon>
                </v-btn>
              </div>
              <div style="cursor: pointer">
                <!-- <div @click="showDetail(item)"></div> -->
                <!-- <div class="ml-3" style="position: relative; width: 100%; "> -->
                <div class="d-flex pt-3" style="width: 100%">
                  <div @click="showDetail(item)">
                    <v-img
                      contain
                      aspect-ratio="1"
                      v-if="
                        item.jobsiteimages &&
                        item.jobsiteimages.left &&
                        item.jobsiteimages.left.length
                      "
                      class="elevation-1"
                      style="border-radius: 13px"
                      width="110px"
                      :src="item.jobsiteimages.left[0]"
                    ></v-img>
                    <v-img
                      v-else
                      aspect-ratio="1"
                      class="elevation-1"
                      style="border-radius: 13px"
                      width="110px"
                      src="@/assets/empty.png"
                    ></v-img>
                  </div>
                  <div class="ml-3" style="position: relative; width: 100%">
                    <div @click="showDetail(item)" style="height: 100%">
                      <div>{{ item.name }}</div>
                      <div>
                        {{ getProductName(item.productdetails) }}
                      </div>
                    </div>
                    <div style="position: absolute; right: 0; top: 0; height: 100%">
                      <div style="position: absolute; right: 0px; bottom: -4px; font-size: 13px">
                        {{ moment(item.appointmentdate).format('DD/MM/YYYY') }}
                        ช่วง
                        {{ item.appointmenttime || '-' }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- </div> -->
              </div>
              <div v-if="$store.state.userData.job == 'Sales' && active == 'pending'">
                <div v-if="item.googlemap">
                  <v-row dense class="px-5 pt-3">
                    <v-col cols="6" class="pr-2">
                      <v-btn
                        @click.stop="showRejectDialog(item)"
                        large
                        depressed
                        block
                        color="#FFECDF"
                        style="color: #ff4206; text-transform: none"
                        >Reject</v-btn
                      >
                    </v-col>
                    <v-col cols="6" class="pl-2">
                      <v-btn
                        large
                        depressed
                        block
                        color="#59BEA3"
                        dark
                        @click.stop="onApprove(item)"
                        style="text-transform: none"
                        >Approve</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
                <div v-else class="orange--text text-center mt-2">รอดำเนินการ</div>
              </div>
              <div v-if="active == 'approved'">
                <v-row dense class="px-0 pt-3">
                  <v-col cols="6" class="pr-1">
                    <v-btn
                      class="py-5"
                      @click.stop="showBeforeForm(item)"
                      :disabled="!item.beforeinstallhousenoimages"
                      :class="{ 'disabled-button': !item.beforeinstallhousenoimages }"
                      depressed
                      block
                      small
                      color="#FFECDF"
                      style="color: #ff4206; text-transform: none"
                      >ดูข้อมูลก่อนติดตั้ง</v-btn
                    >
                  </v-col>
                  <v-col cols="6" class="pl-1">
                    <v-btn
                      :disabled="!item.afterinstallimages"
                      :class="{ 'disabled-button': !item.afterinstallimages }"
                      class="py-5"
                      depressed
                      block
                      small
                      color="#59BEA3"
                      @click.stop="showAfterForm(item)"
                      style="text-transform: none"
                      >ดูข้อมูลปิดงาน</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </div>
          <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler"></infinite-loading>
        </div>
      </div>
    </div>
    <v-dialog v-model="detailDialog" fullscreen transition="dialog-bottom-transition">
      <v-card>
        <order-detail
          v-if="detailDialog"
          :detaildata="detaildata"
          @close="detailDialog = false"
        ></order-detail>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showNotificationAlert" fullscreen transition="dialog-bottom-transition">
      <v-card>
        <NotificationAlert
          v-if="showNotificationAlert"
          :message="notificationMessage"
          @close="closeNotifcationDialog"
        />
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="createOrderDialog" fullscreen>
      <v-card>
        <CreateOrder @close="createOrderDialog = false"></CreateOrder>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="editOrderDialog" fullscreen>
      <v-card>
        <CreateOrder v-if="editOrderDialog" @close="editOrderDialog = false"></CreateOrder>
      </v-card>
    </v-dialog>

    <v-dialog v-model="rejectDialog" persistent width="550px">
      <v-card class="Kanit">
        <div class="text-right">
          <v-btn @click="rejectDialog = false" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </div>
        <div class="px-3 pb-3">
          <div style="font-weight: bold; font-size: 18px">โปรดระบุเหตุผลการ reject</div>
          <div style="font-size: 14px">*ระบบจะแจ้งเหตุผลกลับไปยัง PC ผู้ดูแล</div>
          <div class="mt-2">
            <ValidationObserver ref="observer">
              <ValidationProvider
                :rules="{
                  required: true
                }"
                name="เหตุผลที่ Reject"
                v-slot="{ errors }"
              >
                <v-textarea :error-messages="errors" outlined v-model="rejectReason"> </v-textarea>
              </ValidationProvider>
            </ValidationObserver>
          </div>
          <div>
            <v-row dense>
              <v-col cols="6" class="pr-2">
                <v-btn @click="rejectDialog = false" block depressed color="#C4C4C4" dark
                  >ยกเลิก</v-btn
                >
              </v-col>
              <v-col cols="6" class="pl-2">
                <v-btn
                  :loading="rejectloading"
                  @click="onReject"
                  block
                  depressed
                  color="#59BEA3"
                  dark
                  >ยืนยัน</v-btn
                ></v-col
              >
            </v-row>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog transition="dialog-bottom-transition" persistent v-model="afterformDialog" fullscreen>
      <v-card v-if="afterformDialog">
        <AfterInstallationForm
          @close="afterformDialog = false"
          :detaildata="afterformdata"
          :orderRead="orderReadOnly"
        ></AfterInstallationForm>
      </v-card>
    </v-dialog>

    <v-dialog
      transition="dialog-bottom-transition"
      persistent
      v-model="beforeformDialog"
      fullscreen
    >
      <v-card v-if="beforeformDialog">
        <BeforeInstallationForm
          @close="beforeformDialog = false"
          :detaildata="beforeformdata"
          :orderRead="orderReadOnly"
        ></BeforeInstallationForm>
      </v-card>
    </v-dialog>

    <v-dialog v-model="chooseEditFormDialog" persistent width="350px">
      <v-card class="pa-4 Kanit rounded-xl">
        <div class="text-right">
          <v-btn @click="chooseEditFormDialog = false" ripple icon>
            <v-icon>close</v-icon>
          </v-btn>
        </div>
        <v-layout wrap>
          <v-flex xs12 class="text-center mb-6">
            <v-btn width="200px" class="red--text" color="#CCC" @click="onSelectedForm('customer')"
              >ฝั่งลูกค้า</v-btn
            >
          </v-flex>
          <v-flex xs12 class="text-center mb-4">
            <v-btn width="200px" class="red--text" color="#CCC" @click="onSelectedForm('pc')"
              >ฝั่ง PC</v-btn
            >
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import OrderDetail from './OrderDetail.vue'
import CreateOrder from './CreateOrder/main.vue'
import axios from 'axios'
import InfiniteLoading from 'vue-infinite-loading'
import moment from 'moment'
import NotificationAlert from './NotifcationAlert.vue'
import AfterInstallationForm from '../TechnicianManager/AfterInstallationForm/main.vue'
import BeforeInstallationForm from '../TechnicianManager/BeforeInstallationForm/main.vue'

export default {
  components: {
    OrderDetail,
    CreateOrder,
    InfiniteLoading,
    NotificationAlert,
    AfterInstallationForm,
    BeforeInstallationForm
  },
  data() {
    return {
      infiniteId: 1,
      afterformdata: '',
      afterformDialog: false,
      beforeformdata: '',
      beforeformDialog: false,
      rejectDialog: false,
      rejectloading: false,
      rejectReason: '',
      rejectData: '',
      search: '',
      loading: false,
      active: 'pending',
      detailDialog: false,
      createOrderDialog: false,
      limit: 5,
      offset: 0,
      orders: [],
      state: null,
      showNotificationAlert: false,
      notificationMessage: '',
      orderReadOnly: false,
      chooseEditFormDialog: false,
      editOrderDialog: false
    }
  },
  computed: {
    moment() {
      return moment
    },
    userdata() {
      return this.$store.state.userData
    }
  },
  async mounted() {
    // await this.getData();
    if (this.query.id && this.orders && this.orders.length > 0) {
      this.handleShowDetail()
    }
  },
  methods: {
    showAfterForm(item) {
      this.afterformdata = item
      this.orderReadOnly = item.afterinstallimages && item.afterinstallimages.length > 0
      this.afterformDialog = true
    },
    showBeforeForm(item) {
      this.beforeformdata = item
      this.orderReadOnly =
        item.beforeinstallhousenoimages && item.beforeinstallhousenoimages.length > 0
      this.beforeformDialog = true
    },
    showRejectDialog(item) {
      this.rejectReason = ''
      this.rejectData = item
      this.rejectDialog = true
    },
    async onApprove(item) {
      await this.$swal
        .fire({
          text: 'ยืนยันการ Approve ?',
          showCancelButton: true,
          confirmButtonText: 'ยืนยัน',
          reverseButtons: true,
          cancelButtonText: 'ยกเลิก',
          showLoaderOnConfirm: true,
          preConfirm: (login) => {
            var data = {
              orderid: item.id,
              status: 'approved', // approved, reject
              salename: `${this.userdata.first_name} ${this.userdata.last_name}` // ชื่อ sale ที่ approve ส่งมาเฉพาะ status เป็น approved
            }
            return axios
              .put(this.serverUrl + 'kingkitchen/verifyOrderBySale', data, {
                headers: {
                  AUTHORIZATION: 'Bearer xxx xxx'
                }
              })
              .then((response) => {
                if (response.data.responsecode == 200) {
                  return
                }
              })
              .catch(async (error) => {
                await this.$swal.fire({
                  text: error.response.data.reason
                })
                if (error.response.data.responsecode == 400) {
                  this.$liff.closeWindow()
                }
              })
          }
        })
        .then(async (result) => {
          if (result.value) {
            await this.$swal.fire({
              text: 'ระบบได้บันทึกข้อมูลคำสั่งซื้อนี้เรียบร้อยแล้ว',
              confirmButtonText: 'ปิด',
              confirmButtonColor: 'grey'
            })
            this.changeActive(this.active)
          }
        })
    },
    async onReject() {
      let pass = await this.$refs.observer.validate()
      if (!pass) {
        return
      }

      this.rejectloading = true
      var data = {
        orderid: this.rejectData.id,
        status: 'reject', // approved, reject
        reason: this.rejectReason
      }
      axios
        .put(process.env.VUE_APP_API_URL + 'kingkitchen/verifyOrderBySale', data, {
          headers: {
            AUTHORIZATION: 'Bearer xxx xxx'
          }
        })
        .then(async (response) => {
          this.rejectloading = false
          this.rejectDialog = false
          if (response.data.responsecode == 200) {
            this.notificationMessage = 'ระบบได้ทำการแจ้งเตือนไปยัง PC เรียบร้อยแล้ว'
            this.showNotificationAlert = true
            // await this.$swal.fire({
            //   text: "ระบบได้บันทึกข้อมูลคำสั่งซื้อนี้เรียบร้อยแล้ว",
            //   confirmButtonText: "ปิด",
            //   confirmButtonColor: "grey",
            // });
            //this.changeActive(this.active);
          }
        })
        .catch(async (error) => {
          await this.$swal.fire({
            text: error.response.data.reason
          })
          if (error.response.data.responsecode == 400) {
            this.$liff.closeWindow()
          }
        })
    },
    async infiniteHandler(state) {
      this.state = state
      var length = await this.getData('add')
      this.offset += 5
      if (length) {
        this.state.loaded()
      } else {
        this.state.complete()
      }
    },
    changeActive(type) {
      this.active = type
      this.limit = 5
      this.offset = 0
      this.orders = []
      this.state.reset()
      // this.getData();
    },
    getProductName(item) {
      return item.map((el) => el.product).join(', ')
    },
    async getData(type = null) {
      const vm = this
      try {
        const data = {
          tab: vm.active, // pending คือ ยังไม่ตรวจสอบ, approved คือ ตรวจสอบแล้ว
          search: vm.search, // optional
          limit: vm.limit,
          offset: vm.offset,
          assignee: vm.$store.state.userData.employeeid,
          type: vm.$store.state.userData.job,
          location: vm.$store.state.userData.location,
          department: vm.$store.state.userData.department
        }

        if (!vm.loading) {
          vm.loading = true

          const response = await axios.get(process.env.VUE_APP_API_URL + 'kingkitchen/getOrders', {
            params: data,
            headers: {
              AUTHORIZATION: 'Bearer xxx xxx'
            }
          })
          vm.loading = false

          if (response && response.status === 200) {
            if (type === 'add') {
              this.orders.push(...response.data.data)
            } else {
              this.orders = response.data.data
            }

            return response.data.data.length
          }
        }
      } catch (error) {
        vm.loading = false
        console.error(error)
      }
    },
    handleShowDetail() {
      const vm = this
      const orderId = vm.query.id
      const allOrders = JSON.parse(JSON.stringify(vm.orders))
      const foundOrder = allOrders.find((el) => el.id === orderId)
      vm.showDetail(foundOrder)
    },
    showDetail(item) {
      console.log('item: ', item)
      this.detaildata = item
      this.detailDialog = true
    },
    closeNotifcationDialog() {
      this.showNotificationAlert = false
      this.notifcationMessage = ''
      this.changeActive('pending')
    },
    onEditData(item) {
      if (this.$store.state.userData.job == 'Sales') {
        this.chooseEditFormDialog = true
        this.detaildata = item
      } else {
        this.openInstallation(item.id)
      }
    },
    onSelectedForm(val) {
      const item = this.detaildata
      if (val == 'customer') {
        this.openInstallation(item.id)
      } else {
        this.openEditOrder()
      }
      this.chooseEditFormDialog = false
    },
    openEditOrder() {
      // console.log("detaildata",this.detaildata)
      this.$store.state.inputCreateOrder = this.detaildata
      this.$store.state.isSaleEditOrder = true
      this.editOrderDialog = true
    },
    openInstallation(orderId) {
      let params = this.$store.state.query
      params.view = 'InstallationForm'
      params.orderid = orderId
      params.canBack = true
    },
    onDeleteOrder(orderId) {
      this.$swal
        .fire({
          text: 'ยืนยันการลบรายการ ?',
          showCancelButton: true
        })
        .then((result) => {
          if (result.value) {
            this.deleteOrder(orderId)
          }
        })
    },
    deleteOrder(orderId) {
      let data = {
        orderid: orderId
      }
      axios
        .put(this.serverUrl + 'kingkitchen/updateOrderToInactive', data, {
          headers: {
            AUTHORIZATION: 'Bearer xxx xxx'
          }
        })
        .then((response) => {
          if (response.data.responsecode == 200) {
            this.$swal.fire({
              text: 'ลบรายการสำเร็จ'
            })
            this.offset = 0
            this.getData()
            this.$forceUpdate()
          }
        })
    }
  },
  watch: {
    orders() {
      if (this.query.id && this.orders && this.orders.length > 0) {
        this.handleShowDetail()
      }
    }
  }
}
</script>

<style lang="scss">
.boxshadow-none .v-input__control .v-input__slot {
  box-shadow: none !important;
}
</style>
