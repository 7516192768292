<template>
  <div class="Kanit">
    <div v-if="loading" class="text-center">
      <v-progress-circular
        indeterminate
        color="primary"
        size="60"
        class="mt-15"
      ></v-progress-circular>
    </div>
    <div v-else class="pb-10">
      <div style="max-width: 500px" class="mx-auto pa-3">
        <div v-if="edittype == 'product'" class="text-right">
          <v-btn @click="$emit('close')" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div v-else>
          <v-btn @click="$emit('close')" text> <v-icon>mdi-chevron-left</v-icon> กลับ </v-btn>
        </div>
        <div class="px-1 pt-5 mb-3">
          <v-row dense>
            <v-col cols="6" class="pb-3">
              <div style="font-size: 20px; font-weight: 600">ตรวจสอบคิวช่าง</div>
              <div>กรุณาเลือกวันที่</div></v-col
            >
            <v-col cols="6" style="text-align: -webkit-right">
              <v-select
                style="width: 90px"
                hide-details
                v-model="$store.state.inputCreateOrder.amount"
                label="จำนวนคิว"
                outlined
                :items="[1, 2, 3, 4, 5]"
              >
              </v-select
            ></v-col>
          </v-row>
        </div>
        <v-card>
          <div @click="setPosition">
            <v-date-picker
              :events="events"
              :event-color="'red'"
              :allowed-dates="allowedDates"
              @input="show"
              color="primary"
              full-width
              no-title
              v-model="$store.state.inputCreateOrder.appointmentdate"
            ></v-date-picker>
          </div>
        </v-card>
      </div>

      <div class="text-center mt-6">
        <v-btn
          @click="updateData"
          :disabled="!$store.state.inputCreateOrder.appointmentdate"
          :loading="updateLoading"
          class="px-10"
          large
          depressed
          dark
          color="#59BEA3"
          v-if="isChangeDate"
          >บันทึก</v-btn
        >
        <v-btn
          v-else-if="isEditOrder"
          :disabled="!$store.state.inputCreateOrder.appointmentdate"
          @click="nextStep"
          large
          depressed
          dark
          class="px-10"
          color="#59BEA3"
          >บันทึก
        </v-btn>
        <v-btn
          v-else
          :disabled="!$store.state.inputCreateOrder.appointmentdate"
          @click="nextStep"
          large
          rounded
          color="primary"
        >
          <v-icon class="mr-2" style="border: 2px solid white; border-radius: 20px"
            >mdi-plus</v-icon
          >
          เพิ่มคำสั่งซื้อ</v-btn
        >
      </div>
    </div>

    <v-menu
      :close-on-click="false"
      :close-on-content-click="false"
      v-model="showTooltip"
      :position-x="x"
      :position-y="y + 10"
      absolute
      offset-y
    >
      <v-card class="Kanit">
        <div class="pa-5">คิวว่าง : {{ availableQueue }}</div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {
  props: {
    isChangeDate: {
      type: Boolean,
      default: false
    },
    isEditOrder: {
      type: Boolean,
      default: false
    },
    orderid: {},
    edittype: {}
  },
  data() {
    return {
      loading: true,
      updateLoading: false,
      availableQueue: 0,
      x: 0,
      y: 0,
      tempX: 0,
      tempY: 0,
      showTooltip: false,
      appointmentComputed: {},

      tempDate: '',
      events: [],
      appointmentData: [],
      holidays: [],
      queueList: {}
    }
  },
  async mounted() {
    await this.getHolidays()
    await this.getData()
    await this.getQuere()
    if (this.isChangeDate || this.isEditOrder || this.edittype == 'product') {
      this.$store.state.inputCreateOrder.appointmentdate = moment(
        this.$store.state.inputCreateOrder.appointmentdate
      ).format('YYYY-MM-DD')
      this.tempDate = moment(this.$store.state.inputCreateOrder.appointmentdate).format(
        'YYYY-MM-DD'
      )
      this.events = [
        moment(this.$store.state.inputCreateOrder.appointmentdate).format('YYYY-MM-DD')
      ]
    }
  },
  computed: {
    amount() {
      return this.$store.state.inputCreateOrder.amount
    },
    appointmentdate() {
      return moment(this.$store.state.inputCreateOrder.appointmentdate).format('YYYY-MM-DD')
    }
  },
  methods: {
    updateData() {
      this.updateLoading = true
      var data = {
        orderid: this.orderid,
        appointmentdate: moment(this.$store.state.inputCreateOrder.appointmentdate).format(
          'YYYY-MM-DD'
        ), // YYYY-MM-DD
        amount: this.amount,
        employeeid: this.$store.state.userData.employeeid
      }
      axios
        .put(this.serverUrl + 'kingkitchen/changeAppointment', data, {
          headers: {
            AUTHORIZATION: 'Bearer xxx xxxx'
          }
        })
        .then(async (response) => {
          if (response.data.responsecode == 200) {
            await this.$store.dispatch('sendMessage', `เปลี่ยนวันนัดสำเร็จ: ${this.orderid}`)
            await this.$swal.fire({
              text: 'สำเร็จ',
              icon: 'success'
            })
          }
          this.updateLoading = false
          this.$emit('onUpdateData', data.appointmentdate)
        })
    },
    nextStep() {
      if (this.edittype == 'product') {
        this.$emit('onsave')
      } else {
        this.$emit('next')
      }
    },
    getQuere() {
      var data = {
        pageid: this.query.pageid
      }

      axios
        .get(this.serverUrl + 'kingkitchen/setQueue', {
          headers: { AUTHORIZATION: 'Bearer xxx xxx' },
          params: data
        })
        .then((response) => {
          if (response.data.responsecode == 200) {
            this.queueList = response.data.data
          }
        })
    },
    allowedDates(date) {
      let result = true
      let day = moment(date).format('dddd')

      let quota = this.queueList[date]
      let availableQueue =
        quota - (this.appointmentComputed[date] ? this.appointmentComputed[date] : 0)
      let diff = moment(new Date()).diff(moment(date))
      let holidays = this.holidays

      // console.log(`${date} quota: `,quota)
      // console.log(`${date} availableQueue: `,availableQueue)
      // console.log(`${date} diff: `,diff)
      // console.log(`${holidays} diff: `,holidays)

      if (availableQueue < 1) {
        //check available queue
        result = false
      } else if (availableQueue - this.amount < 0) {
        //check quota as select amount
        result = false
      } else if (diff > 0) {
        //check diff date
        result = false
      } else if (holidays.includes(date)) {
        //check holidays
        result = false
      } else {
        result = true
      }

      // Check holiday
      // let holidays = this.holidays;
      // if(holidays.includes(date)) {
      //   result = false;
      // }

      // if (true) {
      //   var diffchange = moment(date).diff(moment(new Date()), "days");
      //   console.log(`${date} diffchange: `,diffchange)

      //   if (diffchange > 2) {
      //     // result = true;
      //   } else {
      //     result = false;
      //   }
      // }
      // console.log(`${date} : `,result)
      return result
    },
    getData() {
      axios
        .get(this.serverUrl + 'kingkitchen/getAppointmentDateList', {
          params: {
            currentdate: moment().format('YYYY-MM-DD')
          },
          headers: {
            AUTHORIZATION: 'Bearer xxx xxx'
          }
        })
        .then((response) => {
          this.loading = false
          this.appointmentData = response.data.data
          var result = {}

          this.appointmentData.forEach((val) => {
            if (result.hasOwnProperty(moment(val.appointmentdate).format('YYYY-MM-DD'))) {
              result[moment(val.appointmentdate).format('YYYY-MM-DD')] += parseInt(val.count)
            } else {
              Object.assign(result, {
                [moment(val.appointmentdate).format('YYYY-MM-DD')]: parseInt(val.count)
              })
            }
          })

          this.appointmentComputed = result
        })
    },
    getHolidays() {
      var data = {
        pageid: this.query.pageid
      }

      axios
        .get(this.serverUrl + 'kingkitchen/holidays', {
          headers: { AUTHORIZATION: 'Bearer xxx xxx' },
          params: data
        })
        .then((response) => {
          if (response.data.responsecode == 200) {
            this.holidays = response.data.holidays
          }
        })
    },
    setPosition(e) {
      this.showTooltip = false
      this.tempX = e.clientX
      this.tempY = e.clientY
    },
    show(e) {
      setTimeout(() => {
        this.$nextTick(() => {
          this.x = this.tempX
          this.y = this.tempY
          var quota = null

          let allQueueOrder = this.queueList
          if (allQueueOrder) {
            let key = Object.keys(allQueueOrder).find((key) => e.includes(key))
            if (key) {
              quota = allQueueOrder[key]
            }
          }

          if (!quota) {
            var day = moment(e).format('dddd')
            if (['Saturday', 'Sunday'].includes(day)) {
              quota = 8
            } else {
              quota = 10
            }
          }

          if (this.appointmentComputed.hasOwnProperty(e)) {
            this.availableQueue = quota - this.appointmentComputed[e]
          } else {
            this.availableQueue = quota
          }

          this.showTooltip = true
        })
      })
    }
  }
}
</script>
