<template>
  <div>
    <v-dialog ref="dialog" v-model="menu2" persistent width="290px">
      <template v-slot:activator="{ on, attrs }">
        <div v-on="on">
          <slot></slot>
        </div>
      </template>
      <v-date-picker v-model="date" scrollable @input="onInput">
        <!-- <v-spacer></v-spacer>
        <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
        <v-btn text color="primary" @click="$refs.dialog.save(date)">
          OK
        </v-btn> -->
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['value'],
  data() {
    return {
      date: this.value,
      menu2: false
    }
  },
  methods: {
    onInput(date) {
      this.menu2 = false

      this.$emit('input', date)
    }
  }
}
</script>

<style lang="scss" scoped></style>
