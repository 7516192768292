var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"px-4 Kanit pb-5"},[_c('ValidationObserver',{ref:"observer"},[_c('ValidationProvider',{attrs:{"name":"สินค้า","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_vm._v("สินค้า")]),(_vm.showOtherProduct)?_c('v-combobox',{ref:"productother",attrs:{"error-messages":errors,"placeholder":"อื่นๆ (โปรดระบุ)","dense":"","outlined":"","solo":"","items":_vm.productOptions},on:{"change":_vm.onSelectProduct},model:{value:(_vm.input.product),callback:function ($$v) {_vm.$set(_vm.input, "product", $$v)},expression:"input.product"}}):_c('v-select',{attrs:{"error-messages":errors,"dense":"","outlined":"","solo":"","items":_vm.productOptions},on:{"change":_vm.onSelectProduct},model:{value:(_vm.input.product),callback:function ($$v) {_vm.$set(_vm.input, "product", $$v)},expression:"input.product"}})]}}])}),(['DIY', 'Built-in'].includes(_vm.input.product))?[_c('ValidationProvider',{attrs:{"name":"รูปแบบการติดตั้ง","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_vm._v("รูปแบบการติดตั้ง")]),_c('v-text-field',{attrs:{"outlined":"","solo":"","dense":"","error-messages":errors},model:{value:(_vm.input.format),callback:function ($$v) {_vm.$set(_vm.input, "format", $$v)},expression:"input.format"}})]}}],null,false,1158787249)}),_c('ValidationProvider',{attrs:{"name":"แนบภาพตัวอย่าง","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('UploadMultipleFile',{key:_vm.keyupload,attrs:{"errors":errors,"multiple":true,"label":'แนบภาพตัวอย่าง',"max":55},model:{value:(_vm.input.photos),callback:function ($$v) {_vm.$set(_vm.input, "photos", $$v)},expression:"input.photos"}})]}}],null,false,704107692)})]:_vm._e(),(['ตัวโชว์ (display)'].includes(_vm.input.product))?[_c('ValidationProvider',{attrs:{"name":"แนบภาพตัวอย่าง"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('UploadMultipleFile',{key:_vm.keyupload,attrs:{"errors":errors,"multiple":true,"label":'แนบภาพตัวอย่าง',"max":55},model:{value:(_vm.input.photos),callback:function ($$v) {_vm.$set(_vm.input, "photos", $$v)},expression:"input.photos"}})]}}],null,false,704107692)})]:_vm._e(),_c('ValidationProvider',{attrs:{"name":"ซิงค์","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mt-2"},[_vm._v("ซิงค์")]),(_vm.showOtherSink)?_c('v-combobox',{ref:"sinkother",attrs:{"error-messages":errors,"placeholder":"อื่นๆ (โปรดระบุ)","dense":"","outlined":"","solo":"","items":_vm.sinkOptions},on:{"change":_vm.onSelectSink},model:{value:(_vm.input.sink),callback:function ($$v) {_vm.$set(_vm.input, "sink", $$v)},expression:"input.sink"}}):_c('v-select',{attrs:{"error-messages":errors,"dense":"","outlined":"","solo":"","items":_vm.sinkOptions},on:{"change":_vm.onSelectSink},model:{value:(_vm.input.sink),callback:function ($$v) {_vm.$set(_vm.input, "sink", $$v)},expression:"input.sink"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"อุปกรณ์อื่นๆ","rules":'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mt-2"},[_vm._v("อุปกรณ์อื่นๆ")]),_c('v-select',{attrs:{"error-messages":errors,"dense":"","outlined":"","solo":"","items":_vm.otherOptions},model:{value:(_vm.input.other),callback:function ($$v) {_vm.$set(_vm.input, "other", $$v)},expression:"input.other"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"หมายเหตุ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"mt-2"},[_vm._v("หมายเหตุ")]),_c('v-textarea',{attrs:{"rows":"3","error-messages":errors,"dense":"","outlined":"","solo":"","items":_vm.otherOptions},model:{value:(_vm.input.remark),callback:function ($$v) {_vm.$set(_vm.input, "remark", $$v)},expression:"input.remark"}})]}}])})],2),_c('div',{staticClass:"text-center px-3"},[_c('v-btn',{attrs:{"dark":"","color":"#59BEA3"},on:{"click":_vm.submitData}},[_vm._v(_vm._s(_vm.edittype == 'create' ? 'เพิ่มสินค้า' : 'แก้ไขสินค้า'))])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }