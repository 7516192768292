<template>
  <div>
    <div style="max-width: 550px" class="mx-auto">
      <div class="pa-3 pt-6">
        <div style="font-weight: bold">กรุณาประเมินความพึงพอใจในการให้บริการ</div>
        <br />
        <div class="mt-3">
          <div><b>1. ประเมินบริการติดตั้ง</b></div>
          <br />
          <ValidationObserver ref="observer">
            <ValidationProvider
              name="การประสานงานของเจ้าหน้าที่ในการนัดหมายติดตั้ง"
              rules="required"
              v-slot="{ errors }"
            >
              <div>1.1 การประสานงานของเจ้าหน้าที่ในการนัดหมายติดตั้ง</div>
              <v-radio-group :error-messages="errors" row v-model="input.installation.coordinate">
                <v-radio :key="n" v-for="n in 4" :value="n" :label="n.toString()"></v-radio>
              </v-radio-group>
            </ValidationProvider>
            <ValidationProvider
              name="ความตรงต่อเวลาและความสุภาพของเจ้าหน้าติดตั้ง"
              rules="required"
              v-slot="{ errors }"
            >
              <div>1.2 ความตรงต่อเวลาและความสุภาพของเจ้าหน้าติดตั้ง</div>
              <v-radio-group
                :error-messages="errors"
                row
                v-model="input.installation.timeAndPolite"
              >
                <v-radio :key="n" v-for="n in 4" :value="n" :label="n.toString()"></v-radio>
              </v-radio-group>
            </ValidationProvider>
            <ValidationProvider
              name="ความเรียบร้อยและความสะดวกในการใช้งานหลังการติดตั้ง"
              rules="required"
              v-slot="{ errors }"
            >
              <div>1.3 ความเรียบร้อยและความสะดวกในการใช้งานหลังการติดตั้ง</div>
              <v-radio-group :error-messages="errors" row v-model="input.installation.neatness">
                <v-radio :key="n" v-for="n in 4" :value="n" :label="n.toString()"></v-radio>
              </v-radio-group>
            </ValidationProvider>
            <ValidationProvider
              name="ความพึงพอใจในการได้รับบริการโดยรวม"
              rules="required"
              v-slot="{ errors }"
            >
              <div>1.4 ความพึงพอใจในการได้รับบริการโดยรวม</div>
              <v-radio-group :error-messages="errors" row v-model="input.installation.complacence">
                <v-radio :key="n" v-for="n in 4" :value="n" :label="n.toString()"></v-radio>
              </v-radio-group>
            </ValidationProvider>
            <br />
            <div><b>2. ประเมิน PC</b></div>
            <br />
            <ValidationProvider
              name="PC ได้นำเสนอข้อมูลสินค้า, ให้ความรู้ความเข้าใจ, ประโยชน์ที่ลูกค้าจะได้รับ
"
              rules="required"
              v-slot="{ errors }"
            >
              <div>
                2.1 PC ได้นำเสนอข้อมูลสินค้า, ให้ความรู้ความเข้าใจ, ประโยชน์ที่ลูกค้าจะได้รับ
              </div>
              <v-radio-group :error-messages="errors" row v-model="input.pc.offer">
                <v-radio :key="n" v-for="n in 4" :value="n" :label="n.toString()"></v-radio>
              </v-radio-group>
            </ValidationProvider>
            <ValidationProvider
              name="ลูกค้าได้รับทราบถึงความแตกต่างของชุดครัวคิงส์เมื่อเทียบกับชุดครัวแบรนด์อื่น
"
              rules="required"
              v-slot="{ errors }"
            >
              <div>
                2.2 ลูกค้าได้รับทราบถึงความแตกต่างของชุดครัวคิงส์เมื่อเทียบกับชุดครัวแบรนด์อื่น
              </div>
              <v-radio-group :error-messages="errors" row v-model="input.pc.compare">
                <v-radio :key="n" v-for="n in 4" :value="n" :label="n.toString()"></v-radio>
              </v-radio-group>
            </ValidationProvider>
            <ValidationProvider
              name="ลูกค้าได้รับการแนะนำถึงสิทธิประโยชน์ และโปรโมชั่นการขายต่างๆ"
              rules="required"
              v-slot="{ errors }"
            >
              <div>2.3 ลูกค้าได้รับการแนะนำถึงสิทธิประโยชน์ และโปรโมชั่นการขายต่างๆ</div>
              <v-radio-group :error-messages="errors" row v-model="input.pc.benefit">
                <v-radio :key="n" v-for="n in 4" :value="n" :label="n.toString()"></v-radio>
              </v-radio-group>
            </ValidationProvider>
            <ValidationProvider
              name="ลูกค้าได้รับการแนะนำถึงสิทธิประโยชน์ และโปรโมชั่นการขายต่างๆ

"
              rules="required"
              v-slot="{ errors }"
            >
              <div>2.4 การต้อนรับลูกค้า, กิริยาวาจาและบุคลิกภาพของ PC</div>
              <v-radio-group :error-messages="errors" row v-model="input.pc.welcome">
                <v-radio :key="n" v-for="n in 4" :value="n" :label="n.toString()"></v-radio>
              </v-radio-group>
            </ValidationProvider>
            <ValidationProvider
              name="การติดตามดูแลเอาใจใส่และตอบข้อสักถามลูกค้าของ PC
"
              rules="required"
              v-slot="{ errors }"
            >
              <div>2.5 การติดตามดูแลเอาใจใส่และตอบข้อสักถามลูกค้าของ PC</div>
              <v-radio-group :error-messages="errors" row v-model="input.pc.care">
                <v-radio :key="n" v-for="n in 4" :value="n" :label="n.toString()"></v-radio>
              </v-radio-group>
            </ValidationProvider>
            <br />
            <div><b>3. เหตุผลสำคัญที่ลูกค้าตัดสินใจซื้อชุดครัวคิงส์ </b></div>
            <br />
            <ValidationProvider name="ราคา" rules="required" v-slot="{ errors }">
              <div>3.1 ราคา</div>
              <v-radio-group :error-messages="errors" row v-model="input.importantReason.price">
                <v-radio :key="n" v-for="n in 4" :value="n" :label="n.toString()"></v-radio>
              </v-radio-group>
            </ValidationProvider>
            <ValidationProvider name="ความสวยงาม" rules="required" v-slot="{ errors }">
              <div>3.2 ความสวยงาม</div>
              <v-radio-group :error-messages="errors" row v-model="input.importantReason.beautiful">
                <v-radio :key="n" v-for="n in 4" :value="n" :label="n.toString()"></v-radio>
              </v-radio-group>
            </ValidationProvider>
            <ValidationProvider
              name="คุณสมบัติสินค้า เช่น กันน้ำ, กันปลวก ฯลฯ"
              rules="required"
              v-slot="{ errors }"
            >
              <div>3.3 คุณสมบัติสินค้า เช่น กันน้ำ, กันปลวก ฯลฯ</div>
              <v-radio-group :error-messages="errors" row v-model="input.importantReason.property">
                <v-radio :key="n" v-for="n in 4" :value="n" :label="n.toString()"></v-radio>
              </v-radio-group>
            </ValidationProvider>
            <ValidationProvider
              name="โปรโมชั่นส่งเสริมการขาย เข่น ของแถม, ส่วนลด ฯลฯ"
              rules="required"
              v-slot="{ errors }"
            >
              <div>3.4 โปรโมชั่นส่งเสริมการขาย เข่น ของแถม, ส่วนลด ฯลฯ</div>
              <v-radio-group :error-messages="errors" row v-model="input.importantReason.promotion">
                <v-radio :key="n" v-for="n in 4" :value="n" :label="n.toString()"></v-radio>
              </v-radio-group>
            </ValidationProvider>
            <ValidationProvider
              name="บริการส่งพร้อมติดตั้งฟรี"
              rules="required"
              v-slot="{ errors }"
            >
              <div>3.5 บริการส่งพร้อมติดตั้งฟรี</div>
              <v-radio-group
                :error-messages="errors"
                row
                v-model="input.importantReason.freeService"
              >
                <v-radio :key="n" v-for="n in 4" :value="n" :label="n.toString()"></v-radio>
              </v-radio-group>
            </ValidationProvider>
            <ValidationProvider name="เชื่อมั่นในแบรนด์คิงส์" rules="required" v-slot="{ errors }">
              <div>3.6 เชื่อมั่นในแบรนด์คิงส์</div>
              <v-radio-group
                :error-messages="errors"
                row
                v-model="input.importantReason.trustBrain"
              >
                <v-radio :key="n" v-for="n in 4" :value="n" :label="n.toString()"></v-radio>
              </v-radio-group>
            </ValidationProvider>
            <ValidationProvider name="พนักงานขาย (PC)" rules="required" v-slot="{ errors }">
              <div>3.7 พนักงานขาย (PC)​</div>
              <v-radio-group :error-messages="errors" row v-model="input.importantReason.pc">
                <v-radio :key="n" v-for="n in 4" :value="n" :label="n.toString()"></v-radio>
              </v-radio-group>
            </ValidationProvider>
            <ValidationProvider name="การให้บริการออกแบบครัว" rules="required" v-slot="{ errors }">
              <div>3.8 การให้บริการออกแบบครัว​</div>
              <v-radio-group :error-messages="errors" row v-model="input.importantReason.design">
                <v-radio :key="n" v-for="n in 4" :value="n" :label="n.toString()"></v-radio>
              </v-radio-group>
            </ValidationProvider>
            <br />
            <ValidationProvider name="ข้อเสนอแนะ" v-slot="{ errors }">
              <div>
                <b>4. ข้อเสนอแนะเพิ่มเติมเพื่อนำไปปรับปรุงคุณภาพการให้บริการ</b>
              </div>
              <br />
              <v-textarea
                outlined
                background-color="white"
                rows="3"
                :error-messages="errors"
                v-model="input.note"
              ></v-textarea>
            </ValidationProvider>
          </ValidationObserver>
        </div>
        <div class="px-13 mt-2">
          <v-btn @click="saveData" :loading="saveloading" large dark block color="#59BEA3"
            >ส่ง</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      saveloading: false,
      input: {
        installation: {
          coordinate: null, // ด้านการประสานงาน integer
          timeAndPolite: null, // ด้านความตรงต่อเวลา
          neatness: null, // ด้านความเรียบร้อย integer
          complacence: null // ด้านความพึงพอใจโดยรวม integer
        },
        pc: {
          offer: null,
          compare: null,
          benefit: null,
          welcome: null,
          care: null
        },
        importantReason: {
          price: null,
          beautiful: null,
          property: null,
          promotion: null,
          freeService: null,
          trustBrain: null,
          pc: null,
          design: null
        },
        note: '' // optional
      }
    }
  },
  methods: {
    async saveData() {
      var isValid = await this.$refs.observer.validate()
      if (!isValid) return
      this.saveloading = true
      var data = {
        detail: {
          installation: this.input.installation,
          pc: this.input.pc,
          importantReason: this.input.importantReason
        },
        note: this.input.note,
        userid: this.userid,
        pageid: this.query.pageid,
        orderid: this.query.orderid
      }
      axios
        .post(process.env.VUE_APP_API_URL + 'kingkitchen/customerFillDataSatisfaction', data, {
          headers: {
            AUTHORIZATION: 'Bearer xxx xxx'
          }
        })
        .then(async (response) => {
          if (response.data.responsecode == 200) {
            await this.$swal.fire({
              text: 'สำเร็จ',
              icon: 'success'
            })
            await this.$store.dispatch(
              'sendMessage',
              'ขอบคุณสำหรับการประเมินความพึงพอใจในการให้บริการของเรา ทางเราจะนำไปปรับปรุงให้ดียิ่งขึ้น🙏'
            )
            this.$liff.closeWindow()
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
