<template>
  <div>
    <v-container class="pt-5 pb-10">
      <div style="text-align: center" class="mt-1">
        <img style="height: auto; width: 80px" src="@/assets/logo.png" />
      </div>
      <v-card style="margin-top: 20px" class="px-8 py-13 mx-4 radius-15" :elevation="18">
        <!-- <vue-element-loading
          :active="loading"
          spinner="bar-fade-scale"
          color="#152d51"
        /> -->
        <div class="text-right">
          <v-btn @click="editProfile" icon ripple>
            <v-icon>edit</v-icon>
          </v-btn>
        </div>
        <template v-if="!isEditProfile">
          <v-layout column align-center v-if="userData">
            <v-responsive :max-height="imgSize" :max-width="imgSize + 30">
              <v-img
                class="mb-md-2"
                style="border-radius: 50%"
                :height="imgSize"
                :width="imgSize"
                :src="userData.profile_pic || img"
              ></v-img>
            </v-responsive>
            <h3 class="mt-4">{{ userData.first_name }} {{ userData.last_name }}</h3>
            <v-layout column align-center class="w-100" v-if="usertype == 'customer'">
              <div class="text-center" style="font-size: 12px">Customer ID #{{ userData.id }}</div>
              <div
                class="text-center py-1 px-5 mt-3"
                style="font-size: 11px; background-color: black; color: #fff; border-radius: 8px"
                v-if="userData.email"
              >
                {{ userData.email }}
              </div>
            </v-layout>
            <div v-else-if="usertype == 'employee'" class="mt-3">
              ตำแหน่ง : {{ userData.job || '-' }}
            </div>
            <div class="w-100 mt-6">
              <v-layout class="w-100" row>
                <v-icon class="col-2" style="color: red">mdi-phone</v-icon>
                <div class="pl-0 col-10">
                  {{ phonenumber(userData.phoneno) || '-' }}
                </div>
              </v-layout>
              <!-- <v-layout
                v-if="usertype == 'employee'"
                class="w-100 mt-3"
                row
                :wrap="false"
              >
                <v-icon class="col-2" style="color: red">mdi-store</v-icon>
                <div class="pl-0 col-10">
                  ร้าน {{ userData.location || "-" }} สาขา
                  {{ userData.branch || "-" }}
                </div>
              </v-layout> -->
              <v-layout v-if="usertype != 'employee'" class="w-100 mt-3" row :wrap="false">
                <v-icon class="col-2" style="color: red">mdi-home</v-icon>
                <div class="pl-0 col-10">
                  {{ userData.address || '-' }}
                </div>
              </v-layout>
            </div>
          </v-layout>
        </template>
        <template v-else>
          <!-- edit profile -->
          <v-layout column align-center v-if="userData">
            <v-responsive :max-height="imgSize" :max-width="imgSize + 30">
              <v-img
                class="mb-md-2"
                style="border-radius: 50%"
                :height="imgSize"
                :width="imgSize"
                :src="userData.profile_pic || img"
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-responsive>
            <ValidationObserver ref="observer">
              <ValidationProvider
                :rules="{
                  required: true
                }"
                name="ชื่อจริง"
                v-slot="{ errors }"
              >
                <div class="mt-2">
                  <label class="mb-2">ชื่อจริง</label>
                  <custom-input
                    outlined
                    placeholder="ชื่อจริง"
                    v-model="input.first_name"
                    dense
                    solo
                    :error-messages="errors"
                  ></custom-input>
                </div>
              </ValidationProvider>
              <ValidationProvider
                :rules="{
                  required: true
                }"
                name="นามสกุล"
                v-slot="{ errors }"
              >
                <div class="mt-2">
                  <label class="mb-2">นามสกุล</label>
                  <custom-input
                    outlined
                    placeholder="นามสกุล"
                    v-model="input.last_name"
                    dense
                    solo
                    :error-messages="errors"
                  ></custom-input>
                </div>
              </ValidationProvider>
              <ValidationProvider
                :rules="{
                  required: true,
                  thai_phonenumber: true,
                  digits: 10
                }"
                name="เบอร์โทรศัพท์"
                v-slot="{ errors }"
              >
                <div class="mt-2">
                  <label class="mb-2">เบอร์โทรศัพท์</label>
                  <custom-input
                    dense
                    solo
                    type="number"
                    maxlength="10"
                    numberOnly
                    name="phone"
                    placeholder="เบอร์โทรศัพท์"
                    v-model="input.phoneno"
                    outlined
                    :error-messages="errors"
                  ></custom-input>
                </div>
              </ValidationProvider>
            </ValidationObserver>
            <div class="text-center">
              <v-btn @click="saveEditProfile" x-large color="#000" dark :loading="loading"
                >บันทึก
              </v-btn>
            </div>
          </v-layout>
        </template>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios'
// import privacy from "./privacy";
import VueElementLoading from 'vue-element-loading'

export default {
  components: {
    //   privacy,
    VueElementLoading
  },

  data() {
    return {
      img: require('@/assets/empty.png'),
      imgSize: 120,
      error: false,
      loading: true,
      isEditProfile: false,
      input: {
        first_name: '',
        last_name: '',
        phoneno: ''
      }
    }
  },
  mounted() {},
  methods: {
    phonenumber(number) {
      if (number) {
        let match = number.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
          return match[1] + '-' + match[2] + '-' + match[3]
        }
      }
      return ''
    },
    editProfile() {
      this.input = {
        first_name: this.userData.first_name,
        last_name: this.userData.last_name,
        phoneno: this.userData.phoneno
      }
      this.isEditProfile = true
    },
    async saveEditProfile() {
      if (await this.$refs.observer.validate()) {
        let data = {
          employeeid: this.userData.employeeid,
          first_name: this.input.first_name,
          last_name: this.input.last_name,
          phoneno: this.input.phoneno
        }
        this.loading = true
        axios
          .put(this.serverUrl + 'kingkitchen/editEmployeeMemberData', data, {
            headers: {
              AUTHORIZATION: 'Bearer xxx xxx'
            }
          })
          .then((response) => {
            if (response.data.responsecode == 200) {
              this.$store.state.userData.first_name = this.input.first_name
              this.$store.state.userData.last_name = this.input.last_name
              this.$store.state.userData.phoneno = this.input.phoneno
              this.loading = false
              this.$swal.fire({
                text: 'แก้ไขข้อมูลสำเร็จ'
              })
              this.isEditProfile = false
            }
          })
          .catch(async (error) => {
            await this.$swal.fire({
              text: error.response.data.reason
            })
            if (error.response.data.responsecode == 400) {
              this.$liff.closeWindow()
            }
          })
      }
    }
  },
  computed: {
    userData() {
      return this.$store.state.userData
    },
    profile() {
      return this.$store.state.profile
    },
    userid() {
      return this.$store.state.userid
    },
    usertype() {
      return this.$route.query.usertype
    },
    query() {
      return this.$store.state.query
    }
  }
}
</script>

<style lang="scss" scoped>
.req {
  border: 1px solid red;
}
.radius-15 {
  border: 0.5px solid transparent;
  border-radius: 15px !important;
}
</style>
