<template>
  <div class="Kanit mx-auto pb-10" style="max-width: 500px">
    <div v-if="!showChangeDate">
      <v-btn @click="$emit('close')" class="mt-2" icon x-large>
        <v-icon x-large> mdi-chevron-left </v-icon>
      </v-btn>
      <div class="px-4">
        <div style="font-weight: 600; font-size: 23px">ข้อมูลการสั่งซื้อ</div>
        <div class="text-center mt-7">
          <v-btn
            @click="takeAction('customer')"
            class="mr-2 px-9"
            rounded
            :outlined="active != 'customer'"
            color="primary"
            >รูปจากลูกค้า</v-btn
          >
          <v-btn
            @click="takeAction('pc')"
            rounded
            :outlined="active != 'pc'"
            class="ml-2 px-11"
            color="primary"
            >รูปจาก PC</v-btn
          >
        </div>
        <v-menu offset-y attach v-if="active == 'customer'">
          <template v-slot:activator="{ on, attrs }">
            <div class="topic mt-10 mb-5" @click="showMenu = !showMenu" v-bind="attrs" v-on="on">
              <div class="d-flex justify-space-between">
                <div>{{ customerImageSelected }}</div>
                <div>
                  <v-icon>mdi-arrow-down-drop-circle</v-icon>
                </div>
              </div>
            </div>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in customerImageChoice" :key="index">
              <v-list-item-title @click="onClickCustomerImageChoice(index)">{{
                item
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div class="mt-5" v-if="images && images.length > 0">
          <VueSlickCarousel
            ref="slick"
            style="height: 380px"
            v-bind="settings"
            :key="carouselKey"
            :arrow="true"
          >
            <div v-for="(img, k) in images" :key="k" class="px-1">
              <v-img contain height="350px" :src="img" @click="showImage(img)"> </v-img>
            </div>
          </VueSlickCarousel>
        </div>
        <div v-else class="text-center mt-7">No images</div>
        <div class="mt-6">
          <div style="font-weight: bold; font-size: 21px">
            {{ detaildata.name }}
          </div>
          <div style="font-size: 20px">
            {{ getProductName(detaildata.productdetails) }}
          </div>
          <div style="font-size: 17px">
            <span class="font-weight-bold">เบอร์ติดต่อ</span> :
            {{ detaildata.phonenumber }}
          </div>
          <div style="font-size: 17px">
            <span class="font-weight-bold">ที่อยู่</span> : {{ detaildata.address }}
            {{ `เขต${detaildata.district}` }} {{ `แขวง${detaildata.subdistrict}` }}
            {{ `${detaildata.zipcode}` }}
            {{ `จังหวัด${detaildata.province}` }}
          </div>
          <div class="mt-3">
            <v-btn @click="showMap" color="primary" :disabled="!detaildata.googlemap" text>
              <v-icon size="37" color="primary">mdi-map-marker-radius-outline</v-icon>
              <span class="ml-1" style="text-decoration: underline; font-size: 20px"
                >ดูแผนที่
              </span>
            </v-btn>
          </div>
          <div class="mt-4">
            วันที่ {{ moment(detaildata.appointmentdate).format('DD/MM/YYYY') }}
            ช่วง
            {{ detaildata.appointmenttime || '-' }}
          </div>
          <v-btn
            @click="showChangeDateDialog"
            style="border: 1px solid #ff4206; color: #ff4206"
            color="#FFECDF"
            depressed
            >เปลี่ยนวันติดตั้ง</v-btn
          >
          <br />
          <br />
          <ProductDetailList :productdetails="detaildata.productdetails" />
          <br />
          <br />
          <hr />
          <br />
          <div>PC: {{ detaildata.pcname }}</div>
          <div>เบอร์โทรติดต่อ : {{ detaildata.pcphoneno }}</div>
          <div class="mt-6" style="position: relative">
            <v-textarea
              :disabled="!isEditNote"
              outlined
              v-model="note"
              hide-details
              :filled="!isEditNote"
              label="กรอกข้อมูลเพิ่มเติม (Note)"
            ></v-textarea>
            <v-btn
              v-if="!isEditNote"
              @click="showEditNote"
              text
              style="position: absolute; right: 0px; top: 0px"
            >
              แก้ไข <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
          <div v-if="isEditNote" class="text-right mt-2">
            <v-btn @click="cancelEditNote" style="color: grey" color="darkgrey" depressed
              >ยกเลิก</v-btn
            >
            <v-btn
              @click="saveNote"
              :loading="noteloading"
              class="ml-2"
              style="color: #ff4206"
              depressed
              color="#FFECDF"
              >บันทึก</v-btn
            >
          </div>
          <div v-if="detaildata.googlemap" class="mt-4">
            <v-row dense class="px-5 pt-3" v-if="detaildata.salestatus !== 'approved'">
              <v-col cols="6" class="pr-2">
                <v-btn
                  @click.stop="showRejectDialog(detaildata)"
                  large
                  depressed
                  block
                  color="#FFECDF"
                  style="color: #ff4206; text-transform: none"
                  >Reject</v-btn
                >
              </v-col>
              <v-col cols="6" class="pl-2">
                <v-btn
                  large
                  depressed
                  block
                  color="#59BEA3"
                  dark
                  @click.stop="onApprove(detaildata)"
                  style="text-transform: none"
                  >Approve</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <OrderCalendar
        v-if="showChangeDate"
        :orderid="detaildata.id"
        :isChangeDate="true"
        @close="showChangeDate = false"
        @onUpdateData="onUpdateDate"
      ></OrderCalendar>
    </div>
    <v-dialog v-model="dialogShowImage" width="400px" max-width="400px" max-height="400px">
      <v-card>
        <div class="text-right">
          <v-btn @click="dialogShowImage = false" icon ripple>
            <v-icon>close</v-icon>
          </v-btn>
        </div>
        <div>
          <v-zoomer
            ref="zoomer"
            style="width: 400px; height: 400px; border: solid 1px silver"
            :max-scale="10"
            :zooming-elastic="false"
            :zoomed.sync="zoomed"
          >
            <img :src="imageShow" style="object-fit: contain; width: 100%; height: 100%" />
          </v-zoomer>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="rejectDialog" persistent width="550px">
      <v-card class="Kanit">
        <div class="text-right">
          <v-btn @click="rejectDialog = false" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </div>
        <div class="px-3 pb-3">
          <div style="font-weight: bold; font-size: 18px">โปรดระบุเหตุผลการ reject</div>
          <div style="font-size: 14px">*ระบบจะแจ้งเหตุผลกลับไปยัง PC ผู้ดูแล</div>
          <div class="mt-2">
            <ValidationObserver ref="observer">
              <ValidationProvider
                :rules="{
                  required: true
                }"
                name="เหตุผลที่ Reject"
                v-slot="{ errors }"
              >
                <v-textarea :error-messages="errors" outlined v-model="rejectReason"> </v-textarea>
              </ValidationProvider>
            </ValidationObserver>
          </div>
          <div>
            <v-row dense>
              <v-col cols="6" class="pr-2">
                <v-btn @click="rejectDialog = false" block depressed color="#C4C4C4" dark
                  >ยกเลิก</v-btn
                >
              </v-col>
              <v-col cols="6" class="pl-2">
                <v-btn
                  :loading="rejectloading"
                  @click="onReject"
                  block
                  depressed
                  color="#59BEA3"
                  dark
                  >ยืนยัน</v-btn
                ></v-col
              >
            </v-row>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import axios from 'axios'
import moment from 'moment'
import OrderCalendar from './CreateOrder/OrderCalendar.vue'
import ProductDetailList from '../ProductDetailList.vue'
export default {
  components: {
    VueSlickCarousel,
    OrderCalendar,
    ProductDetailList
  },
  props: ['detaildata'],
  data() {
    return {
      showMenu: false,
      customerImageSelected: '',
      customerImageChoice: [
        'ภาพโดยรวม ด้านซ้าย',
        'ด้านหน้า ระยะห่างระหว่างท่อกับเสา',
        'ด้านขวา',
        'ตำแหน่งท่อน้ำทิ้งและน้ำดี ความสูงของพื้น และระยะห่างจากผนัง',
        'ขนาดของเสา ด้านซ้าย และขวา',
        'ขนาดและตำแหน่งของหน้าต่าง',
        'ตำแหน่งของปลั๊กไฟและประตู',
        'ความสูงของบัวจากพื้น',
        'ความสูงของพื้นถึงด้านบนฝ้า'
      ],
      active: 'customer',
      carouselKey: 1,
      images: [],
      note: '',
      noteloading: false,
      showChangeDate: false,
      tempnote: '',
      isEditNote: false,
      settings: {
        lazyLoad: 'ondemand',
        dots: true,
        centerMode: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        touchThreshold: 5
      },
      imageShow: null,
      dialogShowImage: false,
      zoomed: false,
      rejectloading: false,
      rejectDialog: false
    }
  },
  mounted() {
    this.note = this.detaildata.note
    this.takeAction('customer')
  },
  computed: {
    moment() {
      return moment
    },
    userdata() {
      return this.$store.state.userData
    }
  },
  methods: {
    onUpdateDate(item) {
      this.detaildata.appointmentdate = item
      this.showChangeDate = false
    },
    showChangeDateDialog() {
      this.$store.state.inputCreateOrder.appointmentdate = moment(
        this.detaildata.appointmentdate
      ).format('YYYY-MM-DD')
      this.showChangeDate = true
    },
    showMap() {
      var coord = JSON.parse(this.detaildata.googlemap)
      if (coord) {
        var url = `https://maps.google.com/?q=${coord.lat},${coord.lng}`
        this.$liff.openWindow({
          url,
          external: true
        })
      }
    },
    showEditNote() {
      this.tempnote = `${this.note}`
      this.isEditNote = true
    },
    cancelEditNote() {
      this.note = `${this.tempnote}`
      this.isEditNote = false
    },
    saveNote() {
      this.noteloading = true
      var data = {
        orderid: this.detaildata.id,
        note: this.note
      }
      axios
        .put(this.serverUrl + 'kingkitchen/updateNoteOrderBySale', data, {
          headers: {
            AUTHORIZATION: 'Bearer xxx xxx'
          }
        })
        .then((response) => {
          this.noteloading = false
          if (response.data.responsecode == 200) {
            this.isEditNote = false
          }
        })
    },
    onMounted(val) {
      this.showsplide = true
    },
    getProductName(item) {
      return item.map((el) => el.product).join(', ')
    },
    takeAction(val) {
      this.active = val
      let images_from_pc = []
      let images_from_customer = []
      if (val == 'pc') {
        this.detaildata.productdetails.forEach((d) => {
          let photos = d.photos.map((d2) => {
            return d2
          })
          images_from_pc = [...images_from_pc, ...photos]
        })
      } else {
        // customer
        this.customerImageSelected = this.customerImageChoice[0]
        images_from_customer = this.detaildata.jobsiteimages
          ? this.detaildata.jobsiteimages.left
          : []
      }
      this.images = val == 'customer' ? images_from_customer : images_from_pc
    },
    onClickCustomerImageChoice(index) {
      this.customerImageSelected = this.customerImageChoice[index]
      if (!this.detaildata.jobsiteimages) {
        return
      }
      if (index == 0) {
        this.images = this.detaildata.jobsiteimages.left
      } else if (index == 1) {
        this.images = this.detaildata.jobsiteimages.front
      } else if (index == 2) {
        this.images = this.detaildata.jobsiteimages.right
      } else if (index == 3) {
        this.images = this.detaildata.jobsiteimages.sewer
      } else if (index == 4) {
        this.images = this.detaildata.jobsiteimages.pile
      } else if (index == 5) {
        this.images = this.detaildata.jobsiteimages.window
      } else if (index == 6) {
        this.images = this.detaildata.jobsiteimages.plug
      } else if (index == 7) {
        this.images = this.detaildata.jobsiteimages.lotus
      } else {
        this.images = this.detaildata.jobsiteimages.pellicle
      }
    },
    showImage(val) {
      this.dialogShowImage = true
      this.imageShow = val
    },
    showRejectDialog(item) {
      this.rejectReason = ''
      this.rejectData = item
      this.rejectDialog = true
    },
    async onApprove(item) {
      await this.$swal
        .fire({
          text: 'ยืนยันการ Approve ?',
          showCancelButton: true,
          confirmButtonText: 'ยืนยัน',
          reverseButtons: true,
          cancelButtonText: 'ยกเลิก',
          showLoaderOnConfirm: true,
          preConfirm: (login) => {
            var data = {
              orderid: item.id,
              status: 'approved', // approved, reject
              salename: `${this.userdata.first_name} ${this.userdata.last_name}` // ชื่อ sale ที่ approve ส่งมาเฉพาะ status เป็น approved
            }
            return axios
              .put(this.serverUrl + 'kingkitchen/verifyOrderBySale', data, {
                headers: {
                  AUTHORIZATION: 'Bearer xxx xxx'
                }
              })
              .then((response) => {
                if (response.data.responsecode == 200) {
                  return
                }
              })
              .catch(async (error) => {
                await this.$swal.fire({
                  text: error.response.data.reason
                })
                if (error.response.data.responsecode == 400) {
                  this.$liff.closeWindow()
                }
              })
          }
        })
        .then(async (result) => {
          if (result.value) {
            await this.$swal.fire({
              text: 'ระบบได้บันทึกข้อมูลคำสั่งซื้อนี้เรียบร้อยแล้ว',
              confirmButtonText: 'ปิด',
              confirmButtonColor: 'grey'
            })
            this.changeActive(this.active)
          }
        })
    },
    async onReject() {
      let pass = await this.$refs.observer.validate()
      if (!pass) {
        return
      }

      this.rejectloading = true
      var data = {
        orderid: this.rejectData.id,
        status: 'reject', // approved, reject
        reason: this.rejectReason
      }
      axios
        .put(process.env.VUE_APP_API_URL + 'kingkitchen/verifyOrderBySale', data, {
          headers: {
            AUTHORIZATION: 'Bearer xxx xxx'
          }
        })
        .then(async (response) => {
          this.rejectloading = false
          this.rejectDialog = false
          if (response.data.responsecode == 200) {
            this.notificationMessage = 'ระบบได้ทำการแจ้งเตือนไปยัง PC เรียบร้อยแล้ว'
            this.showNotificationAlert = true
          }
        })
        .catch(async (error) => {
          await this.$swal.fire({
            text: error.response.data.reason
          })
          if (error.response.data.responsecode == 400) {
            this.$liff.closeWindow()
          }
        })
    }
  },
  watch: {
    images() {
      this.carouselKey += 1
    }
  }
}
</script>

<style scoped>
.topic {
  padding: 10px 10px 10px 30px;
  background: #dadada;
  color: black;
  width: 100%;
  border-radius: 5px;
}
</style>
