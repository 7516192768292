<template>
  <div>
    <v-container class="pt-5 px-8">
      <h3 class="mt-4 bold text-decoration-underline">การเปลี่ยนเวลานัด</h3>
      <h5 class="my-8 bold">เลือกวันที่สะดวก</h5>
      <!-- date pickup -->
      <div style="position: relative">
        <DatepickerCustomDialog v-model="dateSelect">
          <input readonly :value="depadateFormat(dateSelect)" type="text" class="form-control" />
          <v-icon style="position: absolute; right: 4px; top: 6px; color: darkgrey"
            >mdi-calendar</v-icon
          >
        </DatepickerCustomDialog>
      </div>
      <h5 class="my-10 bold">
        เลือกช่วงเวลาที่สะดวก<v-icon style="color: red" class="ml-2">mdi-clock</v-icon>
      </h5>
      <v-radio-group row v-model="selectTime">
        <v-radio
          v-for="(item, index) in timeOption"
          :key="`key-op-${index}`"
          :label="item"
          :value="item"
        ></v-radio>
      </v-radio-group>
      <v-layout row wrap justify-center class="mt-6">
        <v-btn :loading="loading" large class="col-5 mr-4">ยกเลิก</v-btn>
        <v-btn :loading="loading" large class="col-5" color="#59BEA3" style="color: #fff"
          >ยืนยัน</v-btn
        >
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import DatepickerCustomDialog from '@/components/DatepickerCustomDialog'
import moment from 'moment'
export default {
  components: { DatepickerCustomDialog },
  computed: {},
  data() {
    return {
      dateSelect: null,
      timeOption: ['เช้า', 'บ่าย'],
      selectTime: null,
      img: require('@/assets/empty.png'),
      userData: {},
      errors: false,
      loading: false
    }
  },
  mounted() {},
  methods: {
    depadateFormat(date) {
      if (date) {
        return moment(date).format('DD/MM/YYYY')
      } else return ''
    }
  },
  computed: {
    profile() {
      return this.$store.state.profile
    },
    userid() {
      return this.$store.state.userid
    },
    query() {
      return this.$store.state.query
    }
  }
}
</script>

<style lang="scss">
.req {
  border: 1px solid red;
}
.radius-15 {
  border: 0.5px solid transparent;
  border-radius: 15px !important;
}
.border-cus {
  border: 1px dashed red;
}
</style>
