<template>
  <div style="height: 100%; background-color: #f4f4f4">
    <div style="max-width: 500px; margin: auto">
      <v-slide-x-transition leave-absolute hide-on-leave>
        <div :key="view" class="transition Kanit">
          <keep-alive>
            <component v-if="!loading" :is="view"></component>
            <div v-else class="text-center pt-10">
              <v-progress-circular size="70" indeterminate color="primary"></v-progress-circular>
            </div>
          </keep-alive>
        </div>
      </v-slide-x-transition>
    </div>
  </div>
</template>

<script>
import queryString from 'query-string'
import Profile from './Manager/Profile.vue'
import Register from './Register/main.vue'

import chanceday from '@/components/chanceday'
import axios from 'axios'
import Customer from './Customer/main.vue'
import GetCurrentLocation from './GetCurrentLocation.vue'
import InstallationForm from './Installation/main.vue'
import Manager from './Manager/main.vue'
import SatisfactionForm from './SatisfactionForm'
import TechnicianManager from './TechnicianManager/main.vue'
export default {
  components: {
    Register,
    Profile,
    InstallationForm,
    GetCurrentLocation,
    chanceday,
    SatisfactionForm,
    Customer,
    Manager,
    TechnicianManager
  },
  data() {
    return {
      loading: true
    }
  },
  async mounted() {
    var vm = this
    vm.queryinit()
    await vm.init()
    await vm.checkUser()
    this.loading = false
  },
  methods: {
    checkUser() {
      return new Promise((resolve) => {
        if (this.query.usertype == 'employee') {
          var url = 'getempmemberdata'
        } else {
          var url = 'getmemberdata'
        }
        axios
          .get(process.env.VUE_APP_API_URL + 'kingkitchen/' + url, {
            headers: { AUTHORIZATION: 'Bearer xxx xxx' },
            params: {
              id: this.userid,
              pageid: this.query.pageid
            }
          })
          .then(async (response) => {
            if (response.data.responsecode == 200) {
              this.$store.state.userData = response.data.data[0]
              var userData = response.data.data[0]
              if (this.query.view == 'Register') {
                if (this.query.usertype == 'employee') {
                  if (userData.job.includes('ช่าง')) {
                    this.query.view = 'TechnicianManager'
                  } else {
                    this.query.view = 'Manager'
                  }
                } else {
                  this.query.view = 'Profile'
                }
              } else if (this.query.view == 'Manager') {
                if (this.query.usertype == 'employee' && userData.job.includes('ช่าง')) {
                  this.query.view = 'TechnicianManager'
                }
              }
              resolve()
            }
          })
          .catch(async (error) => {
            if (this.query.view !== 'Register') {
              await this.$swal.fire({
                text: error.response.data.reason
              })
              if (error.response.data.responsecode == 400) {
                if (this.query.usertype == 'employee') {
                  this.query.view = 'Register'
                } else {
                  this.$liff.closeWindow()
                }
              }
            }
            resolve()
          })
      })
    },
    init() {
      const vm = this
      return new Promise(async (resolve) => {
        // Prod => 1656263282-jNNALKLw
        // Dev => 1656263282-9Okr8l8W
        // UAT => 1656263282-GzlgDaDB
        const liffid = '1656263282-jNNALKLw'

        // var liffid = this.$liffid.home;

        if (vm.query.apptype == 'line') {
          await vm.initializeLiff(liffid)
          vm.$store.state.userid = vm.$store.state.profile.userId + '__' + vm.query.pageid
          // vm.$store.state.userid = 'Uc2e68790ad01ce61a8f41b3826712327__101775492156959'
          // Dev Employee => 'Uc2e68790ad01ce61a8f41b3826712327__101775492156959'
          // Dev Customer => 'Uc2e68790ad01ce61a8f41b3826712327__105266258467671'
          // Prod => vm.$store.state.profile.userId + "__" + vm.query.pageid;
          resolve()
        } else {
          vm.$store.state.userid = vm.query.userid
          window.extAsyncInit = function () {
            // the Messenger Extensions JS SDK is done loading
          }
          resolve()
        }

        await vm.getMasterData()
      })
    },
    async initializeLiff(myLiffId) {
      var vm = this
      var redirectUri = window.location.href
      try {
        await this.$liff.init({
          liffId: myLiffId
        })
        if (await this.$liff.isLoggedIn()) {
          this.$store.state.profile = await this.$liff.getProfile()
        } else {
          await this.$liff.login({ redirectUri })
        }
      } catch (error) {
        // alert(error.message);
      }
    },
    async getMasterData() {
      const vm = this
      try {
        let data = {
          page_id: vm.query && vm.query.pageid,
          limit: 500,
          offset: 0
        }

        const url = `${process.env.VUE_APP_API_URL}api/kingkitchen/cms/branch`
        const response = await axios.get(url, {
          headers: {
            AUTHORIZATION: 'Bearer xxx xxx'
          },
          params: data
        })

        if (response && response.status === 200) {
          vm.$store.state.masterData = response.data.data
        }
      } catch (error) {
        console.error(error)
      }
    },
    queryinit() {
      var vm = this
      vm.$store.state.query = vm.$route.query['liff.state']
        ? queryString.parse(vm.$route.query['liff.state'])
        : vm.$route.query
    }
  },
  computed: {
    query() {
      return this.$store.state.query
    },
    view() {
      return this.$store.state.query.view
    }
  }
}
</script>

<style>
.gray1 {
  color: rgb(209, 209, 209);
}
.gray2 {
  color: rgb(146, 146, 146);
}
.bold {
  font-weight: 700;
}

.myshadow {
  -webkit-box-shadow: 2px 3px 17px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 3px 17px -1px rgba(0, 0, 0, 0.65);
  box-shadow: 2px 3px 17px -1px rgba(0, 0, 0, 0.95);
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
