<template>
  <div>
    <div class="Kanit" style="padding-bottom: 80px">
      <div style="max-width: 500px" class="mx-auto pa-3">
        <div>
          <v-btn @click="$emit('prev')" text> <v-icon>mdi-chevron-left</v-icon> กลับ </v-btn>
        </div>
        <div>
          <div class="px-0 pt-5">
            <div style="font-size: 20px; font-weight: 600">รายละเอียดสินค้า</div>
          </div>
          <div @click="showProductCreate" v-ripple class="addproductbox text-center mt-4">
            <div style="font-size: 24px; color: rgb(255 97 97)">
              <v-icon size="40" color="rgb(255 97 97)">mdi-plus</v-icon>เพิ่มสินค้า
            </div>
          </div>
        </div>
        <div class="mt-4">
          <v-card
            @click="showProductEdit(item, k)"
            class="pa-3 pt-0 pr-0 mb-4"
            style="position: relative; border: 1px solid #eee; border-radius: 6px"
            v-for="(item, k) in products"
            :key="k"
          >
            <div style="position: absolute; right: 0px; top: 0px">
              <v-btn
                @click.stop="deleteItem(k)"
                class="elevation-1"
                small
                outlined
                style="
                  border-radius: 0px 6px 0px 9px;
                  background-color: #fff6f6;
                  border: 2px solid #f99d9d;
                "
              >
                <v-icon color="#f99d9d">close</v-icon>
              </v-btn>
            </div>
            <div style="line-height: 22px" class="pr-2">
              <div class="mt-4 mb-2" style="color: #ff6565; font-size: 20px">
                {{ item.product }}
              </div>
              <v-row dense>
                <v-col :cols="item.photos && item.photos.length ? 7 : 12">
                  <div>
                    <div class="grey--text">
                      {{ item.format }}
                    </div>
                    <div class="grey--text">ซิงค์: {{ item.sink }}</div>
                    <div class="grey--text">อุปกรณ์อื่นๆ: {{ item.other }}</div>
                    <div class="grey--text">หมายเหตุ: {{ item.remark || '-' }}</div>
                  </div></v-col
                >
                <v-col cols="5" v-if="item.photos && item.photos.length">
                  <div>
                    <v-img
                      style="border-radius: 8px"
                      aspect-ratio="1"
                      :src="item.photos[0]"
                    ></v-img>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </div>
      </div>
      <div style="position: fixed; bottom: 0px; width: 100%">
        <v-card class="elevation-10">
          <div class="text-center mx-auto" style="max-width: 500px">
            <v-row class="px-3">
              <v-col cols="12">
                <v-btn
                  :loading="loading"
                  :disabled="!products.length"
                  :dark="products.length ? true : false"
                  @click="saveData"
                  color="#59BEA3"
                  block
                  >บันทึก</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>
    </div>
    <v-dialog v-model="editdialog" persistent width="550px">
      <v-card v-if="editdialog">
        <product-edit
          :edittype="edittype"
          :editdata="editdata"
          @onCreate="onCreate"
          @onEdit="onEdit"
          @close="onClose"
        ></product-edit
      ></v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProductEdit from './ProductEdit.vue'
import axios from 'axios'
export default {
  components: { ProductEdit },
  data() {
    return {
      loading: false,
      editdialog: false,
      edittype: '',
      editdata: '',
      editindex: '',
      products: [
        // {
        //   product: "Built-in",
        //   format: "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",
        //   photos: [
        //     "https://zwizai.s3.amazonaws.com/ecommerce/1629014118613killer_croc_by_patrickbrown_de8tv3t-fullview.jpg",
        //   ],
        //   sink: "ซ้าย",
        //   other: "เตา",
        //   remark: "ปป",
        // },
      ]
    }
  },
  methods: {
    saveData() {
      this.$store.state.inputCreateOrder.productdetails = this.products
      this.loading = true
      var data = {
        ...this.$store.state.inputCreateOrder,
        saleid: this.$store.state.userData.employeeid
      }
      axios
        .post(process.env.VUE_APP_API_URL + 'kingkitchen/createOrder', data, {
          headers: {
            AUTHORIZATION: 'Bearer xxx xxx'
          }
        })
        .then(async (response) => {
          if (response.data.responsecode == 200) {
            await this.$store.dispatch(
              `sendMessage`,
              `บันทึกข้อมูลคำสั่งซื้อ: ${response.data.data[0].id}`
            )
            this.loading = false
            this.$emit('next')
          } else {
            this.loading = false
            await this.$swal.fire({
              text: response.data.reason
            })
            if (response.data.responsecode == 400) {
              this.$liff.closeWindow()
            }
          }
        })
    },
    deleteItem(k) {
      this.$swal
        .fire({
          text: 'ยืนยันการลบสินค้า ?',
          showCancelButton: true
        })
        .then((result) => {
          if (result.value) {
            this.products.splice(k, 1)
          }
        })
    },
    onClose() {
      this.editdialog = false
    },
    onCreate(item) {
      this.products.push(item)
      this.editdialog = false
    },
    onEdit(item) {
      this.products[this.editindex] = item
      this.editdialog = false
    },
    showProductCreate() {
      this.edittype = 'create'
      this.editdialog = true
    },
    showProductEdit(item, k) {
      this.edittype = 'edit'
      this.editdata = item
      this.editindex = k
      this.editdialog = true
    }
  }
}
</script>

<style lang="scss">
.addproductbox {
  height: 70px;
  line-height: 60px;
  border-radius: 10px;
  border: 2px dashed rgb(255 97 97);
  cursor: pointer;
}
.addproductbox:hover {
  background-color: rgb(247, 247, 247);
}
</style>
