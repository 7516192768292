<template>
  <div>
    <template>
      <span @click="onClickUploadFile()">
        <slot :filename="currentImage.length + ' ไฟล์'" :loading="loading"> </slot>
      </span>
    </template>
    <!-- <div v-if="currentImage && currentImage.length">
      <div v-for="(item, k) in currentImage" :key="k">
        <div>
          <v-card
            @click="showItem(item.url)"
            color="#eee"
            class="pa-2 pr-10 mb-2"
            style="position: relative"
          >
            <div class="primary--text text-truncate">
              {{ item.name }}
            </div>
            <div style="position: absolute; right: 0px; top: 0px">
              <v-btn @click.stop="deleteItem(item, k)" icon
                ><v-icon>delete</v-icon></v-btn
              >
            </div>
          </v-card>
        </div>
      </div>
    </div> -->
    <ValidationObserver ref="observerdocument" v-slot="{}" tag="form">
      <ValidationProvider :name="'profile'" :rules="'image|size:5120'" v-slot="{}">
        <input
          type="file"
          multiple
          v-show="false"
          :capture="opencamera"
          ref="inputUploadDocument"
          class="form-control-file pt-2"
          name="photoUploadDocument"
          id="photoUploadInputDocument"
          data-vv-as="File"
          :key="`upload_${uploadtimestamp}`"
          @change="onFileChange($event)"
          style="
            overflow: hidden;
            position: fixed;
            width: 1px;
            height: 1px;
            z-index: -1;
            opacity: 0;
          "
        />
      </ValidationProvider>
    </ValidationObserver>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  props: {
    value: {
      default: []
    },
    multiple: {
      default: false
    },
    height: {
      default: 100,
      type: Number
    },
    errors: {
      default: () => [],
      type: Array
    },
    opencamera: {
      default: false,
      type: Boolean
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: function () {
    return {
      showUpload: false,
      currentImage: [],
      filename: [],
      isAlert: false,
      loading: false,
      uploadtimestamp: moment().format('x')
    }
  },
  methods: {
    deleteItem(item, k) {
      var data = [...this.value]
      var index = data.findIndex((el) => el == item.url)
      this.currentImage.splice(k, 1)
      this.$emit('input', data.splice(index, 1))
    },
    onClickUploadFile() {
      var vm = this
      vm.uploadtimestamp = moment().format('x')
      vm.$nextTick(() => {
        vm.$refs.inputUploadDocument.click()
      })
    },
    onClickUploadBtn() {
      document.getElementById('photoUploadInputDocument').click()
    },
    fileResize: function (file, callback) {
      let vm = this
      var reader = new FileReader()
      reader.onloadend = function () {
        var tempImg = new Image()
        tempImg.src = reader.result
        tempImg.onload = function () {
          var MAX_WIDTH = 1000
          var MAX_HEIGHT = 1000
          var tempW = tempImg.width
          var tempH = tempImg.height
          if (tempW > tempH) {
            if (tempW > MAX_WIDTH) {
              tempH *= MAX_WIDTH / tempW
              tempW = MAX_WIDTH
            }
          } else {
            if (tempH > MAX_HEIGHT) {
              tempW *= MAX_HEIGHT / tempH
              tempH = MAX_HEIGHT
            }
          }

          var canvas = document.createElement('canvas')
          canvas.width = tempW
          canvas.height = tempH
          var ctx = canvas.getContext('2d')
          ctx.drawImage(this, 0, 0, tempW, tempH)
          var dataURL = canvas.toDataURL('image/jpeg')
          var data = 'image=' + dataURL
          var obj = vm.dataURItoBlob(data)
          obj.name = file.name
          callback(obj)
        }
      }
      reader.readAsDataURL(file)
    },
    dataURItoBlob: function (dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString
      if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1])
      else byteString = unescape(dataURI.split(',')[1])

      // separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length)
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }

      return new Blob([ia], { type: mimeString })
    },
    showItem(item) {
      this.$liff.openWindow({
        url: item,
        external: true
      })
    },
    onFileChange: async function (event) {
      this.isAlert = false
      let vm = this
      var isValid = await this.$refs.observerdocument.validate()

      if (!isValid) {
        vm.uploadtimestamp = moment().format('x')
        return false
      }
      let files = event.target.files || event.dataTransfer.files
      var isAllImage = true
      // for (var i = 0; i < files.length; i++) {
      //   if (!files[i].type.includes("pdf")) {
      //     isAllImage = false;
      //     break;
      //   }
      // }

      if (isAllImage) {
        if (!files.length) {
          vm.uploadtimestamp = moment().format('x')
          return
        }

        // for (const key in files) {

        //   vm.upload(files[0]);

        //   console.log(key);
        // }
        var uploadall = []
        for (var i = 0; i < files.length; i++) {
          uploadall.push(vm.upload(files[i]))
        }
        Promise.all(uploadall).then(() => {
          vm.loading = false
        })

        event.target.value = ''
      }
    },

    upload(file) {
      return new Promise((resolve) => {
        let data = {
          name: file.name,
          type: file.type,
          file: file
        }
        let vm = this
        vm.loading = true

        let url =
          'https://api-enterprise.zwiz.app/' +
          'sign-s3?file-name=' +
          data.name +
          '&file-type=' +
          data.type

        axios
          .get(url)
          .then((response) => {
            // JSON responses are automatically parsed.
            response = response.data
            response.file = data.file
            this.uploadfile(response, function () {
              // vm.value.push(response.url);
              vm.uploadtimestamp = moment().format('x')
              if (vm.multiple) {
                vm.currentImage.push({
                  url: response.url,
                  name: data.name
                })

                vm.$emit('input', [...vm.value, response.url])
              } else {
                vm.currentImage = [
                  {
                    url: response.url,
                    name: data.name
                  }
                ]

                vm.$emit('input', [response.url])
              }
              resolve()
            })
          })
          .catch(() => {
            vm.loading = false
            vm.isAlert = true
          })
      })
    },
    uploadfile: function (data, callback) {
      let signedRequest = data.signedRequest
      let request = data.file
      const xhr = new XMLHttpRequest()
      xhr.open('PUT', signedRequest)
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            callback()
          } else {
            alert('Could not upload file.')
          }
        }
      }
      xhr.send(request)
    }
  },
  watch: {
    value: function (newVal) {
      // this.currentImage = newVal;
    }
  }
}
</script>
