<template>
  <div>
    <div class="pt-8 px-4">
      <div class="font-weight-bold" style="font-size: 25px; position: relative">รายการติดตั้ง</div>
      <div class="mt-6">
        <v-text-field
          @input="changeActive(active)"
          v-model="search"
          class="boxshadow-none"
          solo-inverted
          dense
          placeholder="ค้นหาชื่อ หรือ Customer ID"
        ></v-text-field>
      </div>
      <div class="text-center">
        <v-btn
          @click="changeActive('pending')"
          class="mr-2 px-9"
          rounded
          :outlined="active !== 'pending'"
          color="primary"
          :loading="loading"
          >รอติดตั้ง</v-btn
        >
        <v-btn
          @click="changeActive('success')"
          rounded
          class="ml-2 px-11"
          :outlined="active !== 'success'"
          color="primary"
          :loading="loading"
          >สำเร็จ</v-btn
        >
      </div>
      <div class="mt-8 pb-15">
        <div>
          <div v-for="(item, k) in orders" :key="k" class="mb-4">
            <v-card
              @click="showDetail(item)"
              class="pa-3 rounded-xl"
              elevation="5"
              style="border: 1px solid grey"
            >
              <div class="d-flex">
                <div>
                  <v-img
                    v-if="
                      item.jobsiteimages &&
                      item.jobsiteimages.left &&
                      item.jobsiteimages.left.length
                    "
                    class="elevation-1"
                    style="border-radius: 13px"
                    width="110px"
                    :src="item.jobsiteimages.left[0]"
                  ></v-img>
                  <v-img
                    v-else
                    class="elevation-1"
                    style="border-radius: 13px"
                    width="110px"
                    src="@/assets/empty.png"
                  ></v-img>
                </div>
                <div class="ml-3" style="position: relative; width: 100%">
                  <div class="font-weight-bold">{{ item.name }}</div>
                  <div style="font-size: 17px; color: grey">
                    {{ getProductName(item.productdetails) }}
                  </div>
                  <!-- <div>PC : A</div> -->
                  <div style="position: absolute; right: 0px; bottom: -4px; font-size: 13px">
                    {{ moment(item.appointmentdate).format('DD/MM/YYYY') }}
                    ช่วง
                    {{ item.appointmenttime || '-' }}
                  </div>
                </div>
              </div>
              <!-- v-if="active == 'pending'" -->
              <div>
                <div>
                  <v-row dense class="px-0 pt-3">
                    <v-col cols="6" class="pr-1">
                      <v-btn
                        class="py-5"
                        @click.stop="showBeforeForm(item)"
                        depressed
                        block
                        small
                        color="#FFECDF"
                        style="color: #ff4206; text-transform: none"
                        >{{
                          !(
                            item.beforeinstallhousenoimages &&
                            item.beforeinstallhousenoimages.length > 0
                          )
                            ? 'กรอก'
                            : 'ดู'
                        }}ข้อมูลก่อนติดตั้ง</v-btn
                      >
                    </v-col>
                    <v-col cols="6" class="pl-1">
                      <v-btn
                        class="py-5"
                        depressed
                        block
                        small
                        color="#59BEA3"
                        dark
                        @click.stop="showAfterForm(item)"
                        style="text-transform: none"
                        >{{
                          !(item.afterinstallimages && item.afterinstallimages.length > 0)
                            ? 'กรอก'
                            : 'ดู'
                        }}ข้อมูลปิดงาน</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-card>
          </div>
          <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler"></infinite-loading>
        </div>
      </div>
    </div>
    <v-dialog v-model="detailDialog" fullscreen transition="dialog-bottom-transition">
      <v-card>
        <order-detail
          v-if="detailDialog"
          :detaildata="detaildata"
          @close="detailDialog = false"
        ></order-detail>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="createOrderDialog" fullscreen>
      <v-card>
        <CreateOrder @close="createOrderDialog = false"></CreateOrder>
      </v-card>
    </v-dialog>
    <v-dialog transition="dialog-bottom-transition" persistent v-model="afterformDialog" fullscreen>
      <v-card v-if="afterformDialog">
        <AfterInstallationForm
          @onsave="onsave"
          @close="afterformDialog = false"
          :detaildata="afterformdata"
          :orderRead="orderReadOnly"
        ></AfterInstallationForm>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-bottom-transition"
      persistent
      v-model="beforeformDialog"
      fullscreen
    >
      <v-card v-if="beforeformDialog">
        <BeforeInstallationForm
          @onsave="onsave"
          @close="beforeformDialog = false"
          :detaildata="beforeformdata"
          :orderRead="orderReadOnly"
        ></BeforeInstallationForm>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import OrderDetail from './OrderDetail.vue'
import CreateOrder from './CreateOrder/main.vue'
import axios from 'axios'
import InfiniteLoading from 'vue-infinite-loading'
import moment from 'moment'
import AfterInstallationForm from './AfterInstallationForm/main.vue'
import BeforeInstallationForm from './BeforeInstallationForm/main.vue'
export default {
  components: {
    OrderDetail,
    CreateOrder,
    InfiniteLoading,
    AfterInstallationForm,
    BeforeInstallationForm
  },
  data() {
    return {
      cancelTokenSource: null,
      afterformdata: '',
      afterformDialog: false,
      beforeformdata: '',
      beforeformDialog: false,
      rejectDialog: false,
      rejectloading: false,
      rejectReason: '',
      rejectData: '',
      search: '',
      loading: false,
      active: 'pending',
      detailDialog: false,
      createOrderDialog: false,
      limit: 5,
      offset: 0,
      infiniteId: 0,
      orders: [],
      state: null,
      orderReadOnly: false
    }
  },
  computed: {
    moment() {
      return moment
    },
    userdata() {
      return this.$store.state.userData
    }
  },
  async mounted() {
    // await this.getData();
  },
  methods: {
    onsave() {
      this.afterformDialog = false
      this.beforeformDialog = false
      this.changeActive(this.active)
    },
    showAfterForm(item) {
      this.afterformdata = item
      this.orderReadOnly = item.afterinstallimages && item.afterinstallimages.length > 0
      this.afterformDialog = true
    },
    showBeforeForm(item) {
      this.beforeformdata = item
      this.orderReadOnly =
        item.beforeinstallhousenoimages && item.beforeinstallhousenoimages.length > 0
      this.beforeformDialog = true
    },
    async onApprove(item) {
      await this.$swal
        .fire({
          text: 'ยืนยันการ Approve ?',
          showCancelButton: true,
          confirmButtonText: 'ยืนยัน',
          reverseButtons: true,
          cancelButtonText: 'ยกเลิก',
          showLoaderOnConfirm: true,
          preConfirm: (login) => {
            var data = {
              orderid: item.id,
              status: 'approved', // approved, reject
              salename: `${this.userdata.first_name} ${this.userdata.last_name}` // ชื่อ sale ที่ approve ส่งมาเฉพาะ status เป็น approved
            }
            return axios
              .put(this.serverUrl + 'kingkitchen/verifyOrderBySale', data, {
                headers: {
                  AUTHORIZATION: 'Bearer xxx xxx'
                }
              })
              .then((response) => {
                if (response.data.responsecode == 200) {
                  return
                }
              })
              .catch(async (error) => {
                await this.$swal.fire({
                  text: error.response.data.reason
                })
                if (error.response.data.responsecode == 400) {
                  this.$liff.closeWindow()
                }
              })
          }
        })
        .then(async (result) => {
          if (result.value) {
            await this.$swal.fire({
              text: 'ระบบได้บันทึกข้อมูลคำสั่งซื้อนี้เรียบร้อยแล้ว',
              confirmButtonText: 'ปิด',
              confirmButtonColor: 'grey'
            })
            this.changeActive(this.active)
          }
        })
    },
    async onReject() {
      this.rejectloading = true
      var data = {
        orderid: this.rejectData.id,
        status: 'reject', // approved, reject
        reason: this.rejectReason
      }

      axios
        .put(this.serverUrl + 'kingkitchen/verifyOrderBySale', data, {
          headers: {
            AUTHORIZATION: 'Bearer xxx xxx'
          }
        })
        .then(async (response) => {
          this.rejectloading = false
          this.rejectDialog = false
          if (response.data.responsecode == 200) {
            await this.$swal.fire({
              text: 'ระบบได้บันทึกข้อมูลคำสั่งซื้อนี้เรียบร้อยแล้ว',
              confirmButtonText: 'ปิด',
              confirmButtonColor: 'grey'
            })
            this.changeActive(this.active)
          }
        })
        .catch(async (error) => {
          await this.$swal.fire({
            text: error.response.data.reason
          })
          if (error.response.data.responsecode == 400) {
            this.$liff.closeWindow()
          }
        })
    },
    async infiniteHandler(state) {
      this.state = state
      var length = await this.getData('add')
      this.offset += 5
      if (length) {
        this.state.loaded()
      } else {
        this.state.complete()
      }
    },
    changeActive(type) {
      this.active = type
      this.limit = 5
      this.offset = 0
      this.orders = []
      this.infiniteId++
      // this.state.reset();
      // this.getData();
    },

    getProductName(item) {
      return item.map((el) => el.product).join(', ')
    },
    getData(type) {
      return new Promise((resolve) => {
        var data = {
          tab: this.active, // pending คือ ยังไม่ตรวจสอบ, approved คือ ตรวจสอบแล้ว
          search: this.search, // optional
          limit: this.limit,
          offset: this.offset,
          technicianid: this.$store.state.userData.employeeid
        }
        if (!this.loading) {
          this.loading = true
          axios
            .get(process.env.VUE_APP_API_URL + 'kingkitchen/getTechnicianOrders', {
              params: data,

              headers: {
                AUTHORIZATION: 'Bearer xxx xxx'
              }
            })
            .then((response) => {
              this.loading = false
              if (response.data.responsecode == 200) {
                if (type === 'add') {
                  this.orders.push(...response.data.data)
                } else {
                  this.orders = response.data.data
                }
              }
              resolve(response.data.data.length)
            })
        }
      })
    },
    showDetail(item) {
      this.detaildata = item
      this.detailDialog = true
    }
  }
}
</script>

<style lang="scss">
.boxshadow-none .v-input__control .v-input__slot {
  box-shadow: none !important;
}
</style>
