<template>
  <div></div>
</template>

<script>
export default {
  async mounted() {
    var vm = this
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 60000
    }
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        window.opener.document.f1.p_name.value = coords.latitude + ',' + coords.longitude
        window.close()
      },
      (err) => {
        window.opener.document.f1.p_name.value = 0 + ',' + 0
        // alert("Please enable location permissions")
        console.warn(`ERROR(${err.code}): ${err.message}`)
      },
      options
    )
  }
}
//  = "555";
</script>

<style lang="scss" scoped></style>
