<template>
  <div>
    <v-slide-x-transition leave-absolute hide-on-leave>
      <step1 v-if="step == 1" @next="onNext"></step1>
      <step2 v-if="step == 2" :data="data" @back="step--"></step2>
    </v-slide-x-transition>
  </div>
</template>

<script>
import step1 from './step1.vue'
import step2 from './step2.vue'
export default {
  components: {
    step1,
    step2
  },
  data() {
    return {
      step: 1,
      data: {}
    }
  },
  methods: {
    onNext(val) {
      this.data = val
      this.step++
    }
  }
}
</script>
