<template>
  <div>
    <v-row>
      <v-col v-for="n in image" :key="n" class="d-flex child-flex" cols="4">
        <v-img @click="OnShowImage(n)" :src="n" aspect-ratio="1">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
    <v-dialog v-model="showDialog" width="550px" persistent>
      <v-card v-if="showDialog">
        <div class="text-right">
          <v-btn @click="showDialog = false" icon><v-icon>close</v-icon></v-btn>
        </div>
        <!-- <v-zoomer>
          <v-img :src="srcImage" width="500px"></v-img>
        </v-zoomer> -->
        <v-zoomer
          ref="zoomer"
          style="width: 500px; height: 500px; border: solid 1px silver"
          :max-scale="10"
          :zooming-elastic="false"
          :zoomed.sync="zoomed"
        >
          <img :src="srcImage" style="object-fit: contain; width: 100%; height: 100%" />
        </v-zoomer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    image: Array
  },
  data() {
    return {
      showDialog: false,
      srcImage: null,
      zoomed: false
    }
  },
  methods: {
    OnShowImage(val) {
      console.log(val)
      this.srcImage = val
      this.showDialog = true
    }
  }
}
</script>
