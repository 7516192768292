<template>
  <div class="Kanit">
    <div v-if="loading" class="text-center">
      <v-progress-circular
        indeterminate
        color="primary"
        size="60"
        class="mt-15"
      ></v-progress-circular>
    </div>
    <div v-else>
      <div style="max-width: 500px" class="mx-auto pa-3">
        <div>
          <v-btn @click="$emit('close')" text> <v-icon>mdi-chevron-left</v-icon> กลับ </v-btn>
        </div>
        <div class="px-6 pt-5 mb-3">
          <div style="font-size: 20px; font-weight: 600">ตรวจสอบคิวช่าง</div>
          <div>กรุณาเลือกวันที่</div>
        </div>
        <v-card>
          <div @click="setPosition">
            <v-date-picker
              :allowed-dates="allowedDates"
              @input="show"
              color="primary"
              full-width
              no-title
              v-model="$store.state.inputCreateOrder.appointmentdate"
            ></v-date-picker>
          </div>
        </v-card>
      </div>

      <div class="text-center mt-6">
        <v-btn
          @click="updateData"
          :disabled="!$store.state.inputCreateOrder.appointmentdate"
          :loading="updateLoading"
          class="px-10"
          large
          depressed
          dark
          color="#59BEA3"
          v-if="isChangeDate"
          >บันทึก</v-btn
        >
        <v-btn
          v-else
          :disabled="!$store.state.inputCreateOrder.appointmentdate"
          @click="nextStep"
          large
          rounded
          color="primary"
        >
          <v-icon class="mr-2" style="border: 2px solid white; border-radius: 20px"
            >mdi-plus</v-icon
          >
          เพิ่มคำสั่งซื้อ</v-btn
        >
      </div>
    </div>

    <v-menu
      :close-on-click="false"
      :close-on-content-click="false"
      v-model="showTooltip"
      :position-x="x"
      :position-y="y + 10"
      absolute
      offset-y
    >
      <v-card class="Kanit">
        <div class="pa-5">คิวว่าง : {{ availableQueue }}</div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {
  props: {
    isChangeDate: {
      type: Boolean,
      default: false
    },
    orderid: {}
  },
  data() {
    return {
      loading: true,
      updateLoading: false,
      availableQueue: 0,
      x: 0,
      y: 0,
      tempX: 0,
      tempY: 0,
      showTooltip: false,
      appointmentComputed: {},
      appointmentData: [
        // {
        //   appointmentdate: "2021-08-14", // YYYY-MM-DD
        //   appointmenttime: "เช้า", // เช้า, บ่าย
        //   count: 5, // จำนวนการนัด
        // },
        // {
        //   appointmentdate: "2021-08-14", // YYYY-MM-DD
        //   appointmenttime: "บ่าย", // เช้า, บ่าย
        //   count: 5, // จำนวนการนัด
        // },
        // {
        //   appointmentdate: "2021-08-13", // YYYY-MM-DD
        //   appointmenttime: "เช้า", // เช้า, บ่าย
        //   count: 4, // จำนวนการนัด
        // },
      ],
      holidays: [],
      queueList: {}
    }
  },
  mounted() {
    this.getHolidays()
    this.getData()
  },
  methods: {
    updateData() {
      this.updateLoading = true
      var data = {
        orderid: this.orderid,
        appointmentdate: moment(this.$store.state.inputCreateOrder.appointmentdate).format(
          'YYYY-MM-DD'
        ), // YYYY-MM-DD
        amount: this.amount,
        employeeid: this.$store.state.userData.employeeid
      }
      axios
        .put(this.serverUrl + 'kingkitchen/changeAppointment', data, {
          headers: {
            AUTHORIZATION: 'Bearer xxx xxxx'
          }
        })
        .then(async (response) => {
          if (response.data.responsecode == 200) {
            await this.$store.dispatch('sendMessage', `เปลี่ยนวันนัดสำเร็จ: ${this.orderid}`)
            await this.$swal.fire({
              text: 'สำเร็จ',
              icon: 'success'
            })
          }
          this.updateLoading = false
          this.$emit('onUpdateData', data.appointmentdate)
        })
    },
    nextStep() {
      this.$emit('next')
    },
    getQuere() {
      var data = {
        pageid: this.query.pageid
      }

      axios
        .get(this.serverUrl + 'kingkitchen/setQueue', {
          headers: { AUTHORIZATION: 'Bearer xxx xxx' },
          params: data
        })
        .then((response) => {
          if (response.data.responsecode == 200) {
            this.queueList = response.data.data
          }
        })
    },
    allowedDates(date) {
      if (this.appointmentComputed[date] && this.appointmentComputed[date] >= 10) {
        return false
      } else {
        return true
      }
    },
    getData() {
      axios
        .get(this.serverUrl + 'kingkitchen/getAppointmentDateList', {
          params: {
            currentdate: moment().format('YYYY-MM-DD')
          },
          headers: {
            AUTHORIZATION: 'Bearer xxx xxx'
          }
        })
        .then((response) => {
          this.loading = false
          this.appointmentData = response.data.data
          var result = {}
          this.appointmentData.forEach((val) => {
            if (result.hasOwnProperty(moment(val.appointmentdate).format('YYYY-MM-DD'))) {
              result[moment(val.appointmentdate).format('YYYY-MM-DD')] += val.count
            } else {
              Object.assign(result, {
                [moment(val.appointmentdate).format('YYYY-MM-DD')]: val.count
              })
            }
          })
          this.appointmentComputed = result
        })
    },
    getHolidays() {
      var data = {
        pageid: this.query.pageid
      }

      axios
        .get(this.serverUrl + 'kingkitchen/holidays', {
          headers: { AUTHORIZATION: 'Bearer xxx xxx' },
          params: data
        })
        .then((response) => {
          if (response.data.responsecode == 200) {
            this.holidays = response.data.holidays
          }
        })
    },
    setPosition(e) {
      this.showTooltip = false
      this.tempX = e.clientX
      this.tempY = e.clientY
    },
    show(e) {
      setTimeout(() => {
        this.$nextTick(() => {
          this.x = this.tempX
          this.y = this.tempY

          if (this.appointmentComputed.hasOwnProperty(e)) {
            this.availableQueue = 10 - this.appointmentComputed[e]
          } else {
            this.availableQueue = 10
          }

          this.showTooltip = true
        })
      })
    }
  }
}
</script>
