<template>
  <div>
    <div class="pt-5 px-3">
      <div style="text-align: center" class="mt-5">
        <img style="height: auto; width: 140px" src="@/assets/logo.png" />
      </div>
      <div class="mt-15">
        <!-- <input type="text" class="form-control col-12 col-md-8" > -->
        <ValidationObserver ref="observer">
          <label class="mt-5 mb-2">กรุณากรอก Employee ID</label>
          <ValidationProvider
            :rules="{
              required: true
            }"
            name="Employee ID"
            v-slot="{ errors }"
          >
            <custom-input
              outlined
              placeholder="Employee ID"
              v-model="input.employeeid"
              solo
              :error-messages="errors"
            ></custom-input>
          </ValidationProvider>
        </ValidationObserver>

        <div class="text-center">
          <v-btn
            @click="checkEmployeeID"
            x-large
            color="#000"
            style="color: #fff"
            :loading="loading"
            >ตรวจสอบ</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
// import privacy from "./privacy";

export default {
  components: {
    //   privacy,
  },

  data() {
    return {
      error: false,
      privacy: false,
      locationOption: [],
      jobOption: ['Sales', 'PC', 'ช่าง', 'ช่าง (SCG)'],
      checkbox: false,
      loading: false
    }
  },
  async mounted() {
    await this.$store.dispatch('getBranch')
    var branch = this.$store.state.branch
    this.locationOption = [...new Set(branch.map((el) => el.location))]
  },
  methods: {
    async checkEmployeeID() {
      if (await this.$refs.observer.validate()) {
        this.loading = true
        axios
          .get(process.env.VUE_APP_API_URL + 'kingkitchen/checkEmployeeId', {
            headers: {
              AUTHORIZATION: 'Bearer xxx xxx'
            },
            params: {
              pageid: this.query.pageid,
              employeeid: this.input.employeeid
            }
          })
          .then(async (response) => {
            this.loading = false
            if (response.data.responsecode == 200) {
              Object.assign(this.$store.state.input, response.data.data[0])

              this.$emit('next')
            } else {
              await this.$swal.fire({
                text: response.data.reason
              })
            }
          })
      }
    },
    async regis() {
      this.loading = true
      if ((await this.$refs.observer.validate()) && this.inputData.location && this.inputData.job) {
        const headers = {
          Authorization: `Bearer xxx xxx`
        }
        let dataCreate = {
          pageid: this.pageid,
          userid: this.userid,
          ...this.inputData
        }
        let url = process.env.VUE_APP_API_URL + 'kingkitchen/createmember'
        try {
          let res = await axios.post(url, dataCreate, { headers })
          if (res.data.responsecode == 204) {
            await this.$store.dispatch('sendMessage', 'ลงทะเบียนพนักงานสำเร็จ')
            await this.$swal.fire({
              text: 'ลงทะเบียนพนักงานสำเร็จ',
              icon: 'success'
            })
            this.loading = false
            this.$liff.closeWindow()
            window.close()
          } else {
            await this.$swal.fire({
              text: 'ลงทะเบียนไม่สำเร็จ',
              icon: 'error'
            })
            this.loading = false
          }
        } catch (error) {
          this.loading = false
          console.error(error)
        }
      } else {
        this.error = true
        this.loading = false
      }
    }
  },
  computed: {
    branchOption() {
      if (this.inputData.location) {
        var result = this.$store.state.branch
          .filter((el) => {
            return el.location == this.inputData.location
          })
          .map((val) => val.branch)

        return result
      }
      return []
    },
    input() {
      return this.$store.state.input
    },
    profile() {
      return this.$store.state.profile
    },
    userid() {
      return this.$store.state.userid
    },
    pageid() {
      return this.$route.query.pageid
    }
  }
}
</script>

<style lang="scss">
.req {
  border: 1px solid red;
}
</style>
