import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import sweetalert from 'sweetalert2'
import mainmixins from './mainmixins.js'
import './vee-validate'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import CustomInput from '@/components/CustomInput.vue'
import Multiselect from 'vue-multiselect'
import VueLocalStorage from 'vue-localstorage'
Vue.use(VueLocalStorage)
import VueZoomer from 'vue-zoomer'
Vue.use(VueZoomer)
require('dotenv').config()
// import * as VueGoogleMaps from 'vue2-google-maps'

// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyAEvBaFpJDwNkUTNjl68x4pCPsoVGWQ5PY',
//     libraries: 'places', // This is required if you use the Autocomplete plugin

//   },
// })

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('CustomInput', CustomInput)
Vue.component('Multiselect', Multiselect)

Vue.prototype.$liff = window.liff
Vue.prototype.$swal = sweetalert
Vue.mixin(mainmixins)
Vue.config.productionTip = false

new Vue({
  router,
  created() {
    return (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = '//connect.facebook.com/en_US/messenger.Extensions.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'Messenger')
  },
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
