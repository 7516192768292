<template>
  <div class="Kanit">
    <div style="max-width: 500px" class="mx-auto pa-3">
      <div>
        <v-btn @click="$emit('prev')" text> <v-icon>mdi-chevron-left</v-icon> กลับ </v-btn>
      </div>
      <div class="px-0 pt-5">
        <div style="font-size: 20px; font-weight: 600">เพิ่มคำสั่งซื้อ</div>
      </div>
      <div class="px-2 pb-4">
        <ValidationObserver ref="observer">
          <ValidationProvider
            :rules="{
              required: true
            }"
            name="Customer ID"
            v-slot="{ errors }"
          >
            <div>Customer ID</div>
            <custom-input
              dense
              outlined
              v-model="input.customerid"
              solo
              :error-messages="errors"
            ></custom-input>
          </ValidationProvider>
          <ValidationProvider
            :rules="{
              required: true
            }"
            name="ชื่อ - สกุล"
            v-slot="{ errors }"
          >
            <div>ชื่อ - สกุล</div>
            <custom-input
              dense
              outlined
              v-model="input.name"
              solo
              :error-messages="errors"
            ></custom-input>
          </ValidationProvider>
          <ValidationProvider
            :rules="{
              required: true,
              thai_phonenumber: true
            }"
            name="เบอร์โทร"
            v-slot="{ errors }"
          >
            <div>เบอร์โทร</div>
            <custom-input
              numberOnly
              maxlength="10"
              dense
              outlined
              v-model="input.phonenumber"
              solo
              :error-messages="errors"
            ></custom-input>
          </ValidationProvider>

          <ValidationProvider
            :rules="{
              required: true
            }"
            name="ที่อยู่"
            v-slot="{ errors }"
          >
            <div>ที่อยู่</div>
            <custom-input
              type="text"
              dense
              solo
              outlined
              v-model="input.address"
              :error-messages="errors"
            ></custom-input>
          </ValidationProvider>

          <ValidationProvider name="รหัสไปรษณีย์" :rules="'required'" v-slot="{ errors }">
            <div>รหัสไปรษณีย์</div>
            <div>
              <ThaiAddressInput
                :inputtype="'number'"
                @select="onSelectAddress"
                v-model="input.zipcode"
                :type="'zipcode'"
                :errorMessages="errors"
                :texttype="'number'"
              ></ThaiAddressInput>
            </div>
          </ValidationProvider>

          <ValidationProvider name="เขต" :rules="'required'" v-slot="{ errors }">
            <div>เขต</div>
            <ThaiAddressInput
              :inputtype="'text'"
              @select="onSelectAddress"
              v-model="input.district"
              :type="'amphoe'"
              :errorMessages="errors"
              :texttype="'text'"
            ></ThaiAddressInput>
          </ValidationProvider>

          <ValidationProvider name="แขวง" :rules="'required'" v-slot="{ errors }">
            <div>แขวง</div>
            <ThaiAddressInput
              @select="onSelectAddress"
              v-model="input.subdistrict"
              :type="'district'"
              :errorMessages="errors"
            ></ThaiAddressInput>
          </ValidationProvider>
          <!-- <label class="mt-5">จังหวัด</label> -->
          <ValidationProvider name="จังหวัด" :rules="'required'" v-slot="{ errors }">
            <div>จังหวัด</div>
            <ThaiAddressInput
              :inputtype="'text'"
              @select="onSelectAddress"
              v-model="input.province"
              :type="'province'"
              :errorMessages="errors"
              :texttype="'text'"
            ></ThaiAddressInput>
          </ValidationProvider>
        </ValidationObserver>
      </div>

      <div class="text-center py-6">
        <v-btn @click="nextStep" large rounded color="primary"> ถัดไป</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import ThaiAddressInput from '@/components/ThaiAddressInput.vue'
export default {
  data() {
    return {}
  },
  components: {
    ThaiAddressInput
  },
  computed: {
    input() {
      return this.$store.state.inputCreateOrder
    }
  },
  methods: {
    onSelectAddress(address) {
      this.input.district = address.amphoe
      this.input.province = address.province
      this.input.subdistrict = address.district
      this.input.postCode = address.zipcode
    },
    async nextStep() {
      var isValid = await this.$refs.observer.validate()
      if (!isValid) return false
      this.$emit('next')
    }
  }
}
</script>
