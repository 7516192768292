<template>
  <div class="Kanit">
    <div style="max-width: 500px" class="mx-auto pa-3 pb-7">
      <div>
        <v-btn @click="$emit('close')" text> <v-icon>mdi-chevron-left</v-icon> กลับ </v-btn>
      </div>
      <div class="mt-3">
        <div class="font-weight-bold" style="font-size: 23px">กรอกข้อมูลหลังติดตั้ง</div>
      </div>

      <div class="mt-4 px-3">
        <ValidationObserver ref="observer">
          <div class="headerafter mb-1">งานหลังติดตั้งเสร็จ</div>
          <ValidationProvider
            :rules="{ required: true }"
            name="งานหลังติดตั้งเสร็จ"
            v-slot="{ errors }"
          >
            <UploadMultipleFile
              :opencamera="false"
              :key="keyupload"
              :multiple="true"
              :max="!orderRead ? 15 : 0"
              v-model="input.afterinstallimages"
              :errors="errors"
              :readOnly="orderRead"
            ></UploadMultipleFile>
          </ValidationProvider>
          <div class="headerafter mb-1 mt-4">ลูกค้าถือป้าย Checklist</div>

          <ValidationProvider
            :rules="{ required: true }"
            name="ลูกค้าถือป้าย Checklist"
            v-slot="{ errors }"
          >
            <UploadMultipleFile
              :opencamera="false"
              :key="keyupload"
              :multiple="true"
              :max="!orderRead ? 15 : 0"
              v-model="input.afterinstallcustomerchecklist"
              :errors="errors"
              :readOnly="orderRead"
            ></UploadMultipleFile>
          </ValidationProvider>
          <div class="headerafter mb-1 mt-4">เอกสาร Check list</div>

          <ValidationProvider
            :rules="{ required: true }"
            name="เอกสาร Check list"
            v-slot="{ errors }"
          >
            <UploadMultipleFile
              :opencamera="false"
              :key="keyupload"
              :multiple="true"
              :max="!orderRead ? 15 : 0"
              v-model="input.afterinstalldocumentchecklist"
              :errors="errors"
              :readOnly="orderRead"
            ></UploadMultipleFile>
          </ValidationProvider>
          <div class="headerafter mb-1 mt-4">งานเพิ่มเติม (ถ้ามี)</div>
          <ValidationProvider
            :rules="{ required: false }"
            name="งานเพิ่มเติม (ถ้ามี)"
            v-slot="{ errors }"
          >
            <UploadMultipleFile
              :opencamera="false"
              :key="keyupload"
              :multiple="true"
              :max="!orderRead ? 15 : 0"
              v-model="input.afterinstallother"
              :errors="errors"
              :readOnly="orderRead"
            ></UploadMultipleFile>
          </ValidationProvider>
        </ValidationObserver>
      </div>
      <div class="text-center mt-8">
        <v-btn @click="next" dark large class="px-9" color="#59BEA3">ถัดไป</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import UploadMultipleFile from '@/components/UploadMultipleFile.vue'
export default {
  props: ['detaildata', 'orderRead'],
  components: {
    UploadMultipleFile
  },
  computed: {
    input() {
      return this.$store.state.afterinstallinput
    }
  },
  data() {
    return {
      keyupload: 0,
      otherproblem: false,
      otherproblemtext: ''
    }
  },
  mounted() {
    Object.assign(this.input, JSON.parse(JSON.stringify({ ...this.detaildata })))
  },
  methods: {
    async next() {
      var isValid = await this.$refs.observer.validate()

      if (!isValid) {
        return
      }

      this.$emit('next')
    }
  }
}
</script>

<style lang="scss" scoped>
.headerafter {
  font-weight: bold;
  font-size: 19px;
}
</style>
