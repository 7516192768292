var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Kanit"},[_c('div',{staticClass:"mx-auto pa-3 pb-7",staticStyle:{"max-width":"500px"}},[_c('div',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-chevron-left")]),_vm._v(" กลับ ")],1)],1),_vm._m(0),_c('div',{staticClass:"mt-4 px-3"},[_c('ValidationObserver',{ref:"observer"},[_c('div',{staticClass:"headerafter mb-1"},[_vm._v("งานหลังติดตั้งเสร็จ")]),_c('ValidationProvider',{attrs:{"rules":{ required: true },"name":"งานหลังติดตั้งเสร็จ"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('UploadMultipleFile',{key:_vm.keyupload,attrs:{"opencamera":false,"multiple":true,"max":!_vm.orderRead ? 15 : 0,"errors":errors,"readOnly":_vm.orderRead},model:{value:(_vm.input.afterinstallimages),callback:function ($$v) {_vm.$set(_vm.input, "afterinstallimages", $$v)},expression:"input.afterinstallimages"}})]}}])}),_c('div',{staticClass:"headerafter mb-1 mt-4"},[_vm._v("ลูกค้าถือป้าย Checklist")]),_c('ValidationProvider',{attrs:{"rules":{ required: true },"name":"ลูกค้าถือป้าย Checklist"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('UploadMultipleFile',{key:_vm.keyupload,attrs:{"opencamera":false,"multiple":true,"max":!_vm.orderRead ? 15 : 0,"errors":errors,"readOnly":_vm.orderRead},model:{value:(_vm.input.afterinstallcustomerchecklist),callback:function ($$v) {_vm.$set(_vm.input, "afterinstallcustomerchecklist", $$v)},expression:"input.afterinstallcustomerchecklist"}})]}}])}),_c('div',{staticClass:"headerafter mb-1 mt-4"},[_vm._v("เอกสาร Check list")]),_c('ValidationProvider',{attrs:{"rules":{ required: true },"name":"เอกสาร Check list"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('UploadMultipleFile',{key:_vm.keyupload,attrs:{"opencamera":false,"multiple":true,"max":!_vm.orderRead ? 15 : 0,"errors":errors,"readOnly":_vm.orderRead},model:{value:(_vm.input.afterinstalldocumentchecklist),callback:function ($$v) {_vm.$set(_vm.input, "afterinstalldocumentchecklist", $$v)},expression:"input.afterinstalldocumentchecklist"}})]}}])}),_c('div',{staticClass:"headerafter mb-1 mt-4"},[_vm._v("งานเพิ่มเติม (ถ้ามี)")]),_c('ValidationProvider',{attrs:{"rules":{ required: false },"name":"งานเพิ่มเติม (ถ้ามี)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('UploadMultipleFile',{key:_vm.keyupload,attrs:{"opencamera":false,"multiple":true,"max":!_vm.orderRead ? 15 : 0,"errors":errors,"readOnly":_vm.orderRead},model:{value:(_vm.input.afterinstallother),callback:function ($$v) {_vm.$set(_vm.input, "afterinstallother", $$v)},expression:"input.afterinstallother"}})]}}])})],1)],1),_c('div',{staticClass:"text-center mt-8"},[_c('v-btn',{staticClass:"px-9",attrs:{"dark":"","large":"","color":"#59BEA3"},on:{"click":_vm.next}},[_vm._v("ถัดไป")])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"font-weight-bold",staticStyle:{"font-size":"23px"}},[_vm._v("กรอกข้อมูลหลังติดตั้ง")])])}]

export { render, staticRenderFns }